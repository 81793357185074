import { FormItem } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormLayout from '../../components/SignupForm/FormLayout';
import AddressModule from '../../components/form-modules/AddressModule';
import CompanyNameModule from '../../components/form-modules/CompanyNameModule';
import { CompanyInvoiceData, UpdateContextFunction, isBusinessDataCompany, isCompanyData } from '../../types';
import { Context, useContextUpdate } from '../../wizard';

export interface InstallerInvoiceProps {
    invoiceAddressQuestion: string;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    setIsFormValid: (isFormValid: boolean) => void;
}

export default ({
    invoiceAddressQuestion,
    updateContext,
    context,
    setIsFormValid,
}: InstallerInvoiceProps): React.ReactElement => {
    const methods = useForm<CompanyInvoiceData>({
        mode: 'onTouched',
    });
    const {
        watch,
        reset,
        formState: { isValid },
    } = methods;

    const preassignedBusinessData = () => {
        if (isBusinessDataCompany(context)) {
            return context.businessData;
        }

        if (isCompanyData(context)) {
            return context.companyData;
        }

        throw Error('Illegal State');
    };

    React.useEffect(() => {
        if (context.isDeviatingInvoiceAddress) {
            reset({
                invoiceCompany: context.invoiceCompany ?? {
                    companyName: '',
                    address: { street: '', houseNumber: '', postalCode: '', city: '' },
                },
            });
        } else {
            reset({ invoiceCompany: preassignedBusinessData() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.isDeviatingInvoiceAddress]);

    useContextUpdate(watch, updateContext, 'ADDRESSES_INVOICE_ADDRESS', {
        disabled: !context.isDeviatingInvoiceAddress,
    });

    React.useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid, setIsFormValid]);

    return (
        <FormProvider {...methods}>
            <FormLayout>
                <FormItem label={invoiceAddressQuestion}>
                    <div className="max-w-[456px]">
                        <CompanyNameModule
                            registerPrefix="invoiceCompany"
                            disabled={!context.isDeviatingInvoiceAddress}
                        />
                        <AddressModule
                            parentObjectReference="invoiceCompany.address"
                            disabled={!context.isDeviatingInvoiceAddress}
                        />
                    </div>
                </FormItem>
            </FormLayout>
        </FormProvider>
    );
};
