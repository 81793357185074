import * as React from 'react';

interface SingleButtonProps {
    button: React.ReactElement;
}

export const SingleButton = ({ button }: SingleButtonProps): React.ReactElement => (
    <div className="grid grid-flow-col mt-12" style={{ height: 'min-content' }}>
        <div className="button place-self-end flex">{button}</div>
    </div>
);

interface TwoButtonProps {
    leftButton: React.ReactElement;
    rightButton: React.ReactElement;
}

export const TwoButton = ({ leftButton, rightButton }: TwoButtonProps): React.ReactElement => (
    <div className="grid grid-flow-col mt-12 w-full" style={{ height: 'min-content' }}>
        <div className="button place-self-start flex">{leftButton}</div>
        <div className="button place-self-end flex">{rightButton}</div>
    </div>
);
