import { Dropdown, FormItem, TextField, Tooltip } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { TelephoneData } from '../../types';
import telephoneNumberValidation from './telephoneNumberValidation';

const TelephoneNumberArea = (): React.ReactElement => {
    const {
        register,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<TelephoneData>();

    const requiredErrorMessage = 'Dies ist ein Pflichtfeld.';
    const validationErrorMessage = 'Bitte korrigieren Sie Ihre Eingabe.';
    const leadingZeroErrorMessage = 'Bitte geben Sie Ihre Telefonnummer ohne führende Null ein.';

    const dropdownOptions = {
        numberType: [
            {
                value: 'Mobile',
                label: 'Mobil',
            },
            {
                value: 'Landline',
                label: 'Festnetz',
            },
        ],
    };

    const telephoneCountryPrefix: string = watch('telephone.countryPrefix');
    const telephoneNumber: string = watch('telephone.number');
    const [isTrackingPhoneNumberAutocompletion, setIsTrackingPhoneNumberAutocompletion] = useState(true);
    useEffect(() => {
        if (isTrackingPhoneNumberAutocompletion) {
            // after autocompletion happened, the leading 0 should be removed for german numbers
            if (
                telephoneNumber &&
                telephoneNumber.length > 5 &&
                telephoneCountryPrefix === '49' &&
                telephoneNumber[0] === '0'
            ) {
                setValue('telephone.number', telephoneNumber.slice(1));
                setIsTrackingPhoneNumberAutocompletion(false);
            }
            // while typing normally, nothing should be removed automatically
            else if (telephoneNumber && telephoneNumber.length > 0) {
                setIsTrackingPhoneNumberAutocompletion(false);
            }
        }
        // reset tracking of autocompletion changes if the input field has been emptied
        else if (telephoneNumber === '') {
            setIsTrackingPhoneNumberAutocompletion(true);
        }
    }, [setValue, telephoneNumber, isTrackingPhoneNumberAutocompletion, telephoneCountryPrefix]);

    return (
        <FormItem label="Geben Sie bitte eine Telefonnummer für eventuelle Rückfragen an:">
            <div className="flex flex-row space-x-2 max-w-[456px] children-w-full">
                <div className="flex flex-row space-x-2 children-w-full">
                    <label className="text-small grid" htmlFor="dropdown-telephone.type">
                        <span>Art</span>
                        <Dropdown
                            name="telephone.type"
                            options={dropdownOptions.numberType}
                            dropdownClassname="w-full"
                            {...register('telephone.type', { required: true })}
                        />
                        {errors?.telephone?.type && (
                            <div>
                                <Tooltip message={requiredErrorMessage} />
                            </div>
                        )}
                    </label>
                    <div>
                        <TextField
                            autoComplete="tel-country-code"
                            name="telephone.countryPrefix"
                            label="Ländervorwahl"
                            prefix="+"
                            displayErrorMessage={errors?.telephone?.countryPrefix?.message}
                            {...register('telephone.countryPrefix', {
                                required: requiredErrorMessage,
                                pattern: { value: /^\d+$/, message: validationErrorMessage },
                                maxLength: { value: 5, message: validationErrorMessage },
                            })}
                        />
                    </div>
                </div>
                <div>
                    <TextField
                        autoComplete="tel-national"
                        name="telephone.number"
                        label="Nummer"
                        displayErrorMessage={errors?.telephone?.number?.message}
                        {...register('telephone.number', {
                            required: requiredErrorMessage,
                            validate: (value) =>
                                telephoneNumberValidation(value, validationErrorMessage, leadingZeroErrorMessage),
                        })}
                    />
                </div>
            </div>
        </FormItem>
    );
};
export default TelephoneNumberArea;
