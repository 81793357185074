import { Address, AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';

import {
    CompanyDataProperties,
    Person,
    PrivateDataProperties,
    isContactDataPrivate,
    isSaxonyAnhaltOrThuringiaCadastralDistrictLocation,
} from '../types';
import isSaxony from './isSaxony';

export const formatAddress = (address: AddressLocation): [string, string] => [
    `${address.streetName} ${address.housenumber}${address.housenumberAddition ? address.housenumberAddition : ''}`,
    `${address.postcode} ${address.cityName}`,
];

export const addressToString = (address: Address): string =>
    `(${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city})`;

export const formatCadastralDistrict = (district: Partial<CadastralDistrictLocation>): string => {
    const { districtName, districtId, cadastralUnitCounter, cadastralUnitDenominator, stateCode } = district;
    if (isSaxonyAnhaltOrThuringiaCadastralDistrictLocation(district)) {
        return (
            `${districtName} (${districtId})` +
            `${!isSaxony(stateCode) ? `, ${+district.cadastralDistrict}` : ''}` +
            `, ${+cadastralUnitCounter}` +
            `${+cadastralUnitDenominator ? `/${+cadastralUnitDenominator}` : ''}`
        );
    }
    return (
        `${districtName} (${districtId})` +
        `, ${+cadastralUnitCounter}` +
        `${+cadastralUnitDenominator ? `/${+cadastralUnitDenominator}` : ''}`
    );
};

const formatAcademicTitle = (academicTitle: 'Dr' | 'Prof' | 'ProfDr' | undefined) => {
    switch (academicTitle) {
        case 'Dr':
            return 'Dr. ';
        case 'Prof':
            return 'Prof. ';
        case 'ProfDr':
            return 'Prof. Dr. ';

        default:
            return undefined;
    }
};

export const formatPersonName = (person: Person): string =>
    `${formatAcademicTitle(person.academicTitle) || ''}${person.firstName} ${person.lastName}`;

export const getNameFromContactData = (contactData: PrivateDataProperties | CompanyDataProperties) =>
    isContactDataPrivate(contactData) ? formatPersonName(contactData.person) : contactData.companyName;
