import { Dropdown, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { AddRemoveButtonGroup as ButtonGroup } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import useMultiField from '../../../components/useMultiField';
import { GasConsumptionUnknownData, GasHeatingOptions, GasHeatings, UserType } from '../../../types';

export type GasHeatingProps = {
    specialOrSingleFamily: boolean;
    userType: UserType;
    syncContext: () => void;
};

export default function GasHeating({
    specialOrSingleFamily,
    userType,
    syncContext,
}: GasHeatingProps): React.ReactElement {
    const reactHookForm = useFormContext<GasConsumptionUnknownData>();
    const {
        register,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
        reset,
    } = reactHookForm;

    const [fields, append, remove] = useMultiField({
        control,
        name: 'gasHeatings',
        defaultValue: {
            type: '',
            capacityInKW: undefined,
            amount: specialOrSingleFamily ? 1 : undefined,
        },
        resetDefaultValues: reset,
        syncContext,
    });

    return (
        <FormLayout>
            <FormItem
                label={`Welche Art von Erdgasheizung ${
                    userType === 'installer' || userType === 'projectpartner' ? 'wird Ihr Kunde' : 'werden Sie'
                } haben?`}
                className="grid-cols-[1fr_4fr]"
            >
                <div className="flex flex-col gap-y-3">
                    {fields.map((field, index) => {
                        const onTypeChange = () => {
                            const { type } = getValues(`gasHeatings.${index}`);
                            const selectedObject = GasHeatings.find((element) => element.type === type);
                            if (selectedObject) {
                                setValue(`gasHeatings.${index}.capacityInKW`, selectedObject.capacityInKW, {
                                    shouldValidate: true,
                                });
                                if (specialOrSingleFamily) {
                                    setValue(`gasHeatings.${index}.amount`, 1, {
                                        shouldValidate: true,
                                    });
                                }
                            }
                            syncContext();
                        };

                        return (
                            <div className="flex flex-row items-center justify-start w-full gap-x-6" key={field.id}>
                                <div className="flex items-center justify-start w-10/12 md:w-2/3 gap-x-2 ml-px mb-1">
                                    <div className="flex flex-col md:grid md:w-3/5 min-w-[120px]">
                                        {index === 0 && <span className="text-small">Art Erdgasheizung</span>}
                                        <Dropdown
                                            name={`gasHeatings.${index}.name`}
                                            dropdownClassname="w-full"
                                            options={GasHeatingOptions}
                                            {...register(`gasHeatings.${index}.type` as const, {
                                                required: true,
                                                shouldUnregister: true,
                                                onChange: onTypeChange,
                                            })}
                                        />
                                    </div>
                                    <div className="flex flex-col md:grid">
                                        {index === 0 && <span className="text-small">Leistung </span>}
                                        <NumberInput
                                            name={`gasHeatings.${index}.capacityInKW`}
                                            {...NumberInputFunctions.registerNumberField(
                                                reactHookForm,
                                                `gasHeatings.${index}.capacityInKW`,
                                                {
                                                    required: true,
                                                    shouldUnregister: true,
                                                    min: 1,
                                                    max: 999,
                                                    onChange: syncContext,
                                                },
                                            )}
                                            displayErrorMessage={
                                                touchedFields?.gasHeatings?.[index]?.capacityInKW &&
                                                errors?.gasHeatings?.[index]?.capacityInKW &&
                                                'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            }
                                        />
                                    </div>
                                    <span className={`${index === 0 ? 'mt-4 ' : ''} mr-0 md:mr-4`}>kW</span>
                                    <div className="flex flex-col md:grid">
                                        {index === 0 && <span className="text-small">Anzahl</span>}
                                        <NumberInput
                                            name={`gasHeatings.${index}.amount`}
                                            {...NumberInputFunctions.registerNumberField(
                                                reactHookForm,
                                                `gasHeatings.${index}.amount`,
                                                {
                                                    required: true,
                                                    shouldUnregister: true,
                                                    min: 1,
                                                    max: 999,
                                                    onChange: syncContext,
                                                },
                                            )}
                                            displayErrorMessage={
                                                touchedFields?.gasHeatings?.[index]?.amount &&
                                                errors?.gasHeatings?.[index]?.amount &&
                                                'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            }
                                        />
                                    </div>
                                </div>
                                <ButtonGroup
                                    group="gasHeatings"
                                    handleAppend={append}
                                    handleDelete={remove(index)}
                                    fields={fields}
                                    index={index}
                                    width="w-2/12 md:w-1/3"
                                />
                            </div>
                        );
                    })}
                </div>
            </FormItem>
        </FormLayout>
    );
}
