import { FormItem, NumberInput, NumberInputFunctions, TextField, useRow } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { UseFormReturn, useFieldArray } from 'react-hook-form';

import { AddRemoveButtonGroup } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { PowerConsumptionTotalProperties } from '../../types';

export type fieldsProps = {
    reactHookForm: UseFormReturn<PowerConsumptionTotalProperties>;
};

export default ({ reactHookForm }: fieldsProps): React.ReactElement => {
    const {
        control,
        register,
        formState: { errors, touchedFields },
        watch,
    } = reactHookForm;

    const { fields, append, remove } = useFieldArray({
        control,
        shouldUnregister: true,
        name: 'infrastructure',
    });

    const { handleAppend, handleDelete } = useRow({ append, fields, remove });

    return (
        <>
            <div className="grid grid-flow-col items-center">
                <span className="font-bold">Weitere Infrastruktur</span>
            </div>
            <FormLayout>
                <FormItem
                    label={
                        <>
                            Welche weiteren Anwendungen mit einer Leistung von 12 kW oder mehr werden Sie haben?
                            <span className="text-darkest-grey ml-1">(optional)</span>
                        </>
                    }
                >
                    <div className="flex flex-col gap-y-3">
                        {fields.map((field, index) => {
                            const capacity = watch(`infrastructure.${index}.capacity`)?.toString();
                            const amount = watch(`infrastructure.${index}.amount`)?.toString();
                            return (
                                <div className="flex flex-row items-center justify-start w-full gap-x-6" key={field.id}>
                                    <div className="flex items-center justify-start w-full sm:w-2/3 gap-x-2 ml-px mb-1">
                                        <div className="grid w-full">
                                            {index === 0 && <span className="text-small">Anwendungsname</span>}
                                            <TextField
                                                name={`infrastructure.${index}.name`}
                                                {...register(`infrastructure.${index}.name` as const, {
                                                    minLength: 2,
                                                    maxLength: 40,
                                                })}
                                                displayErrorMessage={
                                                    errors?.infrastructure?.[index.toString()]?.name &&
                                                    'Bitte geben Sie einen Anwendungsnamen mit 2-40 Zeichen an.'
                                                }
                                            />
                                        </div>
                                        <div className="grid">
                                            {index === 0 && <span className="text-small">Anzahl</span>}
                                            <NumberInput
                                                name={`infrastructure.${index}.amount`}
                                                {...NumberInputFunctions.registerNumberInput(
                                                    reactHookForm,
                                                    `infrastructure.${index}.amount`,
                                                    {
                                                        required: capacity !== '',
                                                        shouldUnregister: true,
                                                        min: 1,
                                                        max: 999,
                                                    },
                                                )}
                                                displayErrorMessage={
                                                    errors?.infrastructure?.[index.toString()]?.amount &&
                                                    touchedFields?.infrastructure?.[index.toString()]?.amount &&
                                                    'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                                }
                                            />
                                        </div>
                                        <div className="grid">
                                            {index === 0 && <span className="text-small">Leistung</span>}
                                            <NumberInput
                                                name={`infrastructure.${index}.capacity`}
                                                {...NumberInputFunctions.registerNumberInput(
                                                    reactHookForm,
                                                    `infrastructure.${index}.capacity`,
                                                    {
                                                        required: amount !== '',
                                                        shouldUnregister: true,
                                                        min: 1,
                                                    },
                                                )}
                                                displayErrorMessage={
                                                    errors?.infrastructure?.[index.toString()]?.capacity &&
                                                    touchedFields?.infrastructure?.[index.toString()]?.capacity &&
                                                    'Bitte geben Sie eine Zahl größer als 0 ein.'
                                                }
                                            />
                                        </div>
                                        <span className={`${index === 0 ? 'mt-4' : ''}`}>kW</span>
                                    </div>
                                    <AddRemoveButtonGroup
                                        group="infrastructure"
                                        handleAppend={handleAppend}
                                        handleDelete={handleDelete(index)}
                                        fields={fields}
                                        index={index}
                                        width=""
                                    />
                                </div>
                            );
                        })}
                    </div>
                </FormItem>
            </FormLayout>
        </>
    );
};
