import { FormItem } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

export interface Props {
    label: string | React.ReactElement;
    children: React.ReactElement[];
    gridTemplateColumns?: string;
    classNameForContent?: string;
    itemsLayout?: 'items-start' | 'items-center' | 'items-end';
}

export default ({ children, ...formItemProps }: Props): React.ReactElement => (
    <FormItem {...formItemProps}>
        <div className="flex flex-col lg:flex-row gap-y-2 lg:gap-y-0" style={{ width: '300px' }}>
            <div style={{ width: '125px' }}>{children[0]}</div>
            <div style={{ width: '125px' }}>{children[1]}</div>
        </div>
    </FormItem>
);
