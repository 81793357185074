import {
    Checkbox,
    Energy,
    Form,
    FormLayout,
    Gas,
    LoadingSpinner,
    RadioButton,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { ClosedQuestionsWrapper, createPrevNextButton } from '../../components';
import { MaterialList } from '../../offer/api/material-costs/MaterialPriceAPI';
import { PersonalContributionProperties, UpdateContextFunction } from '../../types';
import { Context, useContextUpdate, useDynamicDefault } from '../../wizard';
import Table from './Table';

export interface PersonalContributionFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    materialList: MaterialList;
}

export default ({
    onSubmit,
    goBack,
    updateContext,
    context,
    materialList,
}: PersonalContributionFormProps): React.ReactElement => {
    const multiEntry = context.multiEntry === 'yes';
    const counter = context.electricityPosition === 'outside' && context.connectionType === 'counter';

    const reactHookForm = useForm<PersonalContributionProperties>({
        mode: 'all',
        shouldUnregister: true,
        defaultValues: {
            personalContribution: multiEntry || counter ? 'yes' : (context.personalContribution ?? 'no'),
            personalContributionMultiEntryPower: multiEntry,
            personalContributionCounter: counter,
            personalContributionMultiEntryGas: multiEntry,
            personalContributionCivilEngineeringPower: context.personalContributionCivilEngineeringPower,
            personalContributionCivilEngineeringGas: context.personalContributionCivilEngineeringGas,
            haveReadInformationSheetCivilEngineeringPower: context.haveReadInformationSheetCivilEngineeringPower,
            haveReadInformationSheetCivilEngineeringGas: context.haveReadInformationSheetCivilEngineeringGas,
        },
    });

    const {
        control,
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { isValid: isErrorFree, errors },
    } = reactHookForm;

    const personalContribution = watch('personalContribution') === 'yes';

    const personalContributionMultiEntryPower = useWatch({ control, name: 'personalContributionMultiEntryPower' });
    const personalContributionCivilEngineeringPower = useWatch({
        control,
        name: 'personalContributionCivilEngineeringPower',
    });
    const personalContributionCounter = useWatch({ control, name: 'personalContributionCounter' });
    const multiFamilyGas = useWatch({ control, name: 'personalContributionMultiEntryGas' });
    const personalContributionCivilEngineeringGas = useWatch({
        control,
        name: 'personalContributionCivilEngineeringGas',
    });

    React.useEffect(() => {
        updateContext({
            personalContribution: watch('personalContribution'),
            personalContributionMultiEntryPower,
            personalContributionCivilEngineeringPower,
            personalContributionCivilEngineeringGas,
            personalContributionCounter,
            personalContributionMultiEntryGas: multiFamilyGas,
        });
        // eslint-disable-next-line
    }, []);

    useDynamicDefault(
        setValue,
        personalContribution && context.gas,
        'personalContributionCivilEngineeringGas',
        context.personalContributionCivilEngineeringGas ?? false,
    );
    useDynamicDefault(
        setValue,
        personalContribution && context.power,
        'personalContributionCivilEngineeringPower',
        context.personalContributionCivilEngineeringPower ?? false,
    );
    useContextUpdate(watch, updateContext, 'TRANSPOSITION_PERSONAL_CONTRIBUTION');

    const isValid =
        !personalContribution ||
        personalContributionMultiEntryPower ||
        personalContributionCivilEngineeringPower ||
        personalContributionCounter ||
        multiFamilyGas ||
        personalContributionCivilEngineeringGas;

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isErrorFree && isValid,
    });

    if (materialList === undefined) {
        return <LoadingSpinner />;
    }

    const powerTable = (
        <Table
            name="Strom"
            icon={<Energy />}
            firstRow={
                multiEntry && (
                    <div className="grid w-full" style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}>
                        <div>Durchbruch / Mehrspartenhauseinführung</div>
                        <div className="justify-self-end mr-4">{materialList.VTEPHAEGMK.price.net} EUR</div>
                        <div className="justify-self-end ">{materialList.VTEPHAEGMK.price.gross} EUR</div>
                        <div className={`justify-self-center mt-1 ${multiEntry && 'opacity-60 pointer-events-none'}`}>
                            <Checkbox
                                name="personalContributionMultiEntryPower"
                                {...register('personalContributionMultiEntryPower', { required: false })}
                            />
                        </div>
                    </div>
                )
            }
            secondRow={
                <div
                    className="grid w-full cursor-pointer"
                    style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}
                    onClick={() =>
                        setValue(
                            'personalContributionCivilEngineeringPower',
                            !personalContributionCivilEngineeringPower,
                        )
                    }
                    onKeyDown={undefined}
                    role="button"
                    tabIndex={-1}
                >
                    <div>Tiefbau</div>
                    <div className="justify-self-end mr-4">{materialList.VTEPEKNGEL.price.net} EUR</div>
                    <div className="justify-self-end ">{materialList.VTEPEKNGEL.price.gross} EUR</div>
                    <div className="justify-self-center mt-1">
                        <Checkbox
                            name="personalContributionCivilEngineeringPower"
                            {...register('personalContributionCivilEngineeringPower', { required: false })}
                        />
                    </div>
                </div>
            }
            thirdRow={
                counter && (
                    <div className="grid w-full mb-1" style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}>
                        <div className="">Bereitstellung Hausanschlusskasten über Zähleranschlusssäule</div>
                        <div className="justify-self-end mr-4">{materialList.VTEPZASG.price.net} EUR</div>
                        <div className="justify-self-end ">{materialList.VTEPZASG.price.gross} EUR</div>
                        <div className={`justify-self-center mt-1 ${counter && 'opacity-60 pointer-events-none'}`}>
                            <Checkbox
                                name="counter"
                                {...register('personalContributionCounter', { required: false })}
                            />
                        </div>
                    </div>
                )
            }
        />
    );

    const gasTable = (
        <Table
            name="Erdgas"
            icon={
                <div className="mr-1">
                    <Gas />
                </div>
            }
            firstRow={
                multiEntry && (
                    <div className="grid w-full" style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}>
                        <div>Durchbruch / Mehrspartenhauseinführung</div>
                        <div className="justify-self-end mr-4">{materialList.VTEPGHEKB.price.net} EUR</div>
                        <div className="justify-self-end ">{materialList.VTEPGHEKB.price.gross} EUR</div>
                        <div className={`justify-self-center mt-1 ${multiEntry && 'opacity-60 pointer-events-none'}`}>
                            <Checkbox
                                name="multiFamilyGas"
                                {...register('personalContributionMultiEntryGas', { required: false })}
                            />
                        </div>
                    </div>
                )
            }
            secondRow={
                <div
                    className="grid w-full cursor-pointer"
                    style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}
                    onClick={() =>
                        setValue('personalContributionCivilEngineeringGas', !personalContributionCivilEngineeringGas)
                    }
                    onKeyDown={undefined}
                    role="button"
                    tabIndex={-1}
                >
                    <div>Tiefbau</div>
                    <div className="justify-self-end mr-4">{materialList.VTEPGN25GEL.price.net} EUR</div>
                    <div className="justify-self-end ">{materialList.VTEPGN25GEL.price.gross} EUR</div>
                    <div className="justify-self-center mt-1">
                        <Checkbox
                            name="personalContributionCivilEngineeringGas"
                            {...register('personalContributionCivilEngineeringGas', { shouldUnregister: true })}
                        />
                    </div>
                </div>
            }
        />
    );

    return (
        <Form
            title="Eigenleistungen"
            handleSubmit={handleSubmit(onSubmit)}
            leftButton={prevButton}
            rightButton={nextButton}
        >
            <FormLayout>
                <ClosedQuestionsWrapper
                    gridTemplateColumns="1fr 1fr 1fr"
                    classNameForContent={`${(multiEntry || counter) && 'opacity-60 pointer-events-none'}`}
                    label={`${
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Möchte Ihr Kunde einen Teil des Vorhabens wie z.B. den Tiefbau selbst übernehmen?'
                            : 'Möchten Sie einen Teil des Vorhabens wie z.B. den Tiefbau selbst übernehmen?'
                    }`}
                >
                    <RadioButton
                        id="personalContribution-yes"
                        name="personalContribution"
                        value="yes"
                        {...register('personalContribution')}
                    >
                        ja
                    </RadioButton>
                    <RadioButton
                        id="personalContribution-no"
                        name="personalContribution"
                        value="no"
                        {...register('personalContribution')}
                    >
                        nein
                    </RadioButton>
                </ClosedQuestionsWrapper>
            </FormLayout>

            {personalContribution && (
                <>
                    <div className="flex flex-col gap-y-4">
                        <div>
                            {context.userType === 'installer' || context.userType === 'projectpartner' ? (
                                <div>Welche Eigenleistungen plant Ihr Kunde zu erbringen?</div>
                            ) : (
                                <div>Welche Eigenleistungen planen Sie zu erbringen?</div>
                            )}

                            <div className="text-small">
                                <span className="font-bold">Hinweis:</span> Die Eigenleistungen reduzieren die
                                Angebotskosten, müssen jedoch für eine Anrechnung sachgerecht erbracht werden.
                            </div>
                        </div>
                        {(context.power || context.gas) && (
                            <div className="flex flex-col gap-y-8">
                                {context.power && powerTable}
                                {context.gas && gasTable}
                            </div>
                        )}
                    </div>
                    {(personalContributionCivilEngineeringPower || personalContributionCivilEngineeringGas) && (
                        <div className="grid space-y-5">
                            {personalContributionCivilEngineeringPower && (
                                <div className="flex space-x-5 justify-between" style={{ width: '650px' }}>
                                    <label
                                        className="cursor-pointer"
                                        htmlFor="checkbox-haveReadInformationSheetCivilEngineeringPower"
                                    >
                                        Ich habe das{' '}
                                        <a
                                            href={
                                                '/connection-request-form/files/' +
                                                'contribution/Tiefbaumerk_Strom_TEN.pdf'
                                            }
                                            className="text-primary"
                                            download
                                        >
                                            Merkblatt Tiefbaueigenleistung Strom
                                        </a>{' '}
                                        gelesen und verstanden.{' '}
                                    </label>
                                    <div className="flex items-center">
                                        <Checkbox
                                            name="haveReadInformationSheetCivilEngineeringPower"
                                            {...register('haveReadInformationSheetCivilEngineeringPower', {
                                                required:
                                                    'Bitte bestätigen Sie, dass Sie das Merkblatt' +
                                                    ' Tiefbaueigenleistung Strom gelesen und verstanden haben.',
                                                shouldUnregister: true,
                                            })}
                                            errorMessage={
                                                errors?.haveReadInformationSheetCivilEngineeringPower?.message
                                            }
                                            tooltipClassNames="w-96"
                                        />
                                    </div>
                                </div>
                            )}
                            {personalContributionCivilEngineeringGas && (
                                <div className="flex space-x-5 justify-between" style={{ width: '650px' }}>
                                    <label
                                        className="cursor-pointer"
                                        htmlFor="checkbox-haveReadInformationSheetCivilEngineeringGas"
                                    >
                                        Ich habe das{' '}
                                        <a
                                            href={
                                                '/connection-request-form/files/contribution/' +
                                                'Tiefbaumerk_Erdgas_TEN.pdf'
                                            }
                                            className="text-primary"
                                            download
                                        >
                                            Merkblatt Tiefbaueigenleistung Erdgas
                                        </a>{' '}
                                        gelesen und verstanden.{' '}
                                    </label>
                                    <div className="flex items-center">
                                        <Checkbox
                                            name="haveReadInformationSheetCivilEngineeringGas"
                                            {...register('haveReadInformationSheetCivilEngineeringGas', {
                                                required:
                                                    'Bitte bestätigen Sie, dass Sie das Merkblatt' +
                                                    ' Tiefbaueigenleistung Erdgas gelesen und verstanden haben.',
                                                shouldUnregister: true,
                                            })}
                                            errorMessage={errors?.haveReadInformationSheetCivilEngineeringGas?.message}
                                            tooltipClassNames="w-96"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </Form>
    );
};
