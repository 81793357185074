import * as React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { CONNECTION_DATA, CONNECTION_PLAN } from '../routes';
import ConnectionDataForm from './connection-data-form';
import ConnectionPlanForm from './connection-plan-form';
import LandOwnerForm from './land-owner-form';

export default ({ onSubmit, goBack, updateContext, context }: Switch): React.ReactElement => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={CONNECTION_DATA}>
                <ConnectionDataForm
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={context}
                />
            </Route>
            <Route path={CONNECTION_PLAN}>
                <ConnectionPlanForm
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={context}
                />
            </Route>
            <Route path={`${path}/eigentuemer`}>
                <LandOwnerForm onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={context} />
            </Route>
        </Switch>
    );
};
