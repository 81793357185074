import { Checkbox, Form, FormItem, InfoI, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { ClosedQuestionsWrapper, createPrevNextButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { LandOwnerProperties, UpdateContextFunction } from '../../types';
import { Context, useContextUpdate, useDynamicDefault } from '../../wizard';

export interface LandOwnerFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
}

export default ({ onSubmit, goBack, updateContext, context }: LandOwnerFormProps): React.ReactElement => {
    const reactHookForm = useForm<LandOwnerProperties>({
        mode: 'all',
        shouldUnregister: true,
        defaultValues: {
            isLandOwner: context.isLandOwner,
        },
    });
    const {
        control,
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { isValid, errors },
    } = reactHookForm;
    const landOwner = useWatch({ control, name: 'isLandOwner' });

    useDynamicDefault(setValue, landOwner !== 'yes', 'hasLandOwnerConsent', context.hasLandOwnerConsent ?? false);
    useContextUpdate(watch, updateContext, 'CONNECTION_LAND_OWNER');

    const landOwnerConsentErrorMessage =
        'Bitte bestätigen Sie, dass Ihnen das Einverständnis des Grundstückseigentümers vorliegt.';
    const infoDescription = (
        <span>
            Sie bestätigen, dass Sie den Grundstückseigentümer über den beantragten Netzanschluss informiert und sein
            Einverständnis zu Maßnahmen auf dem Grundstück eingeholt haben.
            <br /> Sofern ein Einverständnis des Grundstückseigentümers nicht vorliegt, sind Arbeiten auf dem Grundstück
            als Eingriff ins Eigentum eines Dritten zu werten und Sie können sowohl strafrechtlich als auch
            zivilrechtlich belangt werden.
        </span>
    );
    const multiEntryLabel = (
        <div className="inline">
            <span>
                {context.userType === 'installer' || context.userType === 'projectpartner'
                    ? 'Mein Kunde hat den Grundstückseigentümer über das Vorhaben informiert und ihm liegt sein ' +
                      'Einverständnis dafür vor.'
                    : 'Ich habe den Grundstückseigentümer über das Vorhaben informiert und mir liegt sein ' +
                      'Einverständnis dafür vor.'}
            </span>
            <div
                className="inline-block ml-2 w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue"
            >
                <InfoI size="30rem" text={infoDescription} />
            </div>
        </div>
    );

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
    });
    return (
        <Form
            title="Grundstückseigentümer Standort"
            handleSubmit={handleSubmit(onSubmit)}
            leftButton={prevButton}
            rightButton={nextButton}
        >
            <FormLayout>
                <ClosedQuestionsWrapper
                    label={`${
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Ist Ihr Kunde der Eigentümer des Grundstücks, für das Sie den Service beantragen?'
                            : 'Sind Sie der Eigentümer des Grundstücks, für das Sie den Service benötigen?'
                    } `}
                    gridTemplateColumns="1fr 1fr 1fr"
                >
                    <RadioButton
                        name="landOwner"
                        id="landOwner-yes"
                        value="yes"
                        {...register('isLandOwner', { shouldUnregister: true })}
                    >
                        ja
                    </RadioButton>

                    <RadioButton
                        name="landOwner"
                        id="landOwner-no"
                        value="no"
                        {...register('isLandOwner', { shouldUnregister: true })}
                    >
                        nein
                    </RadioButton>
                </ClosedQuestionsWrapper>
                {landOwner !== 'yes' && (
                    <FormItem label={multiEntryLabel}>
                        <Checkbox
                            name="consent"
                            {...register('hasLandOwnerConsent', { required: true })}
                            errorMessage={errors.hasLandOwnerConsent && landOwnerConsentErrorMessage}
                        />
                    </FormItem>
                )}
            </FormLayout>
        </Form>
    );
};
