import { GasDeviceInput, GasHeatingInput } from '../types';

export const gasHeatings: GasHeatingInput[] = [
    {
        type: 'Brennwertgerät mit Warmwasser',
        capacityInKW: 20,
    },
    {
        type: 'Brennwertgerät ohne Warmwasser',
        capacityInKW: 15,
    },
    {
        type: 'Heizkessel mit Warmwasser',
        capacityInKW: 20,
    },
    {
        type: 'Heizkessel ohne Warmwasser',
        capacityInKW: 15,
    },
];
export const gasHeatingOptions = gasHeatings.map(({ type }) => ({ value: type, label: type }));

export const gasDevices: GasDeviceInput[] = [
    {
        type: '',
        capacityInKW: undefined,
    },
    {
        type: 'BHKW',
        capacityInKW: 25,
    },
    {
        type: 'Brennstoffzelle',
        capacityInKW: 25,
    },
    {
        type: 'Durchlaufwasserheizer',
        capacityInKW: 18,
    },
    {
        type: 'Umlaufwasserheizer',
        capacityInKW: 15,
    },
];
export const gasDevicesOptions = gasDevices.map(({ type }) => ({ value: type, label: type }));
