import { ErrorBox, Form } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { TwoButton } from '../../components';
import { Context } from '../../wizard';

export type InstallerConclusion = {
    prevButton: React.ReactElement;
    nextButton: React.ReactElement;
    context: Partial<Context>;
    concludeOfferError: boolean;
};

export type InstallerOfferState =
    | 'everyBranchSelectedHasOffer'
    | 'onlyOneBranchSelectedHasOffer'
    | 'noOffer'
    | 'initial';

const NonCustomerConclusionForm = ({
    prevButton,
    nextButton,
    context,
    concludeOfferError,
}: InstallerConclusion): React.ReactElement => {
    const [offerState, setOfferState] = React.useState<InstallerOfferState>('initial');
    const [showError, setShowError] = React.useState<boolean>(false);

    const { gas, power } = context;
    const { isAutomaticElectricityOfferPossible, isAutomaticGasOfferPossible } = context.meta;

    React.useEffect(() => {
        if (concludeOfferError) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [concludeOfferError]);

    React.useEffect(() => {
        setOfferState('initial');
        const hasGasAndPower = gas && power;

        if (hasGasAndPower) {
            if (isAutomaticElectricityOfferPossible && isAutomaticGasOfferPossible) {
                setOfferState('everyBranchSelectedHasOffer');
            } else if (isAutomaticElectricityOfferPossible || isAutomaticGasOfferPossible) {
                setOfferState('onlyOneBranchSelectedHasOffer');
            } else {
                setOfferState('noOffer');
            }
        } else if (gas) {
            if (isAutomaticGasOfferPossible) {
                setOfferState('everyBranchSelectedHasOffer');
            } else {
                setOfferState('noOffer');
            }
        } else if (power) {
            if (isAutomaticElectricityOfferPossible) {
                setOfferState('everyBranchSelectedHasOffer');
            } else {
                setOfferState('noOffer');
            }
        }
        // eslint-disable-next-line
    }, [context]);

    switch (offerState) {
        case 'noOffer':
            return (
                <Form title="Unser Angebot für Ihren Kunden">
                    <div className="flex flex-col space-y-4">
                        <span> Fast geschafft – vielen Dank für Ihre Zeit!</span>
                        <span>
                            Nun sind wir an der Reihe: Ihre Angaben werden durch unsere regionalen Ansprechpartner
                            geprüft und es wird ein individuelles Angebot für Ihren Kunden erstellt. Das Angebot wird
                            Ihrem Kunden nach der erfolgreichen Prüfung zeitnah über unser Netzkundenportal zur
                            Verfügung gestellt. Ihr Kunde erhält eine Benachrichtigung, sobald unser Angebot für ihn
                            verfügbar ist.
                        </span>
                        <span>
                            Bitte schließen Sie Ihren Antrag nun ab, so dass die Prüfung und die individuelle
                            Angebotserstellung erfolgen kann.
                        </span>
                    </div>

                    {concludeOfferError && showError && <ErrorBox onClick={() => setShowError(false)} />}
                    <TwoButton leftButton={prevButton} rightButton={nextButton} />
                </Form>
            );
        case 'onlyOneBranchSelectedHasOffer':
            return (
                <Form title="Unser Angebot für Ihren Kunden">
                    <div className="flex flex-col space-y-4">
                        <span> Fast geschafft – vielen Dank für Ihre Zeit!</span>
                        <span>
                            Das Angebot für die Sparte {isAutomaticElectricityOfferPossible ? 'Strom ' : 'Erdgas '}
                            wurde nun für Ihren Kunden erstellt. Das Angebot ist für 12 Wochen gültig. Ihr Kunde kann
                            daher das Angebot innerhalb dieses Zeitraums über das Netzkundenportal jederzeit einsehen
                            und annehmen.
                        </span>
                        <span>
                            Für das Angebot der Sparte {!isAutomaticElectricityOfferPossible ? 'Strom ' : 'Erdgas '}
                            benötigen wir noch etwas Zeit. Ihre Angaben werden durch unsere regionalen Ansprechpartner
                            geprüft und es wird ein individuelles Angebot für Ihren Kunden erstellt. Das Angebot wird
                            Ihrem Kunden nach der erfolgreichen Prüfung zeitnah über unser Netzkundenportal zur
                            Verfügung gestellt. Ihr Kunde erhält eine Benachrichtigung, sobald unser Angebot für ihn
                            verfügbar ist.
                        </span>

                        <span>
                            Bitte schließen Sie Ihren Antrag nun ab, so dass die Prüfung und die individuelle
                            Angebotserstellung erfolgen kann.
                        </span>
                    </div>
                    {concludeOfferError && showError && <ErrorBox onClick={() => setShowError(false)} />}
                    <TwoButton leftButton={prevButton} rightButton={nextButton} />
                </Form>
            );
        case 'everyBranchSelectedHasOffer':
            return (
                <Form title="Unser Angebot für Ihren Kunden">
                    <div className="flex flex-col space-y-4">
                        <span> Fast geschafft – vielen Dank für Ihre Zeit!</span>
                        <span>
                            {isAutomaticElectricityOfferPossible && isAutomaticGasOfferPossible
                                ? 'Die Angebote '
                                : 'Das Angebot '}
                            für Ihren Kunden
                            {isAutomaticElectricityOfferPossible && isAutomaticGasOfferPossible
                                ? ' wurden '
                                : ' wurde '}
                            erstellt. Jedes Angebot ist für 12 Wochen gültig. Ihr Kunde kann daher
                            {isAutomaticElectricityOfferPossible && isAutomaticGasOfferPossible
                                ? ' die Angebote '
                                : ' das Angebot '}
                            innerhalb dieses Zeitraums über das Netzkundenportal jederzeit einsehen und annehmen.
                        </span>
                        <span>
                            Bitte schließen Sie Ihren Antrag nun ab, so dass wir Ihrem Kunden das Angebot zur Verfügung
                            stellen können.
                        </span>
                    </div>

                    {concludeOfferError && showError && <ErrorBox onClick={() => setShowError(false)} />}
                    <TwoButton leftButton={prevButton} rightButton={nextButton} />
                </Form>
            );
        case 'initial':
        default:
            return <div />;
    }
};

export default NonCustomerConclusionForm;
