import transformContextToContactData from '../../addresses/contact-address-form/transformContextToContactData';
import transformContextToInvoice from '../../addresses/invoice-address-form/transformContextToInvoice';
import transformContextToDrawing from '../../connection/connection-plan-form/transformContextToDrawing';
import transformContextToBuilding from '../../consumption/building-data-form/transformContextToBuilding';
import transformContextToGas from '../../consumption/gas-consumption-form/transformContextToGas';
import transformContextToElectricity from '../../consumption/transformContextToElectricity';
import transformContextToCadastralDistrict from '../../service/location-data-form/transformContextToCadastralDistrict';
import { transformInstallerArrayToSpecifiedBranchInstaller } from '../../transposition/installer-selection-form/transformInstallerRequest';
import { Context } from '../../wizard';
import { ConnectionRequest } from './types';

export default (context: Omit<Partial<Context>, 'meta'>): ConnectionRequest => ({
    serviceType: context.serviceType,
    userType: context.userType,
    building: transformContextToBuilding(context),
    cadastralDistrict: transformContextToCadastralDistrict(context),
    electricity: transformContextToElectricity(context),
    gas: transformContextToGas(context),
    invoice: transformContextToInvoice(context),
    contactData: transformContextToContactData(context),
    drawing: transformContextToDrawing(context),
    ...transformInstallerArrayToSpecifiedBranchInstaller(context.chosenInstallers),
});
