/*  eslint-disable import/no-unresolved */
import { Control, FieldArrayPath, FieldValues, UseFormReset, useFieldArray } from 'react-hook-form';
import { FieldArray, FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';

export default function useMultiField<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>(props: {
    control: Control<TFieldValues>;
    name: TFieldArrayName;
    defaultValue: FieldArray<TFieldValues, TFieldArrayName> | FieldArray<TFieldValues, TFieldArrayName>[];
    syncContext?: () => void;
    resetDefaultValues?: UseFormReset<TFieldValues>;
}): [FieldArrayWithId<TFieldValues, TFieldArrayName, 'id'>[], () => void, (index: number) => () => void] {
    const { control, name, defaultValue, resetDefaultValues, syncContext } = props;
    const { fields, append, remove } = useFieldArray({
        control,
        shouldUnregister: true,
        name,
    });

    const handleAppend = () => {
        append(defaultValue, {
            shouldFocus: false,
        });
    };

    const handleDelete = (index: number) => () => {
        if (fields.length > 1) {
            remove(index);
            resetDefaultValues?.((values) => ({ ...values }));
            syncContext?.();
        }
    };

    return [fields, handleAppend, handleDelete];
}
