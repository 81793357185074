import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';

const serviceUrl = appConfig.services.connectionRequestFormApi;

export const createStorageContainer = async ({
    customerId,
    axios,
    params,
}: ApiParams<{
    connectionRequestId: string;
    processCommunicationId: string;
}>) => {
    await axios.post(
        `${serviceUrl}/account/${customerId}/connection-request/${params.connectionRequestId}/offer/${params.processCommunicationId}/container`,
    );
};
