import { WizardStep } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';

import Addresses from './addresses';
import Connection from './connection';
import Consumption from './consumption';
import NextStepsForm from './nextSteps';
import Offer from './offer';
import getMaterialList, { MaterialList } from './offer/api/material-costs/MaterialPriceAPI';
import { ADDRESSES, CONNECTION, CONSUMPTION, NEXT_STEPS, OFFER, SERVICE, TRANSPOSITION } from './routes';
import Service from './service';
import Transposition from './transposition';
import {
    Context,
    NEXT_EVENT,
    PREV_EVENT,
    SUBPROCESS_VALUE,
    SYNC_LOCATION_WITH_STATE,
    SYNC_STATE_WITH_LOCATION,
    useWizardMachine,
} from './wizard';
import { getFurthestSteps } from './wizard/getFurthestSteps';

const AppRouting = (): React.ReactElement => {
    const location = useLocation();
    const history = useHistory();
    const [materialList, setMaterialList] = React.useState<MaterialList>();
    const [state, send] = useWizardMachine(history, location.pathname);
    const onSubmit = () => send(NEXT_EVENT);
    const goBack = () => send(PREV_EVENT);

    /**
     * By giving {process} in the function, the furthest step process will be changed into the process given in the
     * parameter.
     * Avoid using this in useEffect, because useEffect is run per default on the first render, before the subscribed
     * value changes. This mean, if we use this inside a useEffect and giving it a {process} parameter, the furthest step
     * in context will always be updated when the page first render, even if no value was changed.
     */
    const updateContext = React.useCallback(
        (payload: Partial<Context>, process?: SUBPROCESS_VALUE) => {
            const meta = process ? { ...getFurthestSteps(process), ...payload.meta } : undefined;
            if (meta) {
                return send({
                    type: 'UPDATE_CONTEXT',
                    value: { ...payload, meta },
                });
            }
            return send({
                type: 'UPDATE_CONTEXT',
                value: payload,
            });
        },
        [send],
    );

    React.useEffect(() => {
        const getMaterialCostAsync = async () => {
            if (materialList === undefined) {
                const materialPriceResponse = await getMaterialList();
                setMaterialList(materialPriceResponse);
            }
        };
        getMaterialCostAsync().catch((e) => console.error(e));
    }, [materialList]);

    React.useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, [history]);

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (statePath !== undefined && location.pathname !== statePath) {
                send({ type: SYNC_STATE_WITH_LOCATION, path: location.pathname });
            }
        },
        // eslint-disable-next-line
        [location.pathname],
    );

    React.useEffect(
        () => {
            const statePath = state.context.meta.path;
            if (statePath !== undefined && location.pathname !== statePath) {
                send({ type: SYNC_LOCATION_WITH_STATE });
            }
        }, // eslint-disable-next-line
        [state.context.meta.path],
    );

    return (
        <>
            <div className="w-full my-10 px-0 lg:px-32" style={{ maxWidth: '1536px' }}>
                <WizardStep
                    steps={state.context.meta.steps}
                    furthestStep={[
                        state.context.meta.furthestStep.furthestProcess,
                        state.context.meta.furthestStep.furthestSubprocess,
                    ]}
                    hasError={state.value === 'nextSteps'}
                />
            </div>

            <Route path={SERVICE}>
                <Service onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={state.context} />
            </Route>

            <Route path={CONSUMPTION}>
                <Consumption
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={state.context}
                />
            </Route>

            <Route path={CONNECTION}>
                <Connection onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={state.context} />
            </Route>

            <Route path={TRANSPOSITION}>
                <Transposition
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={state.context}
                    materialList={materialList}
                />
            </Route>

            <Route path={ADDRESSES}>
                <Addresses onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={state.context} />
            </Route>

            <Route path={OFFER}>
                <Offer
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={state.context}
                    materialList={materialList}
                />
            </Route>

            <Route path={NEXT_STEPS}>
                <NextStepsForm context={state.context} />
            </Route>
        </>
    );
};

export default AppRouting;
