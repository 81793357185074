import { Electricity, Gas } from '../../types';
import { getNameFromContactData } from '../../util/formatting';
import { isGetOfferResponseElectricity } from '../guards';
import { GetOfferResponse, MappedQuerySelectedOfferResponse } from '../types';

export const getTheRightConnectionRequest = (response: GetOfferResponse): Electricity | Gas => {
    if (isGetOfferResponseElectricity(response)) {
        return {
            chargingInfrastructure: response.chargingInfrastructure,
            chargingStation: response.chargingStation,
            extractionPlant: response.extractionPlant,
            extractionPlantLessThan30: response.extractionPlantLessThan30,
            heater: response.heater,
            infrastructure: response.infrastructure,
            personalContributionCivilEngineering: response.personalContributionCivilEngineering,
            personalContributionCounter: response.personalContributionCounter,
            personalContributionMultiEntry: response.personalContributionMultiEntry,
            predefinedConsumption: response.predefinedConsumption,
            waterHeater: response.waterHeater,
            commercialUnits: response.commercialUnits,
        };
    }
    return {
        personalContributionCivilEngineering: response.personalContributionCivilEngineering,
        personalContributionMultiEntry: response.personalContributionMultiEntry,
        predefinedConsumption: response.predefinedConsumption,
        additionalDevices: response.additionalDevices,
        devices: response.devices,
        heater: response.heater,
        outlets: response.outlets,
    };
};

export const mapQuerySelectedOfferResponseToProps = (response: GetOfferResponse): MappedQuerySelectedOfferResponse => {
    const { contactData, invoice, location, branch, automaticOffer, manualOffer } = response;

    return {
        name: getNameFromContactData(contactData),
        address: contactData.address,
        invoice,
        location,
        automaticOffer,
        manualOffer,
        connectionRequest: getTheRightConnectionRequest(response),
        branch,
        installerId: response.installerId,
    };
};
