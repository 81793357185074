import { Context } from '../../../wizard';

const GAS_OUTLETS_CONSUMPTION_KW = 13;

type Device = { capacityInKW?: number; amount?: number };

function addUp(sum: number, device: Device): number {
    return sum + (device.capacityInKW || 0) * (device.amount || 0);
}

const gasConsumptionCalculation = (context: Partial<Context>): number => {
    const { gasHeating, gasHeatings, gasOutlet, gasOutlets, gasDevices, gasAdditionalDevices } = context;

    const allDevices: Device[] = [...(gasDevices || []), ...(gasAdditionalDevices || [])];
    if (gasHeating === 'yes') allDevices.push(...(gasHeatings || []));
    if (gasOutlet === 'yes') allDevices.push({ amount: gasOutlets?.amount, capacityInKW: GAS_OUTLETS_CONSUMPTION_KW });

    const sumOfAllDevices = allDevices.reduce(addUp, 0);

    return Math.round(sumOfAllDevices);
};

export function shouldCalculateTotalConsumption(context: Partial<Context>) {
    const { gasHeating, gasOutlet, gasDevices, gasAdditionalDevices } = context;
    return (
        gasHeating === 'yes' ||
        gasOutlet === 'yes' ||
        gasDevices.every((element) => element.capacityInKW && element.amount) ||
        gasAdditionalDevices.every((element) => element.capacityInKW && element.amount)
    );
}

export default gasConsumptionCalculation;
