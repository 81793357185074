import { AuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { CustomerType } from '@ten-netzkundenportal/ui-auth-utils';
import {
    Button,
    Close,
    CustomerTypeSelection,
    Dropdown,
    ErrorBox,
    Form,
    FormItem,
    FormLayout,
    LoadingSpinnerButton,
    StyledLink,
    TextField,
} from '@ten-netzkundenportal/ui-components';
import LocationSearch, {
    AddressLocation,
    CadastralDistrictLocation,
    PolygonCadastralDistricts,
} from '@ten-netzkundenportal/ui-location-search';
import { isAxiosError } from 'axios';
import L, { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { navigateToUrl } from 'single-spa';

import appConfig from '../../app.config';
import { TwoButton } from '../../components';
import { LOCATION_DATA, NOT_RESPONSIBLE } from '../../routes';
import { Availability, LocationDataProperties, UpdateContextFunction, UserType } from '../../types';
import { TokenClaims } from '../../types/tokenClaims';
import { errorCodePasswordForgotButton } from '../../util/errorCodePasswordForgotButton';
import { isAddress } from '../../util/getCadastralDistrictLatLngArrayFromContextLocation';
import mapLocationToGeoCoordinates from '../../util/mapLocationToGeoCoordinates';
import { resetDrawingData } from '../../util/resetDrawingData';
import { useContextUpdate } from '../../wizard';
import checkAvailability from './api/CheckAddressAvailabilityAPI';

export interface LocationDataFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<LocationDataProperties>;
}

const showErrorBoxForNotFoundCadastralDistrict = (cadastralDistrictCoordinates?: LatLng[]) =>
    !cadastralDistrictCoordinates?.length;

const INPUT_ERROR_MESSAGE = 'Bitte geben Sie mind. 2 und max. 50 Zeichen an.';

const geojsonAreIdentical = (
    geojson1: number[] | PolygonCadastralDistricts[],
    geojson2: number[] | PolygonCadastralDistricts[],
): boolean => {
    const flattenedArray1 = geojson1.flat(2);
    const flattenedArray2 = geojson2.flat(2);
    return flattenedArray1.every((a, index) => a === flattenedArray2[index]);
};

export default ({ onSubmit, goBack, updateContext, context }: LocationDataFormProps): React.ReactElement => {
    const [abortController, setAbortController] = React.useState<AbortController>();

    const [userType, setUserType] = React.useState<UserType>(context?.userType);

    const history = useHistory();
    const [availability, setAvailability] = React.useState<{
        gasAvailability: Availability;
        electricityAvailability: Availability;
    }>();
    const [isResponsibilityChecked, setResponsibilityChecked] = React.useState(false);

    const [gisError, setGisError] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [isErrorBoxCadastralDistrictNotFoundShown, setIsErrorBoxCadastralDistrictNotFoundShown] =
        React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);

    const { accounts, instance } = useMsal();

    const {
        register,
        watch,
        setValue,
        trigger,
        formState: { errors, isValid: isUseFormValid },
    } = useForm<LocationDataProperties>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            userType: context?.userType,
            projectPartnerType: context?.projectPartnerType || 'plantBuilder',
            otherProjectPartnerType: context?.otherProjectPartnerType,
            location: context.location,
        },
    });

    const location = watch('location', context.location);
    const projectPartnerType = watch('projectPartnerType');

    useContextUpdate(watch, updateContext, 'SERVICE_LOCATION_DATA');

    const projectPartnerTypeDropdown = [
        {
            value: 'plantBuilder',
            label: 'Anlagenerrichter',
        },
        {
            value: 'planningOffice',
            label: 'Planungsbüro',
        },
        {
            value: 'stationBuilder',
            label: 'Stationserrichter',
        },
        {
            value: 'otherProjectPartner',
            label: 'Sonstiges',
        },
    ];

    React.useEffect(() => {
        if (accounts.length > 0) {
            const tokenClaimCustomType = (accounts[0].idTokenClaims as TokenClaims).extension_customerType as UserType;
            setUserType(tokenClaimCustomType);
            updateContext({ userType: tokenClaimCustomType });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts, userType, updateContext]);

    const isNotValid = (): boolean =>
        !isResponsibilityChecked || isLoading || !isUseFormValid || isErrorBoxCadastralDistrictNotFoundShown;

    const handleSubmit = async () => {
        if (
            availability.electricityAvailability.responsible === 'TEN' ||
            availability.gasAvailability.responsible === 'TEN'
        ) {
            onSubmit();
        } else {
            history.push(NOT_RESPONSIBLE);
        }
    };

    const locationSelectionContainer = React.useRef(null);
    React.useEffect(() => {
        if (locationSelectionContainer.current !== null) {
            L.DomEvent.disableClickPropagation(locationSelectionContainer.current);
            L.DomEvent.disableScrollPropagation(locationSelectionContainer.current);
        }
    });

    const navigateToLoginPage = () => {
        const redirectKey = 'portal-app-local-redirect-url';
        sessionStorage.setItem(redirectKey, LOCATION_DATA);
        instance.loginRedirect().catch((error) => {
            if ((error as AuthError).errorMessage?.includes(errorCodePasswordForgotButton)) {
                navigateToUrl('/account/passwort-vergessen');
            }
        });
    };

    const navigateToRegistrationPage = () => {
        const redirectKey = 'installer-post-signup-redirect-url';
        sessionStorage.setItem(redirectKey, LOCATION_DATA);
        navigateToUrl('/registrierung/');
    };

    function processChosenLocation(chosenLocation?: AddressLocation | CadastralDistrictLocation) {
        if (
            context.location &&
            chosenLocation?.geojson &&
            !geojsonAreIdentical(context.location.geojson.coordinates, chosenLocation.geojson.coordinates)
        ) {
            resetDrawingData(updateContext, 'SERVICE_LOCATION_DATA');
        }

        if (abortController) {
            abortController.abort();
        }

        setGisError(false);
        setShowError(false);

        if (!chosenLocation) {
            setValue('location', null);
            trigger('location');
            setResponsibilityChecked(false);
            setAvailability(undefined);
            return;
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);
        loadAdditionalDataForLocation(chosenLocation, newAbortController.signal);
    }

    async function loadAdditionalDataForLocation(
        chosenLocation: AddressLocation | CadastralDistrictLocation,
        abortSignal: AbortSignal,
    ) {
        try {
            setIsLoading(true);
            const availabilityResponse = await checkAvailability(
                mapLocationToGeoCoordinates(chosenLocation),
                abortSignal,
            );

            setIsErrorBoxCadastralDistrictNotFoundShown(
                showErrorBoxForNotFoundCadastralDistrict(availabilityResponse.cadastralDistrictCoordinates),
            );

            if (isAddress(chosenLocation)) {
                setValue('location', {
                    ...chosenLocation,
                    cadastralDistrictCoordinates: {
                        type: 'Polygon',
                        coordinates: [
                            availabilityResponse.cadastralDistrictCoordinates.map((coords) => [coords.lng, coords.lat]),
                        ],
                    },
                });
            } else {
                setValue('location', chosenLocation);
            }

            setAvailability({
                gasAvailability: availabilityResponse.gas,
                electricityAvailability: availabilityResponse.electricity,
            });

            if (availabilityResponse) {
                updateContext({
                    meta: {
                        locationAvailability: {
                            gas: availabilityResponse.gas,
                            electricity: availabilityResponse.electricity,
                        },
                    },
                });
            }

            setResponsibilityChecked(true);
            trigger('location');
        } catch (error) {
            if (!(isAxiosError(error) && error.name === 'CanceledError')) {
                console.error('Failed to check responsibility', error);
                setGisError(true);
                setShowError(true);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form
            title={`${
                accounts.length === 0 || userType === 'projectpartner' ? 'Antragsteller' : 'Standort des Anschlusses'
            }`}
        >
            {accounts.length === 0 && (
                <CustomerTypeSelection
                    customerType={userType}
                    onChange={(customer: CustomerType) => {
                        setUserType(customer);
                        updateContext({ userType: customer }, 'SERVICE_LOCATION_DATA');
                    }}
                />
            )}

            {userType === 'projectpartner' && (
                <div className="">
                    <FormLayout>
                        <FormItem label="Welche Art von Projektpartner sind Sie bei diesem Antrag?">
                            <Dropdown
                                options={projectPartnerTypeDropdown}
                                dropdownClassname="mr-16 w-56"
                                {...register('projectPartnerType', {
                                    required: true,
                                    shouldUnregister: true,
                                    value: context.projectPartnerType,
                                })}
                            />
                        </FormItem>
                        {projectPartnerType === 'otherProjectPartner' && (
                            <FormItem
                                label="Bitte nennen Sie Ihre Rolle als Projektpartner bei diesem Antrag:"
                                className="child:w-56"
                            >
                                <TextField
                                    name="otherProjectPartnerType"
                                    id="otherProjectPartnerType"
                                    {...register('otherProjectPartnerType', {
                                        required: 'Dies ist ein Pflichtfeld.',
                                        minLength: {
                                            value: 2,
                                            message: INPUT_ERROR_MESSAGE,
                                        },
                                        maxLength: {
                                            value: 50,
                                            message: INPUT_ERROR_MESSAGE,
                                        },
                                        value: context.otherProjectPartnerType,
                                    })}
                                    displayErrorMessage={errors.otherProjectPartnerType?.message}
                                />
                            </FormItem>
                        )}
                    </FormLayout>
                </div>
            )}

            {userType === 'installer' && accounts.length === 0 && (
                <div className="w-full grid relative gap-y-6 sm:gap-y-12 ">
                    <FormLayout>
                        <FormItem label="Bitte melden Sie sich an, um den Antrag für Ihren Kunden zu stellen.">
                            <div
                                className="grid sm:flex w-full justify-start lg:justify-start space-x-0
                        sm:space-x-8 gap-y-2 -mt-16 min-[958px]:mt-0"
                            >
                                <Button label="Zur Anmeldung" onClick={navigateToLoginPage} />
                                <Button label="Zur Registrierung" onClick={navigateToRegistrationPage} />
                            </div>
                        </FormItem>
                    </FormLayout>

                    <div className="self-end items-end flex mt-12">
                        <Button type="secondary" label="Zurück" onClick={goBack} />
                    </div>
                </div>
            )}

            {(accounts.length > 0 || userType !== 'installer') && (
                <div className={`${accounts.length > 0 ? '' : 'mt-16'} grid gap-y-6 sm:gap-y-12 `}>
                    {(accounts.length === 0 || userType === 'projectpartner') && (
                        <h3 className="font-bold text-medium">Standort des Anschlusses</h3>
                    )}
                    <div className="flex flex-col gap-y-4">
                        <span className="inline-flex items-center">
                            {userType === 'installer' || userType === 'projectpartner'
                                ? 'Bitte geben Sie die Adresse des Gebäudes, ' +
                                  'Grundstückes oder des Bauvorhabens ein. ' +
                                  'Wir prüfen anschließend, welche Services wir an diesem Standort anbieten können.'
                                : 'Bitte geben Sie die Adresse Ihres Gebäudes, ' +
                                  'Grundstückes oder Ihres Bauvorhabens ein. ' +
                                  'Wir prüfen anschließend, ' +
                                  'welche Services wir Ihnen an diesem Standort anbieten können.'}
                        </span>

                        <LocationSearch
                            apiKey={appConfig.services.gisMiddlewareApiKey}
                            initialLocation={{ location }}
                            setLocation={({ location: chosenLocation }) => processChosenLocation(chosenLocation)}
                        />
                    </div>
                    {gisError && showError && (
                        <div className="mt-10">
                            <ErrorBox onClick={() => setShowError(false)} />
                        </div>
                    )}
                    {isErrorBoxCadastralDistrictNotFoundShown && (
                        <div
                            className="flex shadow-ten hover:shadow-ten-hover h-24 p-4 space-x-8 items-center"
                            data-testid="error-box-no-cadastral-district"
                        >
                            <span className="flex w-12 text-primary fill-current">
                                <Close />
                            </span>
                            <span>
                                Leider konnten wir kein passendes Ergebnis zur eingegebenen Adresse ermitteln. Bitte
                                geben Sie anstelle der Adresse ein Flurstück an oder wenden Sie sich an unsere{' '}
                                <StyledLink href="mailto:netzkundenportal@thueringer-energienetze.com">
                                    Ansprechpartner
                                </StyledLink>
                                .
                            </span>
                        </div>
                    )}

                    <TwoButton
                        leftButton={<Button size="md" type="secondary" label="Zurück" onClick={goBack} />}
                        rightButton={
                            <LoadingSpinnerButton
                                size="md"
                                label="Weiter"
                                disabled={isNotValid() || !userType}
                                loading={isLoading}
                                onClick={() => handleSubmit()}
                                loadingTitle="Die Zuständigkeit für den Standort wird gerade geprüft."
                            />
                        }
                    />
                </div>
            )}
        </Form>
    );
};
