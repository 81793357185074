import { CompanyData, PrivateData, isBusinessDataCompany, isBusinessDataPrivate } from '../types';
import { formatPersonName } from '../util';
import { Context } from '../wizard';

export default (context: Partial<Context>): string => {
    if (context.userType === 'private') {
        const person = (context as PrivateData)?.privateData?.person;
        return person ? formatPersonName(person) : '-';
    }
    if (context.userType === 'company') {
        return (context as CompanyData)?.companyData?.companyName;
    }
    if (context.userType === 'installer' || context.userType === 'projectpartner') {
        if (isBusinessDataPrivate(context) && context.businessData?.customerType === 'private') {
            const person = context.businessData?.person;
            return person ? formatPersonName(person) : '-';
        }
        if (isBusinessDataCompany(context)) {
            return context.businessData?.companyName;
        }
    }
    throw Error(`Can't define applicant name based on user type ${context.userType}`);
};
