import * as React from 'react';

export type NumerationElementProps = {
    value: string;
};

// Once the textfield got redesigned, this element should be moved to the ui-component-library
export default ({ value }: NumerationElementProps): React.ReactElement => (
    <input
        className="text-center border border-dark-grey text-dark-grey min-w-full px-2 py-1 h-10 bg-white
        cursor-not-allowed select-none"
        value={value}
        size={1}
        disabled
    />
);
