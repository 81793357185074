import axios, { AxiosInstance, AxiosResponse } from 'axios';

import appConfig from '../../app.config';
import { ConnectionRequest, ConnectionRequestResponse } from './types';

const crfServiceUrl = `${appConfig.services.connectionRequestFormApi}`;
const createOfferForAuthenticatedUser = async (
    connectionRequest: ConnectionRequest,
    customerId: string,
    authenticatedAxios: AxiosInstance,
): Promise<AxiosResponse<ConnectionRequestResponse>> =>
    authenticatedAxios.post<ConnectionRequestResponse>(
        `${crfServiceUrl}/customer/${customerId}/connection-request`,
        connectionRequest,
    );

export const updateOfferForAuthenticatedUser = async (
    connectionRequest: ConnectionRequest,
    connectionRequestId: string,
    customerId: string,
    authenticatedAxios: AxiosInstance,
): Promise<AxiosResponse<ConnectionRequestResponse>> =>
    authenticatedAxios.put<ConnectionRequestResponse>(
        `${crfServiceUrl}/customer/${customerId}/connection-request/${connectionRequestId}`,
        connectionRequest,
    );

const createOfferForUnauthenticatedUser = async (
    connectionRequest: ConnectionRequest,
): Promise<AxiosResponse<ConnectionRequestResponse>> =>
    axios.post<ConnectionRequestResponse>(`${crfServiceUrl}/connection-request`, connectionRequest);

const updateOfferForUnauthenticatedUser = async (
    connectionRequest: ConnectionRequest,
    connectionRequestId: string,
): Promise<AxiosResponse<ConnectionRequestResponse>> =>
    axios.put<ConnectionRequestResponse>(
        `${crfServiceUrl}/connection-request/${connectionRequestId}`,
        connectionRequest,
    );

export const calculateOfferForUnauthenticatedUser = async (
    connectionRequest: ConnectionRequest,
    connectionRequestId?: string,
): Promise<ConnectionRequestResponse> => {
    const isExistingOffer = connectionRequestId !== undefined;
    let response: AxiosResponse<ConnectionRequestResponse>;

    if (isExistingOffer) {
        response = await updateOfferForUnauthenticatedUser(connectionRequest, connectionRequestId);
    } else {
        response = await createOfferForUnauthenticatedUser(connectionRequest);
    }

    if (response.status === 200 || response.status === 201) {
        return response.data;
    }
    throw new Error('Failed to calculate offer for passed connection request');
};

export const calculateOfferForAuthenticatedUser = async (
    connectionRequest: ConnectionRequest,
    customerId: string,
    authenticatedAxios: AxiosInstance,
    connectionRequestId?: string,
): Promise<ConnectionRequestResponse> => {
    const isExistingOffer = connectionRequestId !== undefined;
    let response: AxiosResponse<ConnectionRequestResponse>;

    if (isExistingOffer) {
        response = await updateOfferForAuthenticatedUser(
            connectionRequest,
            connectionRequestId,
            customerId,
            authenticatedAxios,
        );
    } else {
        response = await createOfferForAuthenticatedUser(connectionRequest, customerId, authenticatedAxios);
    }

    if (response.status === 200 || response.status === 201) {
        return response.data;
    }
    throw new Error('Failed to calculate offer for passed connection request');
};
