import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { InstallerFilterResultWithDecodedCompanyName } from '../../types';
import { Context } from '../../wizard';
import germanizeBoolean from './util';

export interface TranspositionColumnProps {
    context: Partial<Context>;
}

const parseInstallerData = (installer: InstallerFilterResultWithDecodedCompanyName): string[] => [
    `${installer.companyNameDecoded}`,
    `${installer.address.street} ${installer.address.houseNumber}`,
    `${installer.address.postalCode} ${installer.address.city}`,
];

const buildInstallerLabel = (installer: InstallerFilterResultWithDecodedCompanyName): string => {
    if (installer.electricityBranch && installer.gasBranch) {
        return 'Installateur für Strom und Erdgas';
    }
    if (installer.electricityBranch) {
        return 'Installateur für Strom';
    }
    if (installer.gasBranch) {
        return 'Installateur für Erdgas';
    }
    throw Error(`installer "${installer.companyName}" has no branch`);
};

const TranspositionColumn = ({ context }: TranspositionColumnProps): React.ReactElement => {
    const hasPersonalPowerContribution =
        context.personalContributionCivilEngineeringPower ||
        context.personalContributionMultiEntryPower ||
        context.personalContributionCounter ||
        false;

    const personalPowerContributions: string[] = [
        context.personalContributionMultiEntryPower && 'Durchbruch / Mehrspartenhauseinführung',
        context.personalContributionCivilEngineeringPower && 'Tiefbau',
        context.personalContributionCounter && 'Bereitstellung Hausanschlusskasten über Zähleranschlusssäule',
    ].filter(String);

    const hasPersonalGasContribution =
        context.personalContributionMultiEntryGas || context.personalContributionCivilEngineeringGas || false;
    const personalGasContributions: string[] = [
        context.personalContributionMultiEntryGas && 'Durchbruch / Mehrspartenhauseinführung',
        context.personalContributionCivilEngineeringGas && 'Tiefbau',
    ].filter(String);

    return (
        <Column title="Umsetzung">
            {context.power && (
                <ColumnSection title="Eigenleistung Strom">
                    <ColumnItem label="Eigenleistung" text={germanizeBoolean(hasPersonalPowerContribution)} />
                    {hasPersonalPowerContribution && (
                        <ColumnItem label="Eigenleistungsart" list={personalPowerContributions} />
                    )}
                </ColumnSection>
            )}
            {context.gas && (
                <ColumnSection title="Eigenleistung Erdgas">
                    <ColumnItem label="Eigenleistung" text={germanizeBoolean(hasPersonalGasContribution)} />
                    {hasPersonalGasContribution && (
                        <ColumnItem label="Eigenleistungsart" list={personalGasContributions} />
                    )}
                </ColumnSection>
            )}

            <ColumnSection title="Installateur">
                <>
                    {context.chosenInstallers?.length > 0 &&
                        context.chosenInstallers.map((installer) => (
                            <ColumnItem
                                key={installer.companyName}
                                label={buildInstallerLabel(installer)}
                                text={parseInstallerData(installer)}
                            />
                        ))}
                    {(!context.chosenInstallers || context.chosenInstallers?.length === 0) && <ColumnItem text="-" />}
                </>
            </ColumnSection>
        </Column>
    );
};

export default TranspositionColumn;
