import { downloadBlob, useContainerClientWrapper } from '@ten-netzkundenportal/ui-document-upload';
import { useCallback } from 'react';

import appConfig from '../../../../app.config';
import useApi from '../../../../hooks/useApi';
import { DocumentMetadata } from '../../../types';
import { createSas } from '../api/createSas';
import getExternDocument from '../api/getExternDocument';

export type UseDownloadProps = {
    processCommunicationId: string;
    connectionRequestId: string;
};

const useDownload = ({
    fileName,
    documentId,
    type,
    processCommunicationId,
    connectionRequestId,
}: DocumentMetadata & UseDownloadProps) => {
    const getExternDocumentApi = useApi(getExternDocument);
    const createSasApi = useApi(createSas);

    const createSasCallback = useCallback(
        () => createSasApi({ processCommunicationId, connectionRequestId }),
        [createSasApi, processCommunicationId, connectionRequestId],
    );

    const containerClientWrapper = useContainerClientWrapper({
        storageApiUrl: appConfig.services.connectionRequestFormUserUploadApi,
        containerName: `${processCommunicationId}-${connectionRequestId}`.toLowerCase(),
        createSas: createSasCallback,
    });

    const downloadExternalDocument = useCallback(async (): Promise<void> => {
        const blob = await getExternDocumentApi({
            processCommunicationId,
            documentId,
            connectionRequestId,
        });
        downloadBlob(blob, 'application/pdf', fileName);
    }, [getExternDocumentApi, processCommunicationId, documentId, connectionRequestId, fileName]);

    const downloadDocumentFromBlobStorage = useCallback(async () => {
        const { blob, contentType } = await containerClientWrapper.downloadBlob(documentId);
        downloadBlob(blob, contentType, fileName);
    }, [containerClientWrapper, documentId, fileName]);

    const download = useCallback(() => {
        switch (type) {
            case 'blob':
                return downloadDocumentFromBlobStorage();
            case 'extern':
                return downloadExternalDocument();
            default:
                throw new Error(`Invalid type ${type}`);
        }
    }, [downloadDocumentFromBlobStorage, downloadExternalDocument, type]);

    return {
        download,
    };
};

export default useDownload;
