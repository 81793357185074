import { CompanyData, PrivateData, isBusinessDataCompany, isBusinessDataPrivate } from '../../types';
import { ContactDataRequest } from '../../types/connectionRequest/contactData';
import { removeSpacesFromTelephoneNumber } from '../../util/removeSpacesFromTelephoneNumber';
import { trimObject } from '../../util/trimObject';
import { Context } from '../../wizard';

export const transformContextToContactData = (context: Partial<Context>): ContactDataRequest => {
    switch (context.userType) {
        case 'private':
            return trimObject({
                userType: 'private',
                person: (context as PrivateData).privateData?.person,
                address: (context as PrivateData).privateData?.address,
                telephone: removeSpacesFromTelephoneNumber(context.telephone),
            });
        case 'company':
            return trimObject({
                userType: 'company',
                companyName: (context as CompanyData).companyData?.companyName,
                address: (context as CompanyData).companyData?.address,
                telephone: removeSpacesFromTelephoneNumber(context.telephone),
            });
        case 'installer':
        case 'projectpartner':
            if (isBusinessDataPrivate(context)) {
                return trimObject({
                    userType: 'private',
                    person: context.businessData?.person,
                    address: context.businessData?.address,
                    telephone: removeSpacesFromTelephoneNumber(context.telephone),
                    email: context.businessData.email,
                });
            }
            if (isBusinessDataCompany(context)) {
                return trimObject({
                    userType: 'company',
                    companyName: context.businessData?.companyName,
                    address: context.businessData?.address,
                    telephone: removeSpacesFromTelephoneNumber(context.telephone),
                    email: context.businessData.email,
                });
            }
            throw Error('No valid customer type for installer');
        default:
            throw Error('No valid user type');
    }
};
