import { CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import React from 'react';

import { ServerAddressLocation, isAddressSearchResult } from '../../types';
import { formatAddress, formatCadastralDistrict } from '../../util';

export type LocationSectionProps = {
    location: ServerAddressLocation | CadastralDistrictLocation;
};
const LocationSection = ({ location }: LocationSectionProps) =>
    isAddressSearchResult(location) ? (
        <div>
            <div>{formatAddress(location)[0]}</div>
            <div>{formatAddress(location)[1]}</div>
        </div>
    ) : (
        <div>{formatCadastralDistrict(location as CadastralDistrictLocation)}</div>
    );

export default LocationSection;
