import { StyledLink } from '@ten-netzkundenportal/ui-components';
import React from 'react';

const NotResponsibleText = () => (
    <>
        <p>Es tut uns leid, wir sind an Ihrem Standort leider nicht zuständig.</p>
        <p>
            Bitte erkundigen Sie sich bei Ihrer Gemeinde oder über die Netzbetreibersuche auf{' '}
            <StyledLink href="https://vnbdigital.de/" target="_blank">
                VNB Digital
            </StyledLink>
            , wer für Sie zuständig ist.
        </p>
        <p>Vielen Dank und Ihnen alles Gute!</p>
        <p>Ihre Thüringer Energienetze</p>
    </>
);

export default NotResponsibleText;
