/* eslint-disable*/
import * as React from 'react';
import { SVGProps, Ref, forwardRef, memo } from 'react';

const SvgTenPiktoSolarenergie800X80023032221 = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
    <svg
        id="ten_pikto_solarenergie_800x800_230322_21_svg__Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        viewBox="0 0 841.9 595.3"
        xmlSpace="preserve"
        ref={ref}
        {...props}
    >
        <path
            className="ten_pikto_solarenergie_800x800_230322_21_svg__st0"
            d="M394.2 436.5 361.3 366h-70.6l32.9 70.5h70.6zM281.1 365.9h-75.4l32.9 70.6H314l-32.9-70.6zM489.7 439.1c34.6-24.5 57.3-64.8 57.3-110.5 0-74.6-60.5-135.2-135.2-135.2-54.6 0-101.6 32.4-122.9 79H412l77.7 166.7z"
        />
        <path
            className="ten_pikto_solarenergie_800x800_230322_21_svg__st0"
            d="M286.2 356.3h70.6l-31.2-66.9H255l31.2 66.9zM335.2 289.4l31.2 66.9h66.1l-31.2-66.9h-66.1zM370.9 365.9l32.9 70.6h66.1L437 365.9h-66.1zM408.3 446.1l35.1 75.4h66.1l-35.1-75.4h-66.1zM328.1 446.1l35.2 75.4h70.5l-35.1-75.4h-70.6zM243.1 446.1l35.1 75.4h75.4l-35.1-75.4h-75.4zM201.2 356.3h75.4l-31.2-66.9H170l31.2 66.9zM583 142.5l-3.9 3.9-67.7 67.7c4.2 3.7 8.3 7.6 12.1 11.7l67.8-67.8 3.9-3.9c-4-4-8-7.9-12.2-11.6z"
        />
        <path
            className="ten_pikto_solarenergie_800x800_230322_21_svg__st0"
            d="m583 142.5-3.9 3.9-67.7 67.7c4.2 3.7 8.3 7.6 12.1 11.7l67.8-67.8 3.9-3.9c-4-4-8-7.9-12.2-11.6zM243.3 140.6l-3.8-3.8c-4.3 3.6-8.5 7.3-12.6 11.2l3.8 3.8 71.7 71.7c3.9-4 8-7.9 12.3-11.4l-71.4-71.5zM407.9 75.3h-.1v101.6c1.4 0 2.7-.2 4.1-.2 4.3 0 8.5.2 12.7.6V75.9c-5.6-.4-11.1-.6-16.7-.6zM668.8 310.2H562.6c.7 5.5 1.1 11.1 1.1 16.8H670c-.2-5.6-.6-11.2-1.2-16.8z"
        />
    </svg>
);

const ForwardRef = forwardRef(SvgTenPiktoSolarenergie800X80023032221);
const Memo = memo(ForwardRef);
export default Memo;
