import { TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { Address } from '../../types';

const requiredErrorMessage = 'Dies ist ein Pflichtfeld.';
const validationErrorMessage = 'Bitte korrigieren Sie Ihre Eingabe.';

type FormProps = {
    parentObjectReference: string;
    disabled?: boolean;
};

const AddressFields = ({ parentObjectReference, disabled = false }: FormProps): React.ReactElement => {
    const {
        register,
        formState: { errors },
    } = useFormContext<Address>();

    const flattenedErrors = {
        ...parentObjectReference.split('.').reduce((o, x) => o?.[x], errors as FieldErrors<Address>),
    };
    return (
        <>
            <div className="grid grid-cols-3 space-x-2 h-20 ">
                <div className="col-span-2">
                    <TextField
                        name="street"
                        label="Straße"
                        displayErrorMessage={flattenedErrors?.street?.message}
                        disabled={disabled}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...register(`${parentObjectReference}.street`, {
                            required: requiredErrorMessage,
                            minLength: { value: 2, message: validationErrorMessage },
                            maxLength: { value: 60, message: validationErrorMessage },
                        })}
                    />
                </div>
                <div className="col-span-1">
                    <TextField
                        name="houseNumber"
                        label="Hausnummer"
                        displayErrorMessage={flattenedErrors?.houseNumber?.message}
                        size={1}
                        disabled={disabled}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...register(`${parentObjectReference}.houseNumber`, {
                            required: requiredErrorMessage,
                            minLength: { value: 1, message: validationErrorMessage },
                            pattern: { value: /^[1-9]\d*/, message: validationErrorMessage },
                        })}
                    />
                </div>
            </div>
            <div className="grid grid-cols-3 space-x-2 h-20">
                <div className="col-span-1">
                    <TextField
                        name="postalCode"
                        label="Postleitzahl"
                        displayErrorMessage={flattenedErrors?.postalCode?.message}
                        size={1}
                        disabled={disabled}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...register(`${parentObjectReference}.postalCode`, {
                            required: requiredErrorMessage,
                            minLength: { value: 5, message: validationErrorMessage },
                            maxLength: { value: 5, message: validationErrorMessage },
                            pattern: { value: /^\d+$/, message: validationErrorMessage },
                        })}
                    />
                </div>
                <div className="col-span-2">
                    <TextField
                        name="city"
                        label="Ort"
                        displayErrorMessage={flattenedErrors?.city?.message}
                        disabled={disabled}
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        {...register(`${parentObjectReference}.city`, {
                            required: requiredErrorMessage,
                            minLength: { value: 2, message: validationErrorMessage },
                            maxLength: { value: 30, message: validationErrorMessage },
                        })}
                    />
                </div>
            </div>
            <div className="h-20 ">
                <span className="text-small mb-1">Land</span>
                <div
                    className="flex items-center border border-dark-grey min-w-full
                                px-2 py-1 h-10 bg-light-grey hover:cursor-not-allowed bg-opacity-25"
                >
                    <span className="text-darkest-grey">Deutschland</span>
                </div>
            </div>
        </>
    );
};

export default AddressFields;
