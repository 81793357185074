import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as singleSpa from 'single-spa';

import { LOCATION_DATA, SERVICE_SELECTION } from '../routes';
import { RouteProperty } from '../types';
import LocationDataForm from './location-data-form';
import ServiceSelectionForm from './service-selection-form';

export default ({ onSubmit, goBack, updateContext, context }: RouteProperty): React.ReactElement => (
    <Switch>
        <Route path={LOCATION_DATA}>
            <LocationDataForm
                onSubmit={onSubmit}
                goBack={() => singleSpa.navigateToUrl('/netzanschluss/antragsstrecke/startseite')}
                updateContext={updateContext}
                context={context}
            />
        </Route>

        <Route path={SERVICE_SELECTION}>
            <ServiceSelectionForm onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={context} />
        </Route>
    </Switch>
);
