import * as turf from '@turf/turf';
import { Position } from 'geojson';
import L from 'leaflet';
import React from 'react';

export const calculatePixelHeightInMeters = (map: L.Map) => {
    const southEast = map.getBounds().getSouthEast();
    const northEast = map.getBounds().getNorthEast();
    const mapHeightMeters = southEast.distanceTo(northEast);
    const mapHeightPixels = map.getSize().y;
    return mapHeightMeters / mapHeightPixels;
};

export const calculatePixelWidthInMeters = (map: L.Map) => {
    const southEast = map.getBounds().getSouthEast();
    const southWest = map.getBounds().getSouthWest();
    const mapWidthMeters = southEast.distanceTo(southWest);
    const mapWidthPixels = map.getSize().x;
    return mapWidthMeters / mapWidthPixels;
};

export const calculateLineAngleRelativeToNorth = (a: L.LatLng, b: L.LatLng) => {
    const angle = (turf.bearing([a.lng, a.lat], [b.lng, b.lat]) + 270) % 360;
    return angle > 90 && angle < 270 ? angle - 180 : angle;
};

export const distanceBetweenTwoCoordinatesInMeters = (a: L.LatLng, b: L.LatLng): number => {
    const pointA = turf.point([a.lng, a.lat]);
    const pointB = turf.point([b.lng, b.lat]);
    return turf.distance(pointA, pointB, { units: 'meters' });
};

export const setLengthLabelPositionAndAngle = (
    ref: React.MutableRefObject<HTMLDivElement>,
    x: number,
    y: number,
    angle: number,
) => {
    if (ref.current) {
        const current = ref.current as HTMLElement;
        L.DomUtil.setPosition(ref.current, L.point(x - current.offsetWidth / 2, y - current.offsetHeight / 2));
        current.style.transform += ` rotate(${angle}deg)`;
    }
};

export const calculatePolylineLengthInMeters = (polyline: L.Polyline) => {
    const lineStringPoints = (polyline.getLatLngs() as L.LatLng[]).map(
        (latlng) => [latlng.lng, latlng.lat] as Position,
    );
    const lineString = turf.lineString(lineStringPoints);
    return turf.length(lineString) * 1000;
};
