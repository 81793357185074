import { StyledLink } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { ElectricityAndGasAvailability } from '../types';

const PortalNotResponsibleText = ({
    availability,
}: {
    availability: ElectricityAndGasAvailability;
}): React.ReactElement => {
    const getBranchTextBasedOnResponsibility = () => {
        if (
            availability.electricity.responsible === 'OperatingSupply' &&
            availability.gas.responsible === 'OperatingSupply'
        ) {
            return 'Strom und Erdgas';
        }
        if (availability.electricity.responsible === 'OperatingSupply') {
            return 'Strom';
        }
        return 'Erdgas';
    };

    return (
        <>
            <p>Es tut uns leid, an Ihrem Standort können wir die Beantragung über dieses Portal nicht anbieten.</p>
            <p>
                Ihren Netzanschluss für {getBranchTextBasedOnResponsibility()} können Sie außerhalb des Portals
                beantragen.
            </p>
            <p>
                Wenden Sie sich dazu bitte an unsere regionalen Ansprechpartner unter <br />
                <StyledLink href="mailto:netzanschluesse@thueringer-energienetze.com" target="_blank">
                    netzanschluesse@thueringer-energienetze.com
                </StyledLink>
                .
            </p>
        </>
    );
};

export default PortalNotResponsibleText;
