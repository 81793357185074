import { Gas, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { TwoButton, createPrevNextButton } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import { GasConsumptionUnknownData, UpdateContextFunction } from '../../../types';
import { Context } from '../../../wizard';
import GasAdditionalDevice from './GasAdditionalDevices';
import calculateTotalConsumption, { shouldCalculateTotalConsumption } from './GasConsumptionCalculation';
import GasConsumptionUnknownHeating from './GasConsumptionUnknownHeating';
import GasConsumptionUnknownOutlet from './GasConsumptionUnknownOutlet';
import GasDevice from './GasDevices';

interface GasConsumptionUnknownProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
}

function hasElement(container: unknown[]) {
    return Array.isArray(container) && container.length > 0;
}

function getDefaultValues(context: Partial<Context>) {
    return {
        gasHeating: context.gasHeating,
        gasOutlet: context.gasOutlet,
        gasOutlets: context.gasOutlets,
        gasConsumptionInKw: context.gasConsumptionInKw || 30,
        gasHeatings: hasElement(context.gasHeatings) ? context.gasHeatings : [{}],
        gasAdditionalDevices: hasElement(context.gasAdditionalDevices) ? context.gasAdditionalDevices : [{}],
        gasDevices: hasElement(context.gasDevices) ? context.gasDevices : [{}],
    };
}

export default ({ onSubmit, goBack, context, updateContext }: GasConsumptionUnknownProps): React.ReactElement => {
    const reactHookForm = useForm<GasConsumptionUnknownData>({
        mode: 'all',
        shouldUnregister: true,
        defaultValues: getDefaultValues(context),
    });

    const {
        handleSubmit,
        getValues,
        setValue,
        formState: { isValid, touchedFields, errors },
    } = reactHookForm;

    const syncContext = React.useCallback(
        (setGasConsumptionInKwManual?: number) => {
            const [gasHeating, gasHeatings, gasOutlet, gasOutlets, gasDevices, gasAdditionalDevices] = getValues([
                'gasHeating',
                'gasHeatings',
                'gasOutlet',
                'gasOutlets',
                'gasDevices',
                'gasAdditionalDevices',
            ]);
            const newContext: Partial<Context> = {
                gasHeating,
                gasOutlet,
                gasOutlets: gasOutlet === 'yes' ? gasOutlets : undefined,
                gasHeatings: gasHeating === 'yes' ? gasHeatings : [],
                gasDevices,
                gasAdditionalDevices,
            };

            if (typeof setGasConsumptionInKwManual === 'number') {
                newContext.gasConsumptionInKw = setGasConsumptionInKwManual;
            } else if (shouldCalculateTotalConsumption(newContext)) {
                newContext.gasConsumptionInKw = calculateTotalConsumption(newContext);
            } else {
                newContext.gasConsumptionInKw = 30;
            }

            setValue('gasConsumptionInKw', newContext.gasConsumptionInKw);

            updateContext(newContext, 'CONSUMPTION_GAS_CONSUMPTION');
        },
        [getValues, updateContext, setValue],
    );

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: () => {
            if (!context.gasConsumptionInKw) syncContext();
            handleSubmit(onSubmit)();
        },
        isNextValid: isValid,
    });

    return (
        <FormProvider {...reactHookForm}>
            <GasConsumptionUnknownHeating context={context} syncContext={syncContext} />
            <GasConsumptionUnknownOutlet context={context} syncContext={syncContext} />
            <FormLayout>
                <GasDevice reactHookForm={reactHookForm} userType={context.userType} syncContext={syncContext} />
                <GasAdditionalDevice
                    reactHookForm={reactHookForm}
                    userType={context.userType}
                    syncContext={syncContext}
                />
            </FormLayout>
            <div className="flex flex-row justify-center shadow-ten" style={{ height: '128px', width: '1195px' }}>
                <div className="w-full h-full self-center grid auto-rows-auto p-6 gap-y-2">
                    <div className="flex">
                        <div
                            className="w-6 self-center text-primary fill-current"
                            style={{ marginRight: '5px', marginLeft: '-2px', marginTop: '-5px' }}
                        >
                            <Gas />
                        </div>
                        <div className="self-center font-bold">Leistungsbedarf Erdgas gesamt</div>
                    </div>
                    <div
                        className={`grid grid-flow-row md:grid-flow-col auto-cols-auto gap-x-0 gap-y-4 
                    md:gap-y-0 md:gap-x-8`}
                    >
                        <div className="self-center">
                            Basierend auf Ihren Angaben kalkulieren wir eine benötigte Erdgasleistung von circa:
                            <br />
                            <span className="text-small">
                                <b>Hinweis:</b> Wenn Sie aufgrund Ihres aktuellen Planungsstands keine Angaben machen
                                konnten, kalkulieren wir mit unserer Standardleistung von 30 kW.
                            </span>
                        </div>
                        <div className="mx-0 md:mx-6 justify-self-start md:justify-self-end">
                            <NumberInput
                                disabled={!isValid && !errors.gasConsumptionInKw}
                                name="gas-consumption-total"
                                {...NumberInputFunctions.registerNumberField(reactHookForm, 'gasConsumptionInKw', {
                                    required: true,
                                    shouldUnregister: true,
                                    min: 1,
                                    max: 9999,
                                    onChange: () => {
                                        const consumption = getValues('gasConsumptionInKw');
                                        if (typeof consumption === 'number') syncContext(consumption);
                                    },
                                })}
                                displayErrorMessage={
                                    touchedFields.gasConsumptionInKw &&
                                    errors.gasConsumptionInKw &&
                                    'Bitte geben Sie eine Zahl größer als 0 ein.'
                                }
                            />
                            <span className="ml-2">kW</span>
                        </div>
                    </div>
                </div>
            </div>
            <TwoButton leftButton={prevButton} rightButton={nextButton} />
        </FormProvider>
    );
};
