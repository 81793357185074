import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CONTACT_ADDRESS, INVOICE_ADDRESS } from '../routes';
import ContactAddressForm from './contact-address-form';
import InvoiceAddressContainer from './invoice-address-form/InvoiceAddressContainer';

export default ({ onSubmit, goBack, updateContext, context }: Switch): React.ReactElement => (
    <Switch>
        <Route path={CONTACT_ADDRESS}>
            <ContactAddressForm onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={context} />
        </Route>
        <Route path={INVOICE_ADDRESS}>
            <InvoiceAddressContainer
                onSubmit={onSubmit}
                goBack={goBack}
                updateContext={updateContext}
                context={context}
            />
        </Route>
    </Switch>
);
