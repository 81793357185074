import { assign } from 'xstate';

import { Meta } from '../types';
import { mergeDeep } from '../util';
import { CONSUMPTION_PROCESS_INDEX, SUBPROCESS } from './constants';
import { Context } from './context';
import { UpdateContextEvent, UpdatePathEvent, UpdateWizardStepsEvent, UserEvent } from './events';
import {
    isAutomaticElectricityOfferPageShown,
    isAutomaticGasOfferPageShown,
    isGasServiceSelected,
    isPowerConsumptionTotalPageShown,
    isPowerServiceSelected,
} from './guards';
import { changeStepVisibility, getProcessIndex, isNewFurthestStep } from './util';

export default {
    UPDATE_CONTEXT: assign<Context, UserEvent>((context: Context, event: UpdateContextEvent) =>
        mergeDeep(context, event.value),
    ),

    UPDATE_FURTHEST_STEPS: assign<Context, UserEvent>((context: Context, _, { action }) => {
        const { process, subprocess } = action as unknown as UpdateWizardStepsEvent;

        const positionOfFurthestProcess = getProcessIndex(context.meta.furthestStep?.furthestProcess);
        const positionOfCurrentProcess = getProcessIndex(process);

        const newFurthestProcessIndex = Math.max(positionOfFurthestProcess, positionOfCurrentProcess);

        // Visibilities of the steps should only be updated once the user has reached the consumption step
        if (newFurthestProcessIndex >= CONSUMPTION_PROCESS_INDEX) {
            changeStepVisibility(
                context,
                SUBPROCESS.CONSUMPTION_POWER_CONSUMPTION_DECLARATION,
                isPowerServiceSelected(context),
            );
            changeStepVisibility(
                context,
                SUBPROCESS.CONSUMPTION_POWER_CONSUMPTION_TOTAL,
                isPowerConsumptionTotalPageShown(context),
            );

            changeStepVisibility(context, SUBPROCESS.CONSUMPTION_GAS_CONSUMPTION, isGasServiceSelected(context));

            // Conditionally show pages for automatic offer
            changeStepVisibility(context, SUBPROCESS.OFFER_POWER, isAutomaticElectricityOfferPageShown(context));
            changeStepVisibility(context, SUBPROCESS.OFFER_GAS, isAutomaticGasOfferPageShown(context));
        }

        if (isNewFurthestStep(context, process, subprocess)) {
            return {
                ...(context as Partial<Context>),
                ...{
                    meta: {
                        ...context.meta,
                        ...{
                            furthestStep: {
                                furthestProcess: process,
                                furthestSubprocess: subprocess,
                            },
                        },
                    },
                },
            };
        }

        return context;
    }),

    UPDATE_PATH: assign<Context, UserEvent>((context: Context, _, { action }) => {
        const { path } = action as unknown as UpdatePathEvent;
        return {
            ...(context as Partial<Context>),
            ...({
                meta: {
                    ...context.meta,
                    path,
                },
            } as Partial<Meta>),
        };
    }),

    SYNC_LOCATION_WITH_STATE: (context: Context): void => {
        context.meta.history.push(context.meta.path);
    },
};
