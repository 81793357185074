import { CompanyValues, FormValues, PrivateCustomerValues } from '@ten-netzkundenportal/ui-registration';
import axios, { AxiosResponse } from 'axios';

import appConfig from '../../../app.config';
import { UserType } from '../../../types';

export default async (
    customerType: UserType,
    enteredValues: FormValues,
    connectionRequestId: string,
): Promise<number> => {
    let accountRequestData;
    const partialAccountRequest = {
        email: enteredValues.email,
        emailRepeated: enteredValues.email,
        password: enteredValues.password,
        customerType,
    };
    if (customerType === 'private') {
        const { firstName, lastName } = enteredValues as PrivateCustomerValues;
        accountRequestData = {
            ...partialAccountRequest,
            firstName,
            lastName,
        };
    } else if (customerType === 'company' || customerType === 'projectpartner') {
        const { companyName } = enteredValues as CompanyValues;
        accountRequestData = {
            ...partialAccountRequest,
            name: companyName,
        };
    } else {
        throw new Error(`Unknown customer type ${customerType}`);
    }
    try {
        const response: AxiosResponse = await axios.post(
            `${appConfig.services.connectionRequestFormApi}/connection-request/${connectionRequestId}/account`,
            accountRequestData,
        );
        return response?.status;
    } catch (error) {
        return error.response?.status;
    }
};
