import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import L from 'leaflet';

export const isAddress = (location: AddressLocation | CadastralDistrictLocation): location is AddressLocation =>
    (location as AddressLocation).cityName !== undefined;

const getCadastralDistrictLatLngArrayFromContextLocation = (
    contextLocation: AddressLocation | CadastralDistrictLocation,
) => {
    const polygonCadastralDistricts = !isAddress(contextLocation)
        ? (contextLocation as CadastralDistrictLocation).geojson.coordinates
        : (contextLocation as AddressLocation).cadastralDistrictCoordinates.coordinates;
    return polygonCadastralDistricts.map((coordinateArray) =>
        coordinateArray.map((coordinates) => L.latLng(coordinates[1], coordinates[0])),
    )[0];
};
export default getCadastralDistrictLatLngArrayFromContextLocation;
