import {
    Dropdown,
    Form,
    FormItem,
    TeagPiktoMehrfamilienhaus800X800210729 as MultiFamilyHouse,
    NumberInput,
    NumberInputFunctions,
    RadioButton,
    RadioIcon,
    TeagEnergieloesungen as SingleFamily,
    TeagPiktoTeilweisegenutzt800X800210730 as Special,
    TextField,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { createPrevNextButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { Building, BuildingDataProperties, UpdateContextFunction, UserTypeProperties } from '../../types';
import { useContextUpdate, useDynamicDefault } from '../../wizard';
import specialBuildingOptions from './options';

export interface BuildingDataFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<BuildingDataProperties & UserTypeProperties>;
}

export default function BuildingDataForm({
    onSubmit,
    goBack,
    updateContext,
    context,
}: BuildingDataFormProps): React.ReactElement {
    const reactHookForm = useForm<Building>({
        mode: 'all',
        shouldUnregister: true,
        defaultValues: {
            buildingType: context.building.buildingType,
            newBuilding: context.building.newBuilding,
        },
    });
    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid, errors },
    } = reactHookForm;

    const buildingType = useWatch({ control, name: 'buildingType' });
    const newBuilding = useWatch({ control, name: 'newBuilding' });

    useDynamicDefault(setValue, buildingType !== 'special', 'basement', context.building.basement ?? 'yes');
    useDynamicDefault(setValue, buildingType === 'special', 'specialType', context.building.specialType ?? null);
    useDynamicDefault(setValue, buildingType === 'special', 'basement', undefined);
    useDynamicDefault(
        setValue,
        buildingType === 'multiFamily',
        'residentialUnit',
        context.building.residentialUnit ?? 1,
    );
    useDynamicDefault(setValue, buildingType === 'multiFamily', 'commercialUnit', context.building.commercialUnit ?? 0);
    useDynamicDefault(
        setValue,
        buildingType === 'multiFamily',
        'communityFacilities',
        context.building.communityFacilities || 0,
    );
    useDynamicDefault(setValue, newBuilding === 'yes', 'newBuildingName', context.building.newBuildingName ?? '');

    useContextUpdate(watch, updateContext, 'CONSUMPTION_BUILDING_DATA', { contextPrefix: 'building' });

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
        size: 'md',
    });

    return (
        <Form title="Gebäude" handleSubmit={handleSubmit(onSubmit)} leftButton={prevButton} rightButton={nextButton}>
            <FormLayout>
                <FormItem
                    label={`Um welche Gebäudeart handelt es sich bei ${
                        context.userType === 'installer' || context.userType === 'projectpartner' ? 'dem' : 'Ihrem'
                    } Anschlussobjekt?`}
                    className="items-center gap-x-10 flex [&>*]:w-full"
                >
                    <div className="h-24">
                        <RadioIcon
                            checked={buildingType === 'singleFamily'}
                            id="singleFamily"
                            name="buildingType"
                            iconSize="w-9"
                            icon={<SingleFamily width="3rem" className="pb-1" />}
                            value="singleFamily"
                            {...register('buildingType')}
                        >
                            Einfamilienhaus
                        </RadioIcon>
                    </div>
                    <div className="h-24">
                        <RadioIcon
                            checked={buildingType === 'multiFamily'}
                            id="multiFamily"
                            name="buildingType"
                            iconSize="w-12"
                            icon={<MultiFamilyHouse width="3rem" />}
                            value="multiFamily"
                            {...register('buildingType')}
                        >
                            Mehrfamilienhaus
                        </RadioIcon>
                    </div>
                    <div className="h-24">
                        <RadioIcon
                            checked={buildingType === 'special'}
                            id="special"
                            name="buildingType"
                            iconSize="w-12"
                            icon={<Special width="3rem" />}
                            value="special"
                            {...register('buildingType')}
                        >
                            Sondergebäude
                        </RadioIcon>
                    </div>
                </FormItem>

                {buildingType === 'special' && (
                    <FormItem
                        label="Um welche Art von Sondergebäude handelt es sich?"
                        className="items-center gap-x-20 grid grid-cols-[1fr_2fr]"
                    >
                        <div className="w-full">
                            <Dropdown
                                name="specialType"
                                options={specialBuildingOptions}
                                dropdownClassname="w-full"
                                {...register('specialType', { required: true, shouldUnregister: true })}
                            />
                        </div>
                    </FormItem>
                )}
                {buildingType === 'multiFamily' && (
                    <>
                        <FormItem
                            label="Wie viele Wohneinheiten sollen versorgt werden?"
                            className="items-center gap-x-10 grid grid-cols-[1fr_2fr]"
                        >
                            <div className="mr-5">
                                <NumberInput
                                    name="residentialUnit"
                                    {...NumberInputFunctions.registerNumberInput(reactHookForm, 'residentialUnit', {
                                        required: true,
                                        shouldUnregister: true,
                                        min: 1,
                                        max: 999,
                                    })}
                                    displayErrorMessage={
                                        errors && errors.residentialUnit
                                            ? 'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            : undefined
                                    }
                                />
                            </div>

                            <div className="grid grid-flow-col items-center">
                                <span className="mb-0">Wie viele Gewerbeeinheiten sollen versorgt werden?</span>
                                <NumberInput
                                    fluidWidth
                                    name="commercialUnit"
                                    {...NumberInputFunctions.registerNumberInput(reactHookForm, 'commercialUnit', {
                                        required: true,
                                        shouldUnregister: true,
                                        min: 0,
                                        max: 999,
                                    })}
                                    displayErrorMessage={
                                        errors && errors.commercialUnit
                                            ? 'Bitte geben Sie eine Zahl zwischen 0 und 999 ein.'
                                            : undefined
                                    }
                                />
                            </div>
                        </FormItem>
                        <FormItem
                            label="Wie viele Gemeinschaftsanlagen (z.B. Flur) sollen versorgt werden?"
                            className="items-center gap-x-10 grid-cols-[1fr_2fr]"
                        >
                            <div className="mr-5">
                                <NumberInput
                                    name="communityFacilities"
                                    {...NumberInputFunctions.registerNumberInput(reactHookForm, 'communityFacilities', {
                                        required: true,
                                        shouldUnregister: true,
                                        min: 0,
                                        max: 999,
                                    })}
                                    displayErrorMessage={
                                        errors && errors.communityFacilities
                                            ? 'Bitte geben Sie eine Zahl zwischen 0 und 999 ein.'
                                            : undefined
                                    }
                                />
                            </div>
                        </FormItem>
                    </>
                )}
                <FormItem
                    label={`Ist ${
                        context.userType === 'installer' || context.userType === 'projectpartner' ? 'das' : 'Ihr'
                    } Gebäude ein Neubau?`}
                    className="items-center gap-x-5 grid grid-cols-[0.5fr_0.5fr_2fr]"
                >
                    <RadioButton id="newBuilding-yes" name="newBuilding" value="yes" {...register('newBuilding')}>
                        ja
                    </RadioButton>
                    <RadioButton id="newBuilding-no" name="newBuilding" value="no" {...register('newBuilding')}>
                        nein
                    </RadioButton>
                    {newBuilding === 'yes' && (
                        <div className="grid grid-flow-row lg:grid-flow-col items-center my-0 lg:-my-2">
                            <span>
                                Wie ist der Name des Neubaugebiets?{' '}
                                <span className="text-darkest-grey">(optional)</span>
                            </span>
                            <TextField
                                type="text"
                                label={undefined}
                                name="newBuildingName"
                                {...register('newBuildingName', { shouldUnregister: true, maxLength: 40 })}
                                displayErrorMessage={errors?.newBuildingName && 'Bitte max. 40 Zeichen eingeben.'}
                            />
                        </div>
                    )}
                </FormItem>
                {buildingType !== 'special' && (
                    <FormItem
                        label={`Hat ${
                            context.userType === 'installer' || context.userType === 'projectpartner' ? 'das' : 'Ihr'
                        } Gebäude einen Keller?`}
                        className="items-center gap-x-5 grid grid-cols-[0.5fr_0.5fr_2fr]"
                    >
                        <RadioButton
                            id="basement-yes"
                            name="basement"
                            value="yes"
                            {...register('basement', { shouldUnregister: true })}
                        >
                            ja
                        </RadioButton>
                        <RadioButton
                            id="basement-no"
                            name="basement"
                            value="no"
                            {...register('basement', { shouldUnregister: true })}
                        >
                            nein
                        </RadioButton>
                    </FormItem>
                )}
            </FormLayout>
        </Form>
    );
}
