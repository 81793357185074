import L, { LeafletEvent } from 'leaflet';

import { HouseShape, HouseShapeType } from '../../../types';
import { MarkerToolType } from '../../../util/drawing';

export const shapeListener =
    (
        id: number,
        shape: HouseShapeType,
        updateShape: (shapeId: number, houseShape: HouseShape) => void,
        deleteShape: (shapeId: number) => void,
    ) =>
    (event: LeafletEvent): void => {
        const { type, layer } = event;
        if (type === 'pm:remove') {
            deleteShape(id);
        } else {
            const houseShape: HouseShape = {
                shapeType: shape as 'Rectangle' | 'Polygon',
                // getLatLngs returns a multi polygon (array) with one polygon (array)
                latLngs: (layer as L.Polygon).getLatLngs()[0] as L.LatLng[],
                angle: layer.pm.getAngle(),
            };
            updateShape(id, houseShape);
        }
    };

export const markerListener =
    (
        mT: MarkerToolType,
        marker: L.Marker,
        updateMarker: (functionMarkerType: MarkerToolType, m: L.Marker) => void,
        deleteMarker: (functionMarkerType: MarkerToolType, disableRemoveButton?: boolean) => void,
    ) =>
    (event: LeafletEvent): void => {
        if (event.type === 'pm:remove') {
            deleteMarker(mT, true);
        } else {
            updateMarker(mT, marker);
        }
    };
