import { Gas, GasDeviceType, GasHeaterType } from '../../types';
import { Context } from '../../wizard';

export const transformContextToGas = (context: Partial<Context>): Gas => {
    if (!context.gas) {
        return undefined;
    }
    return {
        predefinedConsumption: context.gasConsumptionInKw,
        heater:
            !context.gasConsumptionKnown &&
            context.gasHeatings?.filter((gasHeating) => gasHeating.type && gasHeating.amount && gasHeating.capacityInKW)
                .length > 0
                ? context.gasHeatings
                      ?.filter((gasHeating) => gasHeating.type && gasHeating.amount && gasHeating.capacityInKW)
                      .map((gasHeating) => ({
                          type: gasHeating.type as GasHeaterType,
                          amount: gasHeating.amount,
                          capacity: gasHeating.capacityInKW,
                      }))
                : undefined,
        devices:
            !context.gasConsumptionKnown &&
            context.gasDevices?.filter((gasDevice) => gasDevice && gasDevice.amount && gasDevice.capacityInKW).length >
                0
                ? context.gasDevices
                      ?.filter((gasDevice) => gasDevice && gasDevice.amount && gasDevice.capacityInKW)
                      .map((gasDevice) => ({
                          type: gasDevice.type as GasDeviceType,
                          amount: gasDevice.amount,
                          capacity: gasDevice.capacityInKW,
                      }))
                : undefined,
        outlets:
            !context.gasConsumptionKnown && context.gasOutlets?.amount ? Number(context.gasOutlets?.amount) : undefined,
        additionalDevices:
            !context.gasConsumptionKnown &&
            context.gasAdditionalDevices?.filter(
                (additionalDevice) => additionalDevice && additionalDevice.amount && additionalDevice.capacityInKW,
            ).length > 0
                ? context.gasAdditionalDevices
                      ?.filter(
                          (additionalDevice) =>
                              additionalDevice && additionalDevice.amount && additionalDevice.capacityInKW,
                      )
                      .map((additionalDevice) => ({
                          name: additionalDevice.name ? additionalDevice.name.trim() : undefined,
                          amount: additionalDevice.amount,
                          capacity: additionalDevice.capacityInKW,
                      }))
                : undefined,
        personalContributionMultiEntry: context.personalContributionMultiEntryGas ?? false,
        personalContributionCivilEngineering: context.personalContributionCivilEngineeringGas ?? false,
    };
};
