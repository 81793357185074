import L from 'leaflet';
import * as React from 'react';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { UpdateContextFunction } from '../../types';
import { MarkerToolType } from '../../util/drawing';
import { Context } from '../../wizard';
import MarkerButtonBox from './buildingAndConnectionPlan/MarkerButtonBox';
import ShapeButtonBox, { ShapeToolType } from './buildingAndConnectionPlan/ShapeButtonBox';
import controlLayerEditing from './buildingAndConnectionPlan/controlLayerEditing';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

export type ToolboxProperties = {
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    toggleShowProcessGuide: () => void;
    currentProcessStep: number;
    setIsMovingMarker: (value: boolean) => void;
};

export const commonButtonStyle = 'button border w-12 h-12 grid place-items-center rounded-lg';
export const inactiveButtonStyle = `${commonButtonStyle} border-grey hover:border-primary hover:border-2 shadow-md`;
export const activeButtonStyle = `${commonButtonStyle} border-dark-blue border-2 text-dark-blue shadow-inner`;
export const disabledButtonStyle = `${commonButtonStyle} border-grey cursor-not-allowed text-gray-400`;

export type ToolType = ShapeToolType | MarkerToolType | 'none';

export default ({
    updateContext,
    context,
    toggleShowProcessGuide,
    currentProcessStep,
    setIsMovingMarker,
}: ToolboxProperties): React.ReactElement => {
    const drawControlContainerRef = React.useRef(null);
    const map = useMap();
    const getCurrentTool = (): ToolType => {
        if (map.pm.globalRotateModeEnabled()) return 'Rotate';
        if (map.pm.globalRemovalModeEnabled()) return 'Remove';
        if (map.pm.globalDragModeEnabled()) return 'Drag';
        if (map.pm.globalEditModeEnabled()) return 'Edit';
        return 'none';
    };
    const [currentTool, setCurrentTool] = React.useState<ToolType>(getCurrentTool());

    useEffect(() => {
        L.DomEvent.disableClickPropagation(drawControlContainerRef.current);
        if (currentTool === 'none') {
            map.dragging.enable();
        } else {
            map.dragging.disable();
        }
        map.on('pm:drawend', () => {
            setCurrentTool('none');
        });

        map.pm.setGlobalOptions({
            // default but required
            panes: { vertexPane: 'markerPane', layerPane: 'overlayPane', markerPane: 'markerPane' },
            // default but required
            snappingOrder: ['Marker', 'CircleMarker', 'Circle', 'Line', 'Polygon', 'Rectangle'],
            snappable: false,
            tooltips: false,
        });
    });

    React.useEffect(() => {
        controlLayerEditing(map, L.PM.Utils.findLayers(map), currentProcessStep, setCurrentTool);
    }, [map, currentProcessStep, context.powerMarker, context.gasMarker, context.multiEntryMarker]);

    return (
        <div className="absolute left-4 top-4">
            <div ref={drawControlContainerRef} className="leaflet-control leaflet-pm-toolbar">
                <div className="bg-primary border-4 rounded-t-xl border-primary flex place-content-center">
                    <button
                        type="button"
                        className="text-white text-base align-self-center"
                        onClick={toggleShowProcessGuide}
                    >
                        Schritt {currentProcessStep + (currentProcessStep === 3 ? 0 : 1)}
                    </button>
                </div>
                <div className="bg-white border-b-4 rounded-b-xl border-white flex flex-col place-items-center pb-3">
                    <div className="font-bold py-2">Werkzeuge</div>
                    <ShapeButtonBox
                        currentTool={currentTool}
                        setCurrentTool={setCurrentTool}
                        updateContext={updateContext}
                        currentProcessStep={currentProcessStep}
                        context={context}
                    />

                    <div className="font-bold py-2">Anschlüsse</div>
                    <MarkerButtonBox
                        currentTool={currentTool}
                        setCurrentTool={setCurrentTool}
                        context={context}
                        updateContext={updateContext}
                        currentProcessStep={currentProcessStep}
                        setIsMovingMarker={setIsMovingMarker}
                    />
                </div>
            </div>
        </div>
    );
};
