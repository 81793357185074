import { Button, Form } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import { DASHBOARD } from '../routes';
import { Context } from '../wizard';

type NextStepsProps = {
    context: Partial<Context>;
};

const constructProcessIdTextPhrase = ({ meta }: Partial<Context>): React.ReactNode => {
    if (meta.electricityOffer && meta.gasOffer) {
        const electricityOfferId = meta.electricityOffer.processCommunicationId;
        const gasOfferId = meta.gasOffer.processCommunicationId;
        return (
            <>
                den <strong>Vorgangsnummern {electricityOfferId}</strong> und <strong>{gasOfferId}</strong>
            </>
        );
    }
    if (meta.electricityOffer) {
        return (
            <>
                der <strong>Vorgangsnummer {meta.electricityOffer.processCommunicationId}</strong>
            </>
        );
    }
    if (meta.gasOffer) {
        return (
            <>
                der <strong>Vorgangsnummer {meta.gasOffer.processCommunicationId}</strong>
            </>
        );
    }
    throw new Error('Invalid state: no offer');
};

const singleOrMultipleProcessTextPhraseForPrivateOrCompany = ({ meta }: Partial<Context>): string => {
    if (meta.electricityOffer && meta.gasOffer) {
        return 'Ihren Vorgängen';
    }
    return 'Ihrem Vorgang';
};

const singleOrMultipleProcessTextPhraseForInstaller = ({ meta }: Partial<Context>): string => {
    if (meta.electricityOffer && meta.gasOffer) {
        return 'seinen Vorgängen';
    }
    return 'seinem Vorgang';
};

const constructTextPhrasesForInstallerOrProjectpartner = (context: Partial<Context>): React.ReactNode => (
    <>
        In unserem Netzkundenportal kann Ihr Kunde ab jetzt seinen Antrag mit {constructProcessIdTextPhrase(context)}{' '}
        jederzeit einsehen. Zudem findet er dort weitere Informationen zu{' '}
        {singleOrMultipleProcessTextPhraseForInstaller(context)} und kann auch die nächsten Schritte mit verfolgen.
        Dafür muss er sich zunächst in unserem Netzkundenportal registrieren. Ihr Kunde erhält eine Benachrichtung per
        E-Mail mit einer Registrierungsaufforderung im Netzkundenportal.
    </>
);

const constructTextPhrasesForPrivateOrCompany = (context: Partial<Context>): React.ReactNode => (
    <>
        In unserem Netzkundenportal können Sie ab jetzt Ihren Antrag mit {constructProcessIdTextPhrase(context)}{' '}
        jederzeit einsehen. Zudem finden Sie dort weitere Informationen zu{' '}
        {singleOrMultipleProcessTextPhraseForPrivateOrCompany(context)} und können auch die nächsten Schritte mit
        verfolgen.
    </>
);

const constructFurtherInformationTextPhrase = (context: Partial<Context>): React.ReactNode => {
    switch (context.userType) {
        case 'installer':
        case 'projectpartner':
            return constructTextPhrasesForInstallerOrProjectpartner(context);
        case 'company':
        case 'private':
            return constructTextPhrasesForPrivateOrCompany(context);
        default:
            throw Error('Invalid state: userType should be installer, company, or private');
    }
};

export default ({ context }: NextStepsProps): React.ReactElement => (
    <Form
        title="Nächste Schritte"
        rightButton={
            <Button
                label="Zur Übersicht"
                onClick={() => {
                    localStorage.removeItem('antragsstrecke');
                    window.history.pushState({}, document.title, DASHBOARD);
                }}
            />
        }
    >
        <div className="flex flex-col space-y-4">
            <span>Geschafft – vielen Dank, dass Sie Ihren Antrag für einen Netzanschluss abgeschlossen haben!</span>
            <span>{constructFurtherInformationTextPhrase(context)}</span>
            <span>
                Haben Sie noch Fragen an uns? Dann melden Sie sich gern bei unseren zuständigen{' '}
                <a
                    href="https://www.thueringer-energienetze.com/Ueber_uns/Profil/Ansprechpartner"
                    className="text-primary"
                    target="_blank"
                    rel="noreferrer"
                >
                    Ansprechpartnern
                </a>
                .
            </span>
        </div>
    </Form>
);
