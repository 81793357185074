import * as React from 'react';
import { UseFormWatch } from 'react-hook-form';

import { UpdateContextFunction } from '../types';
import { SUBPROCESS_VALUE } from './constants';

export type UseContextUpdateOptions = {
    contextPrefix?: string;
    disabled?: boolean;
};

export const useContextUpdate = (
    watch: UseFormWatch<any>,
    updateContext: UpdateContextFunction,
    process: SUBPROCESS_VALUE,
    opts: UseContextUpdateOptions = {},
): void => {
    const { contextPrefix = '', disabled = false } = opts;
    React.useEffect(() => {
        if (disabled) {
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            return () => {};
        }

        const subscription = watch((values, { type }) => {
            const processParam = type === 'change' ? process : undefined;

            const filteredValues = Object.fromEntries(
                Object.entries(values).filter(([, value]) => value !== undefined),
            );
            if (contextPrefix) {
                updateContext({ [contextPrefix]: { ...filteredValues } }, processParam);
            } else {
                updateContext({ ...filteredValues }, processParam);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, updateContext, contextPrefix, disabled, process]);
};

export const useDynamicDefault = (
    setValue: (name: string, value: unknown) => void,
    isMounted: boolean,
    valueName: string,
    value: unknown,
): void => {
    React.useEffect(
        () => {
            if (isMounted) {
                setValue(valueName, value);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isMounted],
    );
};
