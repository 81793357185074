import { Button, Form, FormItem, LoadingSpinnerButton, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import FormLayout from '../../components/SignupForm/FormLayout';
import AddressModule from '../../components/form-modules/AddressModule';
import CompanyNameField from '../../components/form-modules/CompanyNameModule';
import PersonModule from '../../components/form-modules/PersonModule';
import { BusinessData, CompanyData, ContactData, PrivateData, UpdateContextFunction } from '../../types';
import { Context, useContextUpdate } from '../../wizard';
import EmailAddressArea from './EmailAddressArea';
import TelephoneNumberArea from './TelephoneNumberArea';

export interface FormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
}

const ContactAddressForm = ({ onSubmit, goBack, updateContext, context }: FormProps): React.ReactElement => {
    const { userType } = context;

    const reactHookForm = useForm<ContactData>({
        mode: 'onTouched',
        defaultValues: {
            privateData: (context as PrivateData)?.privateData,
            telephone: context.telephone,
            businessData: (context as BusinessData)?.businessData,
            companyData: (context as CompanyData)?.companyData,
        },
        shouldUnregister: true,
    });
    const {
        control,
        register,
        handleSubmit,
        watch,
        trigger,
        formState: { isValid, isValidating },
    } = reactHookForm;

    const customerType = useWatch({ control, name: 'businessData.customerType' });
    const email = useWatch({ control, name: 'businessData.email' });

    const customerDataLabel = 'Geben Sie bitte die Daten Ihres Kunden für die Angebotserstellung an:';
    React.useEffect(() => {
        if (email) {
            trigger('businessData.email');
        }
        // eslint-disable-next-line
    }, [customerType, trigger]);

    useContextUpdate(watch, updateContext, 'ADDRESSES_CONTACT_ADDRESS');

    return (
        <FormProvider {...reactHookForm}>
            <Form
                title="Kundendaten"
                handleSubmit={handleSubmit(onSubmit)}
                isPositionRelative
                leftButton={<Button label="Zurück" type="secondary" onClick={goBack} />}
                rightButton={
                    <LoadingSpinnerButton
                        label="Weiter"
                        type="primary"
                        disabled={!isValid || isValidating}
                        loading={isValidating}
                        loadingTitle="Die E-Mail-Adresse wird gerade geprüft."
                        onClick={handleSubmit(onSubmit)}
                    />
                }
            >
                <FormLayout>
                    {(userType === 'installer' || userType === 'projectpartner') && (
                        <>
                            <FormItem label="Bitte geben Sie an, ob Ihr Kunde ein Privat- oder Firmenkunde ist:">
                                <div className="flex w-72 children-w-full">
                                    <RadioButton
                                        name="customerPrivateOrCompany"
                                        id="customerPrivateOrCompany-private"
                                        value="private"
                                        checked={customerType === 'private'}
                                        {...register('businessData.customerType', {
                                            required: true,
                                            shouldUnregister: true,
                                        })}
                                    >
                                        Privatkunde
                                    </RadioButton>
                                    <RadioButton
                                        name="customerPrivateOrCompany"
                                        id="customerPrivateOrCompany-company"
                                        value="company"
                                        checked={customerType === 'company'}
                                        {...register('businessData.customerType', {
                                            required: true,
                                            shouldUnregister: true,
                                        })}
                                    >
                                        Firmenkunde
                                    </RadioButton>
                                </div>
                            </FormItem>
                            {customerType !== undefined ? (
                                <>
                                    <FormItem label={customerDataLabel}>
                                        <div className="max-w-[456px]">
                                            {customerType === 'company' ? (
                                                <CompanyNameField registerPrefix="businessData" />
                                            ) : (
                                                <PersonModule parentObjectReference="businessData.person" />
                                            )}
                                            <AddressModule parentObjectReference="businessData.address" />
                                        </div>
                                    </FormItem>
                                    <TelephoneNumberArea />
                                    <EmailAddressArea />
                                </>
                            ) : (
                                <div />
                            )}
                        </>
                    )}
                    {userType === 'private' && (
                        <FormItem label="Geben Sie bitte Ihre persönlichen Daten für die Angebotserstellung an:">
                            <div className="max-w-[456px]">
                                <PersonModule parentObjectReference="privateData.person" />
                                <AddressModule parentObjectReference="privateData.address" />
                            </div>
                        </FormItem>
                    )}
                    {userType === 'company' && (
                        <FormItem label="Geben Sie bitte Ihre persönlichen Daten für die Angebotserstellung an:">
                            <div className="max-w-[456px]">
                                <CompanyNameField registerPrefix="companyData" />
                                <AddressModule parentObjectReference="companyData.address" />
                            </div>
                        </FormItem>
                    )}
                    {userType !== 'installer' && userType !== 'projectpartner' && <TelephoneNumberArea />}
                </FormLayout>
            </Form>
        </FormProvider>
    );
};

export default ContactAddressForm;
