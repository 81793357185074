import { RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { ClosedQuestionsWrapper } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import { GasConsumptionUnknownData } from '../../../types';
import { Context } from '../../../wizard';
import GasHeating from './GasHeating';

interface GasConsumptionUnknownHeatingProps {
    context: Partial<Context>;
    syncContext: () => void;
}

const GasConsumptionUnknownHeating = ({
    context,
    syncContext,
}: GasConsumptionUnknownHeatingProps): React.ReactElement => {
    const { userType, building } = context;
    const specialOrSingleFamily = building.buildingType === 'special' || building.buildingType === 'singleFamily';

    const reactHookForm = useFormContext<GasConsumptionUnknownData>();
    const { watch, register, reset } = reactHookForm;
    const gasHeating = watch('gasHeating');

    const toggleGasHeatingAndResetFormDefaultValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        const swapTo = event.target.value;

        if (swapTo === 'no') {
            reset((values) => ({ ...values }));
        }

        syncContext();
    };

    return (
        <>
            <FormLayout>
                <ClosedQuestionsWrapper
                    label={
                        userType === 'installer' || userType === 'projectpartner'
                            ? 'Wird Ihr Kunde eine Erdgasheizung haben?'
                            : 'Werden Sie eine Erdgasheizung haben?'
                    }
                    itemsLayout="items-center"
                    gridTemplateColumns="1fr 4fr"
                >
                    <RadioButton
                        name="gasHeating"
                        id="gasHeating-yes"
                        value="yes"
                        {...register('gasHeating', {
                            shouldUnregister: true,
                            onChange: toggleGasHeatingAndResetFormDefaultValues,
                        })}
                    >
                        ja
                    </RadioButton>
                    <RadioButton
                        name="gasHeating"
                        id="gasHeating-no"
                        value="no"
                        {...register('gasHeating', {
                            shouldUnregister: true,
                            onChange: toggleGasHeatingAndResetFormDefaultValues,
                        })}
                    >
                        nein
                    </RadioButton>
                </ClosedQuestionsWrapper>
            </FormLayout>
            {gasHeating === 'yes' && (
                <GasHeating
                    specialOrSingleFamily={specialOrSingleFamily}
                    userType={context.userType}
                    syncContext={syncContext}
                />
            )}
        </>
    );
};

export default GasConsumptionUnknownHeating;
