import { Form, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import { ClosedQuestionsWrapper } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { UpdateContextFunction } from '../../types';
import { Context } from '../../wizard';
import GasConsumptionKnown from './GasConsumptionKnown';
import GasConsumptionUnknown from './gas-consumption-unknown/GasConsumptionUnknown';

export interface GasConsumptionFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
}

export default ({ onSubmit, goBack, updateContext, context }: GasConsumptionFormProps): React.ReactElement => {
    const [gasConsumptionKnown, setGasConsumptionKnown] = React.useState(context.gasConsumptionKnown ?? false);

    return (
        <Form title="Leistungsbedarf Erdgas">
            <FormLayout>
                <ClosedQuestionsWrapper
                    label={
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Wissen Sie bereits, welche Leistung Ihr Kunde insgesamt benötigen wird?'
                            : 'Wissen Sie bereits, welche Leistung Sie insgesamt benötigen werden?'
                    }
                    gridTemplateColumns="1fr 4fr"
                    itemsLayout="items-center"
                >
                    <RadioButton
                        name="consumptionKnown"
                        id="consumptionKnown-yes"
                        value="yes"
                        checked={gasConsumptionKnown}
                        onChange={() => {
                            setGasConsumptionKnown(true);
                            updateContext({ gasConsumptionKnown: true }, 'CONSUMPTION_GAS_CONSUMPTION');
                        }}
                    >
                        ja
                    </RadioButton>

                    <RadioButton
                        name="consumptionKnown"
                        id="consumptionKnown-no"
                        value="no"
                        checked={!gasConsumptionKnown}
                        onChange={() => {
                            setGasConsumptionKnown(false);
                            updateContext({ gasConsumptionKnown: false }, 'CONSUMPTION_GAS_CONSUMPTION');
                        }}
                    >
                        nein
                    </RadioButton>
                </ClosedQuestionsWrapper>
            </FormLayout>
            {gasConsumptionKnown && (
                <GasConsumptionKnown
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={context}
                />
            )}
            {!gasConsumptionKnown && (
                <GasConsumptionUnknown
                    onSubmit={onSubmit}
                    goBack={goBack}
                    updateContext={updateContext}
                    context={context}
                />
            )}
        </Form>
    );
};
