import { Invoice } from '../../types';
import { Context } from '../../wizard';
import { transformContextToContactData } from '../contact-address-form/transformContextToContactData';

export default (context: Partial<Context>): Invoice => {
    if (context.isDeviatingInvoiceAddress) {
        if (context.deviatingInvoiceAddressType === 'private') {
            return { ...context.invoicePrivate, isEqualApplicantData: false };
        }
        return { ...context.invoiceCompany, isEqualApplicantData: false };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { userType, telephone, email, ...relevantProps } = transformContextToContactData(context);
    return { ...relevantProps, isEqualApplicantData: true };
};
