import { Checkbox } from '@ten-netzkundenportal/ui-components';
import logo from '@ten-netzkundenportal/ui-components/dist/ten_logo_rgb_rz.jpg';
import { ManualCostTable } from '@ten-netzkundenportal/ui-contract-offer/build';
import * as React from 'react';

import { ManualOffer } from '../../types';

export type ManualOfferProps = {
    title: string;
    disabled?: boolean;
    manualOffer?: ManualOffer;
    checked?: boolean;
    id: string;
    onClick?: () => void;
};

export default ({
    title,
    disabled = false,
    manualOffer,
    checked,
    id,
    onClick,
}: ManualOfferProps): React.ReactElement => {
    const checkboxTitle = `Ja, ich möchte den Netzanschlussvertrag ${title} annehmen.`;
    const checkboxRef = React.useRef<HTMLInputElement>();

    const clickHandler = () => {
        if (!disabled) {
            onClick();
        }
    };

    React.useEffect(() => {
        checkboxRef.current.checked = checked;
    }, [checked]);

    return (
        <div className={`w-full h-full border ${checked ? 'border-dark-blue' : ''} ${disabled ? 'opacity-50' : ''}`}>
            <div className="shadow-ten w-full h-full">
                <div className=" flex flex-col gap-4">
                    <div className="p-5">
                        <div className="flex justify-between">
                            <span className="pt-1">Vorgangsnummer: {manualOffer.connectionRequestId}</span>
                            <h1 className="self-center font-bold text-medium text-center">
                                Netzanschlussvertrag - {title}
                            </h1>
                            <div className="self-end">
                                <img src={logo} alt="Logo Thüringer Energienetze" width="125px" />
                            </div>
                        </div>
                        <ManualCostTable manualOffer={manualOffer} />
                    </div>
                    <div
                        className={`flex py-5 border-t border-dark-blue bg-blue-50 justify-end ${
                            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={clickHandler}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            if (e.key === 'Enter') {
                                clickHandler();
                            }
                        }}
                        tabIndex={0}
                        role="button"
                    >
                        <div className="flex justify-end px-5 gap-x-5 pointer-events-none">
                            <span className="text-dark-blue font-bold">{checkboxTitle}</span>
                            <Checkbox name={id} ref={checkboxRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
