import { Context } from '../../wizard';

export default (context: Partial<Context>) => ({
    ...context.building,
    newBuilding: context.building && context.building.newBuilding === 'yes',
    basement: context.building?.basement ? context.building.basement === 'yes' : undefined,
    specialType:
        context.building.specialType && context.building.specialType.length > 1
            ? context.building.specialType
            : undefined,
});
