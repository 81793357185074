import { Dropdown, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AddRemoveButtonGroup as ButtonGroup } from '../../../components';
import useMultiField from '../../../components/useMultiField';
import { GasConsumptionUnknownData, GasDevices, GasDevicesOptions, UserType } from '../../../types';

export type GasDevicesProps = {
    reactHookForm: UseFormReturn<GasConsumptionUnknownData>;
    userType: UserType;
    syncContext: () => void;
};

export default ({ reactHookForm, userType, syncContext }: GasDevicesProps): React.ReactElement => {
    const {
        register,
        control,
        formState: { errors, touchedFields },
        setValue,
        getValues,
        trigger,
        reset,
    } = reactHookForm;

    const [fields, append, remove] = useMultiField({
        control,
        name: 'gasDevices',
        defaultValue: {},
        resetDefaultValues: reset,
        syncContext,
    });

    return (
        <FormItem
            label={
                <>
                    {userType === 'installer' || userType === 'projectpartner' ? 'Wird Ihr Kunde ' : 'Werden Sie '}
                    weitere, fest angeschlossene Erdgasgeräte wie z.B. eine zusätzliche Warmwasserbereitung haben?
                    <span className="text-darkest-grey ml-1">(optional)</span>
                </>
            }
            className="items-start"
        >
            <div className="flex flex-col gap-y-3">
                {fields.map((field, index) => {
                    const inputIsRequired =
                        !!getValues(`gasDevices.${index}.type`) ||
                        !!getValues(`gasDevices.${index}.capacityInKW`) ||
                        !!getValues(`gasDevices.${index}.amount`);

                    const onTypeChange = async () => {
                        const selectedObject = GasDevices.find(
                            (element) => element.type === getValues(`gasDevices.${index}`).type,
                        );

                        setValue(`gasDevices.${index}.capacityInKW`, selectedObject.capacityInKW, {
                            shouldValidate: true,
                        });
                        if (getValues(`gasDevices.${index}.type`) === '') {
                            setValue(`gasDevices.${index}.capacityInKW`, undefined, {
                                shouldValidate: true,
                            });

                            setValue(`gasDevices.${index}.amount`, undefined, {
                                shouldValidate: true,
                            });

                            await trigger(`gasDevices.${index}.capacityInKW`);
                            await trigger(`gasDevices.${index}.amount`);
                        }
                        syncContext();
                    };

                    return (
                        <div className="flex flex-row items-center justify-start w-full gap-x-6" key={field.id}>
                            <div className="flex items-center justify-start w-10/12 md:w-2/3 gap-x-2 ml-px mb-1">
                                <div className="flex flex-col md:grid md:w-3/5 min-w-[120px]">
                                    {index === 0 && (
                                        <label className="text-small" htmlFor={`dropdown-gasDevices.${index}.type`}>
                                            Art Erdgasgerät
                                        </label>
                                    )}
                                    <Dropdown
                                        name={`gasDevices.${index}.type`}
                                        dropdownClassname="w-full"
                                        options={GasDevicesOptions}
                                        {...register(`gasDevices.${index}.type` as const, {
                                            required: false,
                                            shouldUnregister: true,
                                            onChange: onTypeChange,
                                        })}
                                    />
                                </div>

                                <div className="flex flex-col md:grid">
                                    {index === 0 && (
                                        <label
                                            className="text-small"
                                            htmlFor={`numberinput-gasDevices.${index}.capacityInKW`}
                                        >
                                            Leistung
                                        </label>
                                    )}
                                    <NumberInput
                                        name={`gasDevices.${index}.capacityInKW`}
                                        {...NumberInputFunctions.registerNumberField(
                                            reactHookForm,
                                            `gasDevices.${index}.capacityInKW`,
                                            {
                                                required: inputIsRequired,
                                                shouldUnregister: true,
                                                min: 1,
                                                max: 999,
                                                onChange: syncContext,
                                            },
                                        )}
                                        displayErrorMessage={
                                            touchedFields?.gasDevices?.[index.toString()]?.capacityInKW &&
                                            errors?.gasDevices?.[index.toString()]?.capacityInKW &&
                                            'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                        }
                                    />
                                </div>
                                <span className={`${index === 0 ? 'mt-4 ' : ''} mr-0 md:mr-4`}>kW</span>
                                <div className="flex flex-col md:grid">
                                    {index === 0 && (
                                        <label
                                            className="text-small"
                                            htmlFor={`numberinput-gasDevices.${index}.amount`}
                                        >
                                            Anzahl
                                        </label>
                                    )}
                                    <NumberInput
                                        name={`gasDevices.${index}.amount`}
                                        {...NumberInputFunctions.registerNumberField(
                                            reactHookForm,
                                            `gasDevices.${index}.amount`,
                                            {
                                                required: inputIsRequired,
                                                shouldUnregister: true,
                                                min: 1,
                                                max: 999,
                                                onChange: syncContext,
                                            },
                                        )}
                                        displayErrorMessage={
                                            touchedFields?.gasDevices?.[index]?.amount &&
                                            errors?.gasDevices?.[index]?.amount &&
                                            'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                        }
                                    />
                                </div>
                            </div>
                            <ButtonGroup
                                group="gasDevices"
                                handleAppend={append}
                                handleDelete={remove(index)}
                                fields={fields}
                                index={index}
                                width="w-2/12 md:w-1/3"
                            />
                        </div>
                    );
                })}
            </div>
        </FormItem>
    );
};
