import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { OFFER_CONCLUSION, OFFER_GAS, OFFER_POWER, REQUEST_OVERVIEW } from '../routes';
import { RouteProperty } from '../types';
import { MaterialList } from './api/material-costs/MaterialPriceAPI';
import useOffer from './api/useOffer';
import OfferConclusionForm from './offer-conclusion-form';
import OfferGasForm from './offer-gas-form';
import OfferPowerForm from './offer-power-form';
import RequestOverviewForm from './request-overview-form';

type OfferProperties = RouteProperty & { materialList: MaterialList };

export default ({ onSubmit, goBack, context, updateContext, materialList }: OfferProperties): React.ReactElement => {
    const [offer, state, isLoading] = useOffer(context, updateContext);

    return (
        <Switch>
            <Route path={REQUEST_OVERVIEW}>
                <RequestOverviewForm
                    onSubmit={onSubmit}
                    goBack={goBack}
                    context={context}
                    offerRequestState={state}
                    loading={isLoading}
                />
            </Route>
            <Route path={OFFER_POWER}>
                {offer && offer.isElectricityOfferAutomaticallyCalculated && (
                    <OfferPowerForm
                        onSubmit={onSubmit}
                        goBack={goBack}
                        context={context}
                        electricityOffer={offer.electricityOffer}
                        materialList={materialList}
                    />
                )}
            </Route>
            <Route path={OFFER_GAS}>
                {offer && offer.isGasOfferAutomaticallyCalculated && (
                    <OfferGasForm
                        onSubmit={onSubmit}
                        goBack={goBack}
                        context={context}
                        gasOffer={offer.gasOffer}
                        materialList={materialList}
                    />
                )}
            </Route>
            <Route path={OFFER_CONCLUSION}>
                {offer && (
                    <OfferConclusionForm
                        onSubmit={onSubmit}
                        goBack={goBack}
                        context={context}
                        offer={offer}
                        materialList={materialList}
                    />
                )}
            </Route>
        </Switch>
    );
};
