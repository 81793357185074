import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import { Context } from '../../wizard';
import germanizeBoolean from './util';

export interface ConnectionColumnProps {
    context: Partial<Context>;
}

const ConnectionColumn = ({ context }: ConnectionColumnProps): React.ReactElement => (
    <Column title="Anschluss">
        <ColumnSection title="Anschlussdaten">
            <ColumnItem label="Mehrspartenhauseinführung" text={germanizeBoolean(context.multiEntry)} />
            {context.power && (
                <ColumnItem
                    label="Übergabestelle Strom"
                    text={
                        {
                            inside: 'innerhalb des Gebäudes',
                            outside: 'außerhalb des Gebäudes',
                        }[context.electricityPosition]
                    }
                />
            )}
            {context.power && context.electricityPosition === 'outside' && (
                <ColumnItem
                    label="Hausanschlussart Strom"
                    text={
                        {
                            house: 'Hausanschlusssäule',
                            counter: 'Zähleranschlusssäule (an Grundstücksgrenze)',
                            unclear: 'Noch in Klärung',
                        }[context.connectionType]
                    }
                />
            )}

            {context.gas && (
                <ColumnItem
                    label="Übergabestelle Erdgas"
                    text={
                        {
                            inside: 'innerhalb des Gebäudes',
                            outside: 'außerhalb des Gebäudes',
                        }[context.gasPosition]
                    }
                />
            )}

            {context.power && (
                <ColumnItem label="Länge Leitung Strom" text={`${context.electricityConnectionLength.toFixed()} m`} />
            )}

            {context.gas && (
                <ColumnItem
                    label="Länge Leitung Erdgas"
                    text={
                        `${context.gasConnectionLengthMainLineToCadastralDistrict.toFixed()} m + ` +
                        `${context.gasConnectionLengthCadastralDistrictToBuilding.toFixed()} m = ${
                            +context.gasConnectionLengthMainLineToCadastralDistrict.toFixed() +
                            +context.gasConnectionLengthCadastralDistrictToBuilding.toFixed()
                        } m`
                    }
                />
            )}

            <ColumnItem
                label="Überquerung fremdes Grundstück"
                text={
                    {
                        no: 'nein',
                        power: 'ja, für die Stromleitung',
                        gas: 'ja, für die Erdgasleitung',
                        powerAndGas: 'ja, für die Strom- und Erdgasleitung',
                    }[context.propertyCrossing]
                }
            />
        </ColumnSection>

        <ColumnSection title="Eigentümer">
            <ColumnItem label="Grundstückseigentümer" text={germanizeBoolean(context.isLandOwner)} />
            {context.isLandOwner === 'no' && <ColumnItem label="Einverständnis des Eigentümers" text="ja, liegt vor" />}
        </ColumnSection>
    </Column>
);

export default ConnectionColumn;
