import * as React from 'react';

export type TableProps = {
    name: string;
    icon: React.ReactElement;
    firstRow: React.ReactElement;
    secondRow: React.ReactElement;
    thirdRow?: React.ReactElement;
};

export default ({ name, icon, firstRow, secondRow, thirdRow }: TableProps): React.ReactElement => (
    <div className="relative" style={{ minWidth: '650px', maxWidth: '850px', height: '15rem' }}>
        <div className="w-full h-full flex flex-row justify-center shadow-ten">
            <div className="w-full h-full self-center grid auto-rows-auto ml-6 mr-2 py-4 gap-y-2">
                <div className="flex">
                    <div className="w-6 self-center text-primary fill-current">{icon}</div>
                    <div className="self-center font-bold">{name}</div>
                </div>
                <div
                    className="grid w-full ml-2 h-11 place-self-end mt-2"
                    style={{ gridTemplateColumns: '5fr 3fr 3fr 1fr' }}
                >
                    <div className=" self-end items-start justify-start h-full font-bold">Leistung</div>
                    <div className="place-self-end mr-4 grid text-right h-full">
                        <span className="font-bold">Nettopreis</span>
                        <span>(exkl. Umsatzsteuer)</span>
                    </div>
                    <div className="place-self-end grid text-right h-full">
                        <span className="font-bold">Bruttopreis</span>
                        <span>(inkl. Umsatzsteuer)</span>
                    </div>
                </div>
                <hr className="border-dark-grey" style={{ width: '92%' }} />
                {firstRow}
                {secondRow}
                {thirdRow}
            </div>
        </div>
    </div>
);
