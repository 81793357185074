import { Button, CenteredLoadingSpinner, PlusCircle } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';

import useApi from '../../../hooks/useApi';
import { DASHBOARD, OFFER_REVISITED_DOCUMENT_UPLOAD } from '../../../routes';
import { APIRequestState } from '../../../types';
import { DocumentMetadata } from '../../types';
import DocumentListArea from './DocumentListArea';
import { getDocumentList } from './api/getDocumentList';

export const fileCategoryMapper: Record<'contract' | 'invoice', string> = {
    contract: 'Verträge',
    invoice: 'Rechnungen',
};

const OfferRevisitedDocument = (): React.ReactElement => {
    const { connectionRequestId, processCommunicationId } = useParams();
    const pathname = generatePath(OFFER_REVISITED_DOCUMENT_UPLOAD, { connectionRequestId, processCommunicationId });

    const [fetchDocumentState, setFetchDocumentState] = React.useState<APIRequestState>('initial');
    const [documents, setDocuments] = React.useState<DocumentMetadata[]>([]);

    const getDocumentListApi = useApi(getDocumentList);
    const fetchDocumentMetadataList = React.useCallback(async () => {
        setFetchDocumentState('loading');

        try {
            const documentMetadataList = await getDocumentListApi({
                connectionRequestId,
                processCommunicationId,
            });
            setDocuments([...documentMetadataList]);
            setFetchDocumentState('finished_successfully');
        } catch (error) {
            console.error('Failed to load documents', error);

            setFetchDocumentState('error');
        }
    }, [connectionRequestId, getDocumentListApi, processCommunicationId]);

    React.useEffect(() => {
        fetchDocumentMetadataList();
    }, [fetchDocumentMetadataList]);

    if (fetchDocumentState === 'loading') {
        return <CenteredLoadingSpinner />;
    }

    return (
        <div className="w-full flex flex-col gap-y-12 py-10 relative">
            <h3 className="font-bold text-medium">Dokumentenverwaltung ({processCommunicationId})</h3>
            {fetchDocumentState === 'finished_successfully' && (
                <>
                    <p>
                        In diesem Bereich finden Sie alle vorliegenden Dokumente zu diesem Vorgang. Sie können diese
                        ansehen, indem Sie auf den Dokumentennamen klicken. Sie haben gerade neue Dokumente hochgeladen?
                        Es kann einen kurzen Moment dauern, bis diese angezeigt werden.
                    </p>
                    <Link className="flex min-w-max items-center font-bold" to={{ pathname }}>
                        <PlusCircle className="mr-2 h-4 fill-current text-primary" />
                        <span className="hover:underline">Dokumente hochladen</span>
                    </Link>
                    <DocumentListArea
                        documents={documents}
                        processCommunicationId={processCommunicationId}
                        connectionRequestId={connectionRequestId}
                    />
                </>
            )}
            {fetchDocumentState === 'error' && (
                <p className="py-6">Es konnte kein Dokument geladen werden. Bitte versuchen Sie es später erneut.</p>
            )}
            <div className="grid grid-flow-col mt-12 h-min">
                <div className="button place-self-start flex">
                    <Button
                        label="Zurück"
                        size="md"
                        type="secondary"
                        id="goBack"
                        onClick={() => {
                            window.history.pushState({}, document.title, DASHBOARD);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default OfferRevisitedDocument;
