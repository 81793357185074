import { Dropdown, Form, FormItem, InfoI, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { createPrevNextButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { ConnectionDataProperties, UpdateContextFunction } from '../../types';
import { resetDrawingData } from '../../util/resetDrawingData';
import { Context, useContextUpdate, useDynamicDefault } from '../../wizard';

export interface PowerConnectionFormProps {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
}

export default function ConnectionDataForm({
    onSubmit,
    goBack,
    updateContext,
    context,
}: PowerConnectionFormProps): React.ReactElement {
    const {
        control,
        register,
        watch,
        handleSubmit,
        setValue,
        formState: { isValid },
    } = useForm<ConnectionDataProperties>({
        mode: 'onChange',
        defaultValues: {
            multiEntry: context.multiEntry,
            electricityPosition: context.electricityPosition,
            gasPosition: context.gasPosition,
        },
        shouldUnregister: true,
    });
    const powerPosition = useWatch({ control, name: 'electricityPosition' });
    const gasPosition = useWatch({ control, name: 'gasPosition' });
    const isBuildingTypeSpecial = context.building.buildingType === 'special';
    const isPowerSelected = context.power ?? false;
    const isGasSelected = context.gas ?? false;

    const multiEntry = useWatch({ control, name: 'multiEntry' });

    useEffect(() => {
        if (multiEntry === 'yes' && (context.powerMarker || context.gasMarker)) {
            updateContext({
                powerMarker: undefined,
                gasMarker: undefined,
                electricityLineCourse: undefined,
                isElectricityLineCourseValid: false,
                gasLineCourse: undefined,
                isGasLineCourseValid: false,
            });
        } else if (multiEntry === 'no' && context.multiEntryMarker) {
            updateContext({
                multiEntryMarker: undefined,
                electricityLineCourse: undefined,
                isElectricityLineCourseValid: false,
                gasLineCourse: undefined,
                isGasLineCourseValid: false,
            });
        }
    }, [context.gasMarker, context.multiEntryMarker, context.powerMarker, multiEntry, updateContext]);

    React.useEffect(() => {
        if (isBuildingTypeSpecial && isPowerSelected) {
            setValue('electricityPosition', 'outside', { shouldValidate: true });
            setValue('connectionType', 'counter', { shouldValidate: true });
        }
    }, [isBuildingTypeSpecial, isGasSelected, isPowerSelected, setValue]);

    useDynamicDefault(
        setValue,
        isPowerSelected && powerPosition === 'outside',
        'connectionType',
        context.connectionType ?? null,
    );
    useContextUpdate(watch, updateContext, 'CONNECTION_DATA');

    const infoDescription =
        'Bei einer Mehrspartenhauseinführung handelt es sich um ein Leerrohrsystem, welches ' +
        'die Versorgungsmedien Strom, Erdgas, Wasser und Telekommunikation durch eine Öffnung in ein Gebäude bringt.';
    const dropdownOptions = [
        {
            value: 'house',
            label: 'Hausanschlusssäule',
        },
        {
            value: 'counter',
            label: 'Zähleranschlusssäule (an Grundstücksgrenze)',
        },
        {
            value: 'unclear',
            label: 'Noch in Klärung',
        },
    ];

    const multiEntryLabel = (
        <div className="inline">
            <span>Sehen Sie eine Mehrspartenhauseinführung vor?</span>
            <div
                className="inline-block ml-2 w-3 overflow-visible fill-current text-primary
                        hover:text-dark-blue"
            >
                <InfoI size="30rem" text={infoDescription} />
            </div>
        </div>
    );

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
    });

    useEffect(() => {
        if (isBuildingTypeSpecial) {
            setValue('connectionType', 'counter', { shouldValidate: true });
        }
    }, [setValue, isBuildingTypeSpecial]);

    return (
        <Form title="Anschluss" handleSubmit={handleSubmit(onSubmit)} leftButton={prevButton} rightButton={nextButton}>
            <FormLayout alignItems="items-center">
                <FormItem label={multiEntryLabel} className="grid grid-cols-[1fr_1fr] w-full">
                    <div className="grid grid-cols-[1fr_1fr]">
                        <RadioButton
                            id="multiEntry-yes"
                            name="multiEntry"
                            value="yes"
                            {...register('multiEntry', {
                                onChange: () => {
                                    resetDrawingData(updateContext, 'CONNECTION_DATA');
                                },
                            })}
                        >
                            ja
                        </RadioButton>
                        <RadioButton
                            id="multiEntry-no"
                            name="multiEntry"
                            value="no"
                            {...register('multiEntry', {
                                onChange: () => {
                                    resetDrawingData(updateContext, 'CONNECTION_DATA');
                                },
                            })}
                        >
                            nein
                        </RadioButton>
                    </div>
                </FormItem>
                {isPowerSelected && (
                    <FormItem
                        label="Wo ist der gewünschte Standort der Übergabestelle (Hausanschlusskasten) für Strom?"
                        className={`${isBuildingTypeSpecial && 'opacity-60 pointer-events-none'} grid grid-cols-[1fr_1fr]`}
                    >
                        <div className="grid grid-cols-[1fr_1fr]">
                            <RadioButton
                                name="powerPosition"
                                id="powerPosition-inside"
                                value="inside"
                                {...register('electricityPosition', { shouldUnregister: true })}
                            >
                                innerhalb des Gebäudes
                            </RadioButton>
                            <RadioButton
                                name="powerPosition"
                                id="powerPosition-outside"
                                value="outside"
                                {...register('electricityPosition', { shouldUnregister: true })}
                            >
                                außerhalb des Gebäudes
                            </RadioButton>
                        </div>
                        {powerPosition === 'outside' ? (
                            <Dropdown
                                name="connectionType"
                                label="Welche Art des Haus&shy;anschlusses planen Sie?"
                                options={dropdownOptions}
                                labelClassname="w-full"
                                dropdownClassname={`w-full ${isBuildingTypeSpecial && 'opacity-60 pointer-events-none'}`}
                                {...register('connectionType', { shouldUnregister: true, required: true })}
                            />
                        ) : undefined}
                    </FormItem>
                )}
                {(isGasSelected && (
                    <FormItem
                        label="Wo ist der gewünschte Standort der Übergabestelle für Erdgas?"
                        className="grid grid-cols-[1fr_1fr]"
                    >
                        <div className="grid grid-cols-[1fr_1fr]">
                            <RadioButton
                                name="gasPosition"
                                id="gasPosition-inside"
                                value="inside"
                                {...register('gasPosition', { shouldUnregister: true })}
                            >
                                innerhalb des Gebäudes
                            </RadioButton>
                            <RadioButton
                                name="gasPosition"
                                id="gasPosition-outside"
                                value="outside"
                                {...register('gasPosition', { shouldUnregister: true })}
                            >
                                außerhalb des Gebäudes
                            </RadioButton>
                        </div>
                        {gasPosition === 'outside' ? (
                            <span className="text-small justify-self-start place-self-center">
                                <span className="font-bold">Hinweis:</span> Der Hausanschlusskasten muss an einer
                                Außenwand positioniert werden.
                            </span>
                        ) : undefined}
                    </FormItem>
                )) || <div />}
            </FormLayout>
        </Form>
    );
}
