import { Dropdown } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useForm } from 'react-hook-form';

import { DocumentMetadata } from '../../types';
import DocumentViewLink from './DocumentViewLink';

type Props = {
    documents: DocumentMetadata[];
    processCommunicationId: string;
    connectionRequestId: string;
};
export const fileCategoryMapper: Record<'contract' | 'invoice' | 'misc', string> = {
    contract: 'Verträge',
    invoice: 'Rechnungen',
    misc: 'Sonstiges',
};

const DocumentListArea = ({ documents, processCommunicationId, connectionRequestId }: Props) => {
    const { register } = useForm<{ selectDocumentCategory: string }>({
        mode: 'onBlur',
        defaultValues: {
            selectDocumentCategory: 'all',
        },
    });

    const [filteredDocuments, setFilteredDocuments] = React.useState<DocumentMetadata[]>(documents);

    const createDocumentList = () =>
        filteredDocuments.map((filteredDocument) => (
            <div className="flex w-full" key={filteredDocument.documentId} data-testid="listed-documents">
                <div className="grid grid-cols-2 border-b border-dark-grey place-items-center gap-x-8 h-16 break-all w-full">
                    <DocumentViewLink
                        processCommunicationId={processCommunicationId}
                        connectionRequestId={connectionRequestId}
                        {...filteredDocument}
                    />
                    <p title={fileCategoryMapper[filteredDocument.documentType]} className="py-2 truncate w-full">
                        {fileCategoryMapper[filteredDocument.documentType]}
                    </p>
                </div>
            </div>
        ));

    return (
        <>
            <div
                className="flex align-center bg-blue-50 px-12 h-[100px] pb-[6px]
                            full-main-container-width"
            >
                <div className="flex">
                    <div className="flex flex-col justify-center gap-y-2">
                        <span className="text-sm font-bold">Kategorie</span>
                        <div className="items-center flex">
                            <div className="w-72 mr-20">
                                <Dropdown
                                    options={[
                                        {
                                            label: 'Alle',
                                            value: 'all',
                                        },
                                        {
                                            label: 'Rechnungen',
                                            value: 'invoice',
                                        },
                                        {
                                            label: 'Verträge',
                                            value: 'contract',
                                        },
                                        {
                                            label: 'Sonstiges',
                                            value: 'misc',
                                        },
                                    ]}
                                    dropdownClassname="w-full"
                                    data-testid="selectDocumentCategory"
                                    {...register('selectDocumentCategory', {
                                        onChange: (e) => {
                                            const category = e.currentTarget.value;
                                            const documentsFilteredByCategory = documents.filter(
                                                (file) => file.documentType === category || category === 'all',
                                            );
                                            setFilteredDocuments([...documentsFilteredByCategory]);
                                        },
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-96">
                {(documents.length === 0 || filteredDocuments.length === 0) && (
                    <div className="py-6"> Derzeit sind keine Dokumente für Sie verfügbar.</div>
                )}
                {filteredDocuments.length > 0 && (
                    <div
                        className="overflow-y-scroll custom-scrollbar
                                        h-full p-2.5 mt-5 -ml-2.5 -mr-2.5"
                    >
                        <div className="max-h-[36rem]">
                            <div className="flex w-full">
                                <div className="grid grid-cols-2 h-12 gap-x-8 border-b border-dark-grey w-full ">
                                    <span className="font-bold flex pb-2">Name</span>
                                    <span className="font-bold flex pb-2">Kategorie</span>
                                </div>
                            </div>
                            <div className="flex flex-col place-items-start h-full" data-testid="documentEntry">
                                {filteredDocuments?.length && createDocumentList()}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DocumentListArea;
