import { Telephone } from '../types';
import { Context } from './context';

const initialContext: Partial<Context> = {
    serviceType: 'newConnection',
    power: false,
    gas: false,
    // building
    building: {
        buildingType: 'singleFamily',
        newBuilding: 'yes',
        basement: 'no',
        // building multiFamily
        residentialUnit: 1,
        communityFacilities: 0,
        commercialUnit: 0,
    },
    infrastructure: [],
    // electric
    electricHeater: false,
    electricWarmWater: false,
    extractionPlant: false,
    chargingStation: false,
    // gas
    gasHeating: 'no',
    gasOutlet: 'no',
    gasDevices: [],
    gasAdditionalDevices: [],
    // connection
    isLandOwner: 'yes',
    multiEntry: 'no',
    electricityPosition: 'inside',
    gasPosition: 'inside',
    houseShapes: [],
    // addresses
    telephone: {
        countryPrefix: '49',
        type: 'Mobile',
    } as Telephone,
    // transposition
    chosenInstallers: [],
    haveReadInformationSheetCivilEngineeringGas: false,
    haveReadInformationSheetCivilEngineeringPower: false,
    personalContributionCivilEngineeringPower: false,
    personalContributionCivilEngineeringGas: false,
};

export default initialContext;
