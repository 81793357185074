import { CadastralDistrict } from '../../types';
import { Context } from '../../wizard';
import transformContextLocationToCadastralDistrictLocation from './transformContextLocationToCadastralDistrictLocation';

export default (context: Partial<Context>): CadastralDistrict => ({
    location: transformContextLocationToCadastralDistrictLocation(context),
    boundingBox: context.boundingBox,
    multiEntry: context.multiEntry === 'yes',
    electricityConfiguration: context.power
        ? {
              position: context.electricityPosition,
              connectionType: context.electricityPosition === 'outside' ? context.connectionType : undefined,
              isCrossingProperty: context.propertyCrossing === 'power' || context.propertyCrossing === 'powerAndGas',
          }
        : undefined,
    gasConfiguration: context.gas
        ? {
              position: context.gasPosition,
              connectionType: context.gasPosition === 'outside' ? 'house' : undefined,
              isCrossingProperty: context.propertyCrossing === 'gas' || context.propertyCrossing === 'powerAndGas',
          }
        : undefined,
    isLandOwner: context.isLandOwner !== undefined ? context.isLandOwner === 'yes' : true,
    hasLandOwnerConsent: context.hasLandOwnerConsent,
});
