import { Checkbox } from '@ten-netzkundenportal/ui-components';
import logo from '@ten-netzkundenportal/ui-components/dist/ten_logo_rgb_rz.jpg';
import { AutomaticCostTable } from '@ten-netzkundenportal/ui-contract-offer';
import React, { useEffect } from 'react';

import { BranchSpecificOffer } from '../../types';

export type AutomaticOfferProperties = {
    disabled?: boolean;
    automaticOffer: BranchSpecificOffer;
    hint: string;
    personalContribution: {
        type: string;
        value: string;
    }[];
    checked: boolean;
    title: string;
    id: string;
    onClick?: () => void;
};

const AutomaticOffer = ({
    disabled = false,
    automaticOffer,
    hint,
    personalContribution,
    checked,
    title,
    id,
    onClick,
}: AutomaticOfferProperties): React.ReactElement => {
    const checkboxTitle = `Ja, ich möchte den Netzanschlussvertrag ${title} annehmen.`;
    const checkboxRef = React.useRef<HTMLInputElement>();

    const clickHandler = () => {
        if (!disabled) {
            onClick();
        }
    };

    useEffect(() => {
        checkboxRef.current.checked = checked;
    }, [checked]);

    return (
        <div className={`w-full h-full border ${checked ? 'border-dark-blue' : ''} ${disabled ? 'opacity-50' : ''}`}>
            <div className="shadow-ten w-full h-full">
                <div className=" flex flex-col gap-4">
                    <div className="p-5">
                        <div className="flex justify-between">
                            <span className="pt-1">Vorgangsnummer: {automaticOffer.processCommunicationId}</span>
                            <h1 className="self-center font-bold text-medium text-center">
                                Netzanschlussvertrag - {title}
                            </h1>
                            <div className="self-end">
                                <img src={logo} alt="Logo Thüringer Energienetze" width="125px" />
                            </div>
                        </div>
                        <AutomaticCostTable
                            automaticOffer={automaticOffer}
                            hint={hint}
                            personalContribution={personalContribution}
                        />
                    </div>
                    <div
                        className={`flex py-5 border-t border-dark-blue bg-blue-50 justify-end ${
                            disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                        }`}
                        onClick={clickHandler}
                        onKeyDown={(e) => {
                            e.preventDefault();
                            if (e.key === 'Enter') {
                                clickHandler();
                            }
                        }}
                        tabIndex={0}
                        role="button"
                    >
                        <div className="flex justify-end px-5 gap-x-5 pointer-events-none">
                            <span className="text-dark-blue font-bold">{checkboxTitle}</span>
                            <Checkbox name={id} ref={checkboxRef} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AutomaticOffer;
