const readConnectionRequestFromLocalstorage = (key: string) => {
    let localStorageObject = JSON.parse(localStorage.getItem('antragsstrecke'));
    if (key === '') {
        return localStorageObject;
    }
    const keyList = key.split('.');
    let localStorageObjectKeys = Object.keys(localStorageObject);

    for (let i = 0; i < keyList.length; i += 1) {
        if (i === keyList.length - 1) {
            return localStorageObject[keyList[i]];
        }

        if (localStorageObjectKeys.includes(keyList[i])) {
            localStorageObjectKeys = Object.keys(localStorageObject[keyList[i]]);
            localStorageObject = localStorageObject[keyList[i]];
        } else {
            return undefined;
        }
    }
    return undefined;
};

export default readConnectionRequestFromLocalstorage;
