import React from 'react';

type FormItemProps = {
    children: React.ReactNode;
    alignItems?: 'items-start' | 'items-center';
};

const FormLayout = ({ children, alignItems = 'items-start' }: FormItemProps) => (
    <div
        className={`grid gap-4 md:gap-12 ${alignItems} grid-cols-none
    lg:grid-cols-[minmax(min-content,_25rem)_auto] `}
    >
        {children}
    </div>
);

export default FormLayout;
