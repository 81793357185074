import {
    ConnectionSectionElectricity,
    ConnectionSectionGas,
    InfoTextForLocation,
    ObjectOfContract,
    OfferTemplate,
    PropertyBoundarySection,
} from '@ten-netzkundenportal/ui-contract-offer';
import React from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import { createPrevNextButton } from '../../../components';
import LocationSection from '../../../components/offer/LocationSection';
import { DASHBOARD, OFFER_REVISITED_CONCLUSION } from '../../../routes';
import { addressToString } from '../../../util';
import { isElectricityOffer, isGasOffer, isManualElectricityOffer } from '../../guards';
import { getHint } from '../../util';
import { OfferRevisitedChildrenProps } from '../index';

const Offer = ({ mappedData, personalContribution, userType }: OfferRevisitedChildrenProps): React.ReactElement => {
    const history = useHistory();
    const { connectionRequestId, processCommunicationId } = useParams();

    const getConnectionLengthSection = () => {
        if (!mappedData || (!mappedData.automaticOffer && !mappedData.manualOffer)) {
            return null;
        }
        if (isElectricityOffer(mappedData.automaticOffer)) {
            return <ConnectionSectionElectricity connectionLength={mappedData.automaticOffer.connectionLength} />;
        }
        if (isGasOffer(mappedData.automaticOffer)) {
            return (
                <ConnectionSectionGas
                    connectionLengthCadastralDistrictToBuilding={
                        mappedData.automaticOffer?.connectionLengthCadastralDistrictToBuilding
                    }
                    connectionLengthMainLineToCadastralDistrict={
                        mappedData.automaticOffer?.connectionLengthMainLineToCadastralDistrict
                    }
                />
            );
        }
        if (isManualElectricityOffer(mappedData.manualOffer)) {
            return <ConnectionSectionElectricity connectionLength={mappedData.manualOffer.connection.length} />;
        }
        return (
            <ConnectionSectionGas
                connectionLengthCadastralDistrictToBuilding={
                    mappedData.manualOffer.connection.lengthCadastralDistrictToBuilding
                }
                connectionLengthMainLineToCadastralDistrict={
                    mappedData.manualOffer.connection.lengthMainLineToCadastralDistrict
                }
            />
        );
    };

    const isUserContractualPartner = userType === 'private' || userType === 'company';

    const handleBack = () => {
        window.location.href = DASHBOARD;
    };

    const handleSubmit = () => {
        history.push(
            generatePath(OFFER_REVISITED_CONCLUSION, {
                connectionRequestId,
                processCommunicationId,
            }),
        );
    };

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: handleBack,
        onNext: handleSubmit,
        prevLabel: isUserContractualPartner ? 'Abbrechen' : 'Zurück',
        isNextValid: true,
    });
    return !mappedData ? (
        <div data-testid="loading" />
    ) : (
        <div className="flex flex-col mb-10 gap-y-12">
            <OfferTemplate
                leftButton={prevButton}
                rightButton={isUserContractualPartner && nextButton}
                name={mappedData?.name}
                address={addressToString(mappedData?.address)}
                automaticOffer={mappedData?.automaticOffer}
                manualOffer={mappedData?.manualOffer}
                offerTypeName={mappedData?.branch === 'gas' ? 'Erdgas' : 'Strom'}
                netName={mappedData?.branch === 'gas' ? 'Erdgasnetz' : 'Niederspannungsnetz'}
                hint={getHint(mappedData?.branch)}
                personalContribution={personalContribution}
                objectOfContract={
                    <ObjectOfContract
                        location={
                            mappedData !== undefined ? (
                                <LocationSection location={mappedData.location.location} />
                            ) : (
                                <div />
                            )
                        }
                        connectionLengthSection={getConnectionLengthSection()}
                        propertyBoundarySection={<PropertyBoundarySection offerType={mappedData?.branch} />}
                        infoTextForLocationOfConnectionObject={<InfoTextForLocation offerType={mappedData?.branch} />}
                        landOwner
                        consumptionOutputLimit={mappedData?.manualOffer?.consumptionOutputLimit ?? 30}
                    />
                }
                isRevisited
                userType={userType}
            />
        </div>
    );
};

export default Offer;
