import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { INSTALLER_SELECTION, PERSONAL_CONTRIBUTION } from '../routes';
import InstallerSelectionForm from './installer-selection-form';
import PersonalContributionForm from './personal-contribution-form';

export default ({ onSubmit, goBack, updateContext, context, materialList }: Switch): React.ReactElement => (
    <Switch>
        <Route path={PERSONAL_CONTRIBUTION}>
            <PersonalContributionForm
                onSubmit={onSubmit}
                goBack={goBack}
                updateContext={updateContext}
                context={context}
                materialList={materialList}
            />
        </Route>
        <Route path={INSTALLER_SELECTION}>
            <InstallerSelectionForm
                onSubmit={onSubmit}
                goBack={goBack}
                updateContext={updateContext}
                context={context}
            />
        </Route>
    </Switch>
);
