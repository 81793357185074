import { CadastralDistrictLocation, StateCode } from '@ten-netzkundenportal/ui-location-search';

const isSaxony = (cadastralDistrictLocationOrStateCode: CadastralDistrictLocation | StateCode) => {
    if (!cadastralDistrictLocationOrStateCode) {
        return false;
    }

    if (typeof cadastralDistrictLocationOrStateCode === 'string') {
        return cadastralDistrictLocationOrStateCode === 'SN' || cadastralDistrictLocationOrStateCode === '14';
    }

    if (cadastralDistrictLocationOrStateCode.stateCode) {
        return (
            cadastralDistrictLocationOrStateCode.stateCode === 'SN' ||
            cadastralDistrictLocationOrStateCode.stateCode === '14'
        );
    }
    return false;
};

export default isSaxony;
