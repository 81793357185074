import L, { ZoomAnimEvent } from 'leaflet';
import * as React from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';

import {
    calculateLineAngleRelativeToNorth,
    calculatePolylineLengthInMeters,
    distanceBetweenTwoCoordinatesInMeters,
    setLengthLabelPositionAndAngle,
} from './lengthsUtil';

type LineCourseLabelProps = {
    polyline: L.Polyline;
    type: 'gas' | 'electricity';
};

const findLongestSegment = (polyline: L.Polyline): [L.LatLng, L.LatLng] => {
    const latlngs = polyline.getLatLngs() as L.LatLng[];
    return latlngs
        .filter((element, index) => index < latlngs.length - 1)
        .map((latlng: L.LatLng, index) => [latlng, latlngs[index + 1]])
        .sort(
            (segment1, segment2) =>
                distanceBetweenTwoCoordinatesInMeters(segment2[0], segment2[1]) -
                distanceBetweenTwoCoordinatesInMeters(segment1[0], segment1[1]),
        )[0] as [L.LatLng, L.LatLng];
};

export default ({ polyline, type }: LineCourseLabelProps) => {
    const map = useMap();
    const length = calculatePolylineLengthInMeters(polyline);
    const [startLatLng, endLatLng] = findLongestSegment(polyline);
    const middle = L.PM.Utils.calcMiddleLatLng(map, startLatLng, endLatLng);
    const location = L.PM.Utils.calcMiddleLatLng(map, endLatLng, middle);
    const layerPoint = map.latLngToLayerPoint(location);
    const angle = calculateLineAngleRelativeToNorth(startLatLng, endLatLng);
    const distanceRef = useRef<number>(length);
    const locationRef = useRef<L.LatLng>(location);
    const angleRef = useRef<number>(angle);
    distanceRef.current = length;
    locationRef.current = location;
    angleRef.current = angle;

    const ref = useRef<HTMLDivElement>();

    const zoomAnimation = useCallback(
        (e: ZoomAnimEvent) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const newPosition = map['_latLngToNewLayerPoint'](locationRef.current, e.zoom, e.center);
            setLengthLabelPositionAndAngle(ref, newPosition.x, newPosition.y, angleRef.current);
        },
        [map],
    );

    useEffect(() => {
        map.on('zoomanim', zoomAnimation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLengthLabelPositionAndAngle(ref, layerPoint.x, layerPoint.y, angle);
    }, [angle, layerPoint?.x, layerPoint?.y]);

    const colorClass = type === 'gas' ? 'gasConnectionLabel' : 'electricityConnection';

    return (
        <div
            ref={ref}
            className={`border bg-white rounded-md absolute px-[3px] pb-[2px] leading-none text-[13px] 
            pointer-events-none opacity-90 text-dark-blue leaflet-zoom-animated ${colorClass}`}
            style={{ transformOrigin: '50% 50% 0' }}
            data-testid={`line-course-length-label-${type}`}
        >
            {`${length.toLocaleString('de', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}&nbsp;m
        </div>
    );
};
