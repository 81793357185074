/* eslint-disable react/jsx-no-useless-fragment */
import {
    Button,
    Checkbox,
    ErrorBox,
    Form,
    LoadingSpinnerButton,
    StyledLink,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import AutomaticOffer from '../../components/offer/AutomaticOffer';
import useApi from '../../hooks/useApi';
import { OfferChecked } from '../../types';
import getOfferDownloadLink from '../../util/getOfferDownloadLink';
import { Context } from '../../wizard';
import { OfferReply } from '../api/OfferReplyAPI';
import { getOfferDocument } from '../api/getOfferDocument';
import { MaterialList } from '../api/material-costs/MaterialPriceAPI';
import { OfferResponse } from '../api/types';
import { getPersonalContribution } from '../util';
import ManualOfferWaiting from './ManualOfferWaiting';

export type AcceptOfferForm = {
    onBack: () => void;
    onNext: () => void;
    offer: OfferResponse;
    context: Partial<Context>;
    isAuthenticated: boolean;
    materialList: MaterialList;
    concludeOffer: (offerReplies: OfferReply[]) => Promise<boolean>;
};

export default ({
    onBack,
    onNext,
    context,
    offer,
    isAuthenticated,
    materialList,
    concludeOffer,
}: AcceptOfferForm): React.ReactElement => {
    const { electricityOffer, gasOffer } = offer;

    const personalContributionGas = getPersonalContribution(
        false,
        materialList,
        context.personalContributionMultiEntryGas,
        context.personalContributionCivilEngineeringGas,
    );
    const personalContributionElectricity = getPersonalContribution(
        true,
        materialList,
        context.personalContributionMultiEntryPower,
        context.personalContributionCivilEngineeringPower,
        context.personalContributionCounter,
    );

    const hint =
        'Die Hausanschlusskosten enthalten die Aufwendungen für ' +
        'Leitungsverlegung, -anschluss, Mauerdurchbruch, Hausanschlusskasten und' +
        ' die Inbetriebnahme des Netzanschlusses.';

    const {
        control,
        register,
        setValue,
        formState: { isValid },
    } = useForm<OfferChecked>({
        mode: 'onChange',
        defaultValues: {
            isElectricityOfferAccepted: false,
            isGasOfferAccepted: false,
            isAGBAccepted: false,
            areDocumentsAcknowledged: false,
        },
        shouldUnregister: true,
    });

    const isElectricityOfferAccepted = useWatch({ name: 'isElectricityOfferAccepted', control });
    const isGasOfferAccepted = useWatch({ name: 'isGasOfferAccepted', control });
    const [isSending, setIsSending] = React.useState(false);
    const [concludeError, setConcludeError] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const getOfferDocumentApi = useApi(getOfferDocument);

    const toggleGasOfferChecked = () => {
        setValue('isGasOfferAccepted', !isGasOfferAccepted);
        setValue('areDocumentsAcknowledged', false);
    };
    const toggleElectricityOfferChecked = () => {
        setValue('isElectricityOfferAccepted', !isElectricityOfferAccepted);
        setValue('areDocumentsAcknowledged', false);
    };

    const handleOnNext = async () => {
        if (isValid) {
            const offerReplies = [
                gasOffer && {
                    offerCommunicationId: gasOffer.processCommunicationId,
                    acceptOffer: isGasOfferAccepted,
                },
                electricityOffer && {
                    offerCommunicationId: electricityOffer.processCommunicationId,
                    acceptOffer: isElectricityOfferAccepted,
                },
            ].filter(Boolean);
            setConcludeError(false);
            setShowError(false);
            setIsSending(true);
            const wasResponseSuccessful = await concludeOffer(offerReplies);
            setIsSending(false);
            if (wasResponseSuccessful) {
                onNext();
            } else {
                setConcludeError(true);
                setShowError(true);
                console.error(`Failed to send offer reply`);
            }
        }
    };

    const openOfferDocument = async (processCommunicationId: string) => {
        const pdfDocument = await getOfferDocumentApi({
            processCommunicationId,
            connectionRequestId: context.meta.connectionRequestId,
        });
        return getOfferDownloadLink(pdfDocument, `NAV_${processCommunicationId}.pdf`);
    };

    return (
        <Form
            leftButton={<Button type="secondary" label="Zurück" onClick={onBack} />}
            rightButton={
                <LoadingSpinnerButton
                    label={
                        isElectricityOfferAccepted || isGasOfferAccepted
                            ? 'Zahlungspflichtig bestellen'
                            : 'Antrag abschließen'
                    }
                    type="primary"
                    disabled={!isValid || !isAuthenticated}
                    loading={isSending}
                    loadingTitle="Die Angebotsannahme wird übermittelt."
                    onClick={handleOnNext}
                    timeout={0}
                />
            }
            title="Unser Angebot an Sie"
        >
            <div className="-mb-8">
                Fast geschafft – Sie können nun {gasOffer && electricityOffer ? 'Ihre Angebote' : 'Ihr Angebot'}{' '}
                annehmen. Wenn Sie noch Zeit zum Überlegen benötigen, ist das kein Problem. Jedes Angebot ist für 12
                Wochen gültig. Sie können daher {gasOffer && electricityOffer ? 'Ihre Angebote' : 'Ihr Angebot'}{' '}
                innerhalb dieses Zeitraums über das Netzkundenportal jederzeit einsehen und annehmen.
            </div>
            {offer.electricityOffer && (
                <>
                    {offer.isElectricityOfferAutomaticallyCalculated ? (
                        <AutomaticOffer
                            disabled={!isAuthenticated}
                            hint={hint}
                            automaticOffer={electricityOffer}
                            checked={isElectricityOfferAccepted}
                            onClick={toggleElectricityOfferChecked}
                            title="Strom"
                            id="Strom"
                            personalContribution={personalContributionElectricity}
                        />
                    ) : (
                        <ManualOfferWaiting title="Strom" disabled={!isAuthenticated} />
                    )}
                </>
            )}
            {offer.gasOffer && (
                <>
                    {offer.isGasOfferAutomaticallyCalculated ? (
                        <AutomaticOffer
                            disabled={!isAuthenticated}
                            hint={hint}
                            automaticOffer={gasOffer}
                            checked={isGasOfferAccepted}
                            onClick={toggleGasOfferChecked}
                            title="Erdgas"
                            id="Erdgas"
                            personalContribution={personalContributionGas}
                        />
                    ) : (
                        <ManualOfferWaiting title="Erdgas" disabled={!isAuthenticated} />
                    )}
                </>
            )}
            {(isElectricityOfferAccepted || isGasOfferAccepted) && (
                <div className="w-full lg:w-10/12">
                    <div className="flex justify-between mb-2">
                        <label htmlFor="checkbox-isAGBChecked">
                            Mit dieser Bestellung akzeptiere ich die{' '}
                            <a href="/files/Nutzungsbedingungen.pdf" className="text-primary" download>
                                AGB
                            </a>
                            . Ferner erkläre ich, dass ich die{' '}
                            <a href="/files/DSI.pdf" className="text-primary" download>
                                Datenschutzinformation
                            </a>{' '}
                            zur Kenntnis genommen habe.
                        </label>
                        <Checkbox
                            name="isAGBChecked"
                            {...register('isAGBAccepted', { required: true, shouldUnregister: true })}
                        />
                    </div>
                    <div className="flex justify-between">
                        <label htmlFor="areDocumentsAcknowledged">
                            Ich habe die Vertragsunterlagen{' '}
                            {isElectricityOfferAccepted && (
                                <StyledLink
                                    onClick={() => openOfferDocument(offer.electricityOffer.processCommunicationId)}
                                >
                                    Strom
                                </StyledLink>
                            )}
                            {isElectricityOfferAccepted && isGasOfferAccepted && ' und '}
                            {isGasOfferAccepted && (
                                <StyledLink onClick={() => openOfferDocument(offer.gasOffer.processCommunicationId)}>
                                    Erdgas
                                </StyledLink>
                            )}{' '}
                            zur Kenntnis genommen.
                        </label>
                        <Checkbox
                            name="areDocumentsAcknowledged"
                            {...register('areDocumentsAcknowledged', { required: true, shouldUnregister: true })}
                        />
                    </div>
                </div>
            )}
            {concludeError && showError && <ErrorBox onClick={() => setShowError(false)} />}
        </Form>
    );
};
