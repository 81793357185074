import { PowerConsumptionDeclarations } from '../../types';

const residentialConsumptionValues = [13.05, 21.6, 27.9, 32.85, 36.45, 39.6, 42.48, 45, 47.43, 49.5];
const additionalResidentialUnitConsumptionInKw = 2.07;
const communityFacilityUnitConsumptionInKw = 6.5;
const nonMultiFamilyConsumptionBaselineInKw = 13.05;

const calculateResidentialConsumption = (numberOfUnits: number) => {
    if (numberOfUnits === 0) return 0;
    return (
        residentialConsumptionValues[Math.min(9, numberOfUnits - 1)] +
        (numberOfUnits > 10 ? additionalResidentialUnitConsumptionInKw * (numberOfUnits - 10) : 0)
    );
};

const powerConsumptionCalculation = (powerConsumptionDeclarations: PowerConsumptionDeclarations): number => {
    const sumOfElectricalServices = powerConsumptionDeclarations.electricHeater
        ? powerConsumptionDeclarations.electricService?.reduce((sum, element) => sum + element.capacity, 0)
        : 0;
    const sumOfElectricalWaterHeating = powerConsumptionDeclarations.electricWarmWater
        ? powerConsumptionDeclarations.electricalWaterHeating?.reduce((sum, element) => sum + element.capacity, 0)
        : 0;
    const sumOfChargingFields = powerConsumptionDeclarations.chargingStation
        ? powerConsumptionDeclarations.chargingInfrastructure?.reduce(
              (sum, element) => sum + parseFloat(element.capacity) * element.amount,
              0,
          )
        : 0;
    const commonSum = sumOfElectricalServices + sumOfElectricalWaterHeating + sumOfChargingFields;
    if (powerConsumptionDeclarations.building.buildingType === 'multiFamily') {
        const sumOfResidentialUnits = calculateResidentialConsumption(
            powerConsumptionDeclarations.building.residentialUnit ?? 0,
        );
        const sumOfCommunityFacilitiesUnits =
            (powerConsumptionDeclarations.building.communityFacilities ?? 0) * communityFacilityUnitConsumptionInKw;
        const sumOfCommercialUnits =
            powerConsumptionDeclarations.commercialUnits?.reduce((sum, element) => sum + element.capacity, 0) ?? 0;
        const sumOfInfrastructure =
            powerConsumptionDeclarations.infrastructure?.reduce(
                (sum, element) => sum + element.capacity * element.amount,
                0,
            ) ?? 0;
        return (
            sumOfResidentialUnits +
            sumOfCommunityFacilitiesUnits +
            sumOfCommercialUnits +
            sumOfInfrastructure +
            commonSum
        );
    }
    return nonMultiFamilyConsumptionBaselineInKw + commonSum;
};

export default (powerConsumptionDeclarations: PowerConsumptionDeclarations): number | undefined => {
    const sum = powerConsumptionCalculation(powerConsumptionDeclarations);
    return Math.round(sum);
};
