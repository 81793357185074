import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import specialBuildingOptions from '../../consumption/building-data-form/options';
import { Context } from '../../wizard';
import germanizeBoolean from './util';

export interface ConsumptionColumnProps {
    context: Partial<Context>;
}

const ConsumptionColumn = ({ context }: ConsumptionColumnProps): React.ReactElement => (
    <Column title="Leistungsbedarf">
        <ColumnSection title="Gebäudedaten">
            <ColumnItem
                label="Gebäudeart"
                text={
                    {
                        singleFamily: 'Einfamilienhaus',
                        multiFamily: 'Mehrfamilienhaus',
                        special: 'Sondergebäude',
                    }[context.building.buildingType]
                }
            />

            {context.building.buildingType === 'special' && (
                <ColumnItem
                    label="Sondergebäudeart"
                    text={
                        specialBuildingOptions.filter((option) => option.value === context.building.specialType)[0]
                            .label
                    }
                />
            )}

            {context.building.buildingType === 'multiFamily' && (
                <>
                    <ColumnItem label="Wohneinheiten" text={String(context.building.residentialUnit)} />
                    <ColumnItem label="Gewerbeeinheiten" text={String(context.building.commercialUnit)} />
                    <ColumnItem label="Gemeinschaftsanlagen" text={String(context.building.communityFacilities)} />
                </>
            )}

            <ColumnItem
                label="Neubau"
                text={`${germanizeBoolean(context.building.newBuilding)}\
                       ${
                           context.building.newBuildingName // TODO: newBuildingName should be named newDevelopmentArea
                               ? ` (Neubaugebiet: ${context.building.newBuildingName})`
                               : ''
                       }`}
            />

            {context.building.buildingType !== 'special' && (
                <ColumnItem label="Keller" text={germanizeBoolean(context.building.basement)} />
            )}
        </ColumnSection>

        {context.power && (
            <ColumnSection title="Leistungsbedarf Strom">
                <ColumnItem label="Benötigte Leistung" text={`${context.powerConsumptionInKw} kW`} />
                <ColumnItem
                    hint
                    text={`Der benötigte Bedarf wird ${context.powerConsumptionInKw > 30 ? 'nicht ' : ''}\
                    durch unsere Standardleistung bis 30 kW abgedeckt.`}
                />
            </ColumnSection>
        )}

        {context.gas && (
            <ColumnSection title="Leistungsbedarf Erdgas">
                <ColumnItem label="Benötigte Leistung" text={`${context.gasConsumptionInKw} kW`} />
                <ColumnItem
                    hint
                    text={`Der benötigte Bedarf wird ${context.gasConsumptionInKw > 30 ? 'nicht ' : ''}\
                    durch unsere Standardleistung bis 30 kW abgedeckt.`}
                />
            </ColumnSection>
        )}
    </Column>
);

export default ConsumptionColumn;
