import L from 'leaflet';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { UpdateContextFunction } from '../../../types';
import { MarkerToolType, drawMarker } from '../../../util/drawing';
import { Context } from '../../../wizard';
import { ToolType } from '../Toolbox';
import MarkerButton from './MarkerButton';
import { markerListener } from './createdLayerListener';
import { DRAW_MARKERS_STATE } from './useProcessGuide';

export type MarkerButtonBoxProperties = {
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    currentTool: ToolType;
    setCurrentTool: (tool: ToolType) => void;
    currentProcessStep: number;
    setIsMovingMarker: (value: boolean) => void;
};

const markerListenerNames = 'pm:edit pm:remove';

const addListenerToMarkerDrag = (
    markerToolType: MarkerToolType,
    marker: L.Marker,
    updateContext: UpdateContextFunction,
    setIsMovingMarker,
) => {
    marker.on('pm:dragstart', () => {
        setIsMovingMarker(true);
        switch (markerToolType) {
            case 'Electricity':
                updateContext({
                    electricityLineCourse: undefined,
                    isElectricityLineCourseValid: false,
                    powerMarker: undefined,
                });
                break;
            case 'Gas':
                updateContext({ gasLineCourse: undefined, isGasLineCourseValid: false, gasMarker: undefined });
                break;
            case 'MultiEntry':
                updateContext({
                    electricityLineCourse: undefined,
                    gasLineCourse: undefined,
                    isElectricityLineCourseValid: false,
                    isGasLineCourseValid: false,
                    multiEntryMarker: undefined,
                });
                break;
            default:
                break;
        }
    });
    marker.on('pm:dragend', () => {
        setIsMovingMarker(false);
    });
};

export default ({
    context,
    updateContext,
    currentTool,
    setCurrentTool,
    currentProcessStep,
    setIsMovingMarker,
}: MarkerButtonBoxProperties): React.ReactElement => {
    const map = useMap();

    const deleteMarker = useCallback(
        (markerToolType: MarkerToolType) => {
            switch (markerToolType) {
                case 'Electricity':
                    updateContext({
                        powerMarker: undefined,
                        isElectricityLineCourseValid: false,
                        electricityLineCourse: undefined,
                    });
                    break;
                case 'Gas':
                    updateContext({ gasMarker: undefined, isGasLineCourseValid: false, gasLineCourse: undefined });
                    break;
                case 'MultiEntry':
                    updateContext({
                        multiEntryMarker: undefined,
                        isElectricityLineCourseValid: false,
                        isGasLineCourseValid: false,
                        electricityLineCourse: undefined,
                        gasLineCourse: undefined,
                    });
                    break;
                default:
                    break;
            }
            map.pm.disableGlobalRemovalMode();
            setCurrentTool('none');
        },
        [map.pm, updateContext, setCurrentTool],
    );

    const updateMarker = useCallback(
        (markerToolType: MarkerToolType, marker: L.Marker) => {
            switch (markerToolType) {
                case 'Electricity':
                    updateContext({ powerMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                    break;
                case 'Gas':
                    updateContext({ gasMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                    break;
                case 'MultiEntry':
                    updateContext({ multiEntryMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                    break;
                default:
                    break;
            }
        },
        [updateContext],
    );

    const createMarker = useCallback(
        (event) => {
            const { shape, layer } = event;
            const markerToolType = currentTool as MarkerToolType;
            if (shape === 'Marker') {
                const marker = layer as L.Marker;
                map.pm.disableDraw();
                switch (markerToolType) {
                    case 'Electricity':
                        updateContext({ powerMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                        break;
                    case 'Gas':
                        updateContext({ gasMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                        break;
                    case 'MultiEntry':
                        updateContext({ multiEntryMarker: marker.getLatLng() }, 'CONNECTION_PLAN');
                        break;
                    default:
                        break;
                }
                marker.on(markerListenerNames, markerListener(markerToolType, marker, updateMarker, deleteMarker));
                addListenerToMarkerDrag(markerToolType, marker, updateContext, setIsMovingMarker);
            }
        },
        [currentTool, map.pm, updateMarker, deleteMarker, updateContext, setIsMovingMarker],
    );

    useEffect(() => {
        const markers: Map<MarkerToolType, L.Marker> = new Map();
        if (context.powerMarker && context.power && context.multiEntry === 'no') {
            const powerMarker = drawMarker(context.powerMarker, 'Electricity', map);
            markers.set('Electricity', powerMarker);
        }
        if (context.gasMarker && context.gas && context.multiEntry === 'no') {
            const gasMarker = drawMarker(context.gasMarker, 'Gas', map);
            markers.set('Gas', gasMarker);
        }
        if (context.multiEntryMarker && context.multiEntry === 'yes') {
            const multiEntryMarker = drawMarker(context.multiEntryMarker, 'MultiEntry', map);

            markers.set('MultiEntry', multiEntryMarker);
        }
        markers.forEach((marker, markerToolType) => {
            marker.on(markerListenerNames, markerListener(markerToolType, marker, updateMarker, deleteMarker));
            addListenerToMarkerDrag(markerToolType, marker, updateContext, setIsMovingMarker);
        });

        // this needs to be executed only once when the component is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentTool === 'Electricity' || currentTool === 'Gas' || currentTool === 'MultiEntry') {
            map.removeEventListener('pm:create');
            map.once('pm:create', createMarker);
        }
    });

    return (
        <div className="grid grid-cols-2 mb-1 gap-2 mx-2">
            {context.power && context.multiEntry === 'no' && (
                <MarkerButton
                    buttonType="Electricity"
                    context={context}
                    currentTool={currentTool}
                    setCurrentTool={setCurrentTool}
                    disabled={currentProcessStep !== DRAW_MARKERS_STATE}
                />
            )}
            {context.gas && context.multiEntry === 'no' && (
                <MarkerButton
                    buttonType="Gas"
                    context={context}
                    currentTool={currentTool}
                    setCurrentTool={setCurrentTool}
                    disabled={currentProcessStep !== DRAW_MARKERS_STATE}
                />
            )}
            {context.multiEntry === 'yes' && (
                <MarkerButton
                    buttonType="MultiEntry"
                    context={context}
                    currentTool={currentTool}
                    setCurrentTool={setCurrentTool}
                    disabled={currentProcessStep !== DRAW_MARKERS_STATE}
                />
            )}
        </div>
    );
};
