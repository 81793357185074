import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import React from 'react';

import { formatAddress, formatCadastralDistrict } from '../../util';
import { Context } from '../../wizard';

export interface ServiceColumnProps {
    context: Partial<Context>;
}

type Address = {
    label: string;
    text: string | string[];
};

const parseLocation = (address): Address => {
    const parseAddress = (asr: AddressLocation): Address => ({
        label: 'Adresse',
        text: formatAddress(asr),
    });

    const parseCadastralDistrict = (cdsr: CadastralDistrictLocation): Address => ({
        label: 'Flurstück',
        text: formatCadastralDistrict(cdsr),
    });

    const isAddress = (addressInput: AddressLocation | CadastralDistrictLocation): boolean => {
        const keys = Object.keys(addressInput);
        if (keys.includes('postcode')) return true;
        if (keys.includes('districtId')) return false;
        throw Error('invalid address object');
    };

    return isAddress(address) ? parseAddress(address) : parseCadastralDistrict(address);
};

const ServiceColumn = ({ context }: ServiceColumnProps): React.ReactElement => (
    <Column title="Service">
        <ColumnSection title="Standort des Anschlussobjekts">
            <ColumnItem {...parseLocation(context.location)} />
        </ColumnSection>
        <ColumnSection title="Serviceauswahl">
            <ColumnItem
                label="Service"
                text={
                    {
                        newConnection: 'Neuanschluss',
                        adaptConnection: 'Anschlussänderung',
                        dismantleConnection: 'Rückbau',
                    }[context.serviceType]
                }
            />
            <ColumnItem
                label="Sparte"
                text={[context.power ? 'Strom' : null, context.gas ? 'Erdgas' : null].filter((n) => n).join(', ')}
            />
        </ColumnSection>
    </Column>
);

export default ServiceColumn;
