import { FormItem, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import FormLayout from '../../../components/SignupForm/FormLayout';
import { GasConsumptionUnknownData } from '../../../types';
import { Context } from '../../../wizard';
import GasOutletCounter from './GasOutletCounter';

interface GasConsumptionUnknownOutletProps {
    context: Partial<Context>;
    syncContext: () => void;
}

const GasConsumptionUnknownOutlet = ({
    context,
    syncContext,
}: GasConsumptionUnknownOutletProps): React.ReactElement => {
    const reactHookForm = useFormContext<GasConsumptionUnknownData>();
    const { watch, register, reset } = reactHookForm;
    const gasOutlet = watch('gasOutlet');

    const toggleGasOutletAndResetFormDefaultValues = (event: React.ChangeEvent<HTMLInputElement>) => {
        const swapTo = event.target.value;

        if (swapTo === 'no') {
            reset((values) => ({ ...values }));
        }

        syncContext();
    };

    return (
        <FormLayout>
            <FormItem
                label={`${
                    context.userType === 'installer' || context.userType === 'projectpartner'
                        ? 'Wird Ihr Kunde'
                        : 'Werden Sie'
                } eine Erdgassteckdose haben? (z.B. für Terrassenstrahler, Gasgrill, Gasherd)`}
                className="flex items-center h-auto lg:h-16"
            >
                <div className="flex md:flex-row flex-col gap-y-2 md:gap-y-0" style={{ width: '300px' }}>
                    <div style={{ width: '125px' }}>
                        <RadioButton
                            name="gasOutlet"
                            id="gasOutlet-yes"
                            value="yes"
                            {...register('gasOutlet', {
                                shouldUnregister: true,
                                onChange: toggleGasOutletAndResetFormDefaultValues,
                            })}
                        >
                            ja
                        </RadioButton>
                    </div>
                    <div style={{ width: '125px' }}>
                        <RadioButton
                            name="gasOutlet"
                            id="gasOutlet-no"
                            value="no"
                            {...register('gasOutlet', {
                                shouldUnregister: true,
                                onChange: toggleGasOutletAndResetFormDefaultValues,
                            })}
                        >
                            nein
                        </RadioButton>
                    </div>
                </div>
                <div>
                    {gasOutlet === 'yes' && <GasOutletCounter userType={context.userType} syncContext={syncContext} />}
                </div>
            </FormItem>
        </FormLayout>
    );
};

export default GasConsumptionUnknownOutlet;
