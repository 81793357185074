import { AxiosResponse } from 'axios';

import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';

export type GetOfferDocumentParameter = {
    connectionRequestId: string;
    processCommunicationId: string;
};

export const getOfferDocument = async ({
    params,
    customerId,
    axios,
}: ApiParams<GetOfferDocumentParameter>): Promise<Blob> => {
    const { connectionRequestId, processCommunicationId } = params;

    const response: AxiosResponse = await axios.get(
        `${appConfig.services.connectionRequestFormApi}/customer/${customerId}/connection-request` +
            `/${connectionRequestId}/offer/${processCommunicationId}/document`,
        {
            responseType: 'blob',
        },
    );

    if (response.status === 200) {
        return response.data;
    }
    throw new Error('Failed get offer document for connection requests');
};
