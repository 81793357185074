import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';
import L from 'leaflet';

import { isAddress } from './getCadastralDistrictLatLngArrayFromContextLocation';

const mapLocationToGeoCoordinates = (location: AddressLocation | CadastralDistrictLocation) => {
    if (isAddress(location)) {
        return [L.latLng(location.geojson.coordinates[1], location.geojson.coordinates[0])];
    }
    const cadastralDistrict = location.geojson.coordinates[0];
    return cadastralDistrict.map((coordinates) => L.latLng(coordinates[1], coordinates[0]));
};

export default mapLocationToGeoCoordinates;
