import { createProcessGuidanceButtons } from '@ten-netzkundenportal/ui-components';
import {
    InstallerFilterResultWithDecodedCompanyName,
    InstallerSelectionForm,
} from '@ten-netzkundenportal/ui-installer-selection';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import useApi from '../../../hooks/useApi';
import { DASHBOARD } from '../../../routes';
import { MappedQuerySelectedOfferResponse } from '../../types';
import updateInstaller from './api/updateInstaller';

type OfferRevisitedInstallerSelectionProps = {
    mappedData: MappedQuerySelectedOfferResponse;
};

const OfferRevisitedInstallerSelection = ({
    mappedData,
}: OfferRevisitedInstallerSelectionProps): React.ReactElement => {
    const [chosenInstallers, setChosenInstallers] = useState<InstallerFilterResultWithDecodedCompanyName[]>([]);
    const { connectionRequestId } = useParams();

    const addInstallerToConnectionRequestApi = useApi(updateInstaller);

    const installerSelectionDescription =
        'Für die Umsetzung der Dienstleistung ist es erforderlich, einen Installateur einzubinden. Bitte ' +
        'wählen Sie dafür den von Ihnen beauftragten Installateur aus.';

    const onBack = () => {
        window.location.href = DASHBOARD;
    };

    const onNext = async () => {
        if (chosenInstallers.length !== 1) {
            console.log('Exactly one installer should be selected.');
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { companyNameDecoded, ...installer } = chosenInstallers[0];
        try {
            await addInstallerToConnectionRequestApi({
                connectionRequestId,
                installer,
                branch: mappedData.branch,
            });
        } catch (error) {
            console.log(error.message);
            return;
        }

        window.history.pushState({}, document.title, DASHBOARD);
    };

    const buttons = createProcessGuidanceButtons({
        onBack,
        onNext,
        isNextValid: chosenInstallers.length > 0,
        nextLabel: 'Speichern',
        prevLabel: 'Abbrechen',
    });
    return mappedData ? (
        <InstallerSelectionForm
            buttons={buttons}
            chosenInstallers={chosenInstallers}
            setChosenInstallers={setChosenInstallers}
            electricity={mappedData?.branch === 'electricity'}
            gas={mappedData?.branch === 'gas'}
            texts={{ description: installerSelectionDescription, hint: undefined }}
            isGasModifiable={false}
            isElectricityModifiable={false}
            installerId={mappedData.installerId}
        />
    ) : (
        <div data-testid="loading" />
    );
};

export default OfferRevisitedInstallerSelection;
