import L, { ZoomAnimEvent } from 'leaflet';
import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { useMap } from 'react-leaflet';

import {
    calculateLineAngleRelativeToNorth,
    calculatePixelHeightInMeters,
    calculatePixelWidthInMeters,
    distanceBetweenTwoCoordinatesInMeters,
    setLengthLabelPositionAndAngle,
} from './lengthsUtil';

type LengthLabelProps = {
    a: L.LatLng;
    b: L.LatLng;
};

const isLabelLongerThanItsEdge = (
    ref: React.MutableRefObject<HTMLDivElement>,
    distanceRef: React.MutableRefObject<number>,
    map: L.Map,
) => {
    let ret = true;
    if (ref.current) {
        const labelWidthMeters = ref.current.offsetWidth * calculatePixelWidthInMeters(map);
        const labelHeightMeters = ref.current.offsetHeight * calculatePixelHeightInMeters(map);
        const labelLength = Math.sqrt(labelWidthMeters ** 2 + labelHeightMeters ** 2);
        ret = labelLength > distanceRef.current;
    }
    return ret;
};

export default ({ a, b }: LengthLabelProps) => {
    const map = useMap();
    const distance = distanceBetweenTwoCoordinatesInMeters(a, b);
    const middle = L.PM.Utils.calcMiddleLatLng(map, a, b);
    const layerPoint = map.latLngToLayerPoint(middle);
    const angle = calculateLineAngleRelativeToNorth(a, b);
    const distanceRef = useRef<number>(distance);
    const middleRef = useRef<L.LatLng>(middle);
    const angleRef = useRef<number>(angle);
    distanceRef.current = distance;
    middleRef.current = middle;
    angleRef.current = angle;

    const ref = useRef<HTMLDivElement>();
    setLengthLabelPositionAndAngle(ref, layerPoint.x, layerPoint.y, angle);
    const [hidden, setHidden] = useState(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setHidden(isLabelLongerThanItsEdge(ref, distanceRef, map));
    });

    const zoomAnimation = useCallback(
        (e: ZoomAnimEvent) => {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            const newPosition = map['_latLngToNewLayerPoint'](middleRef.current, e.zoom, e.center);
            setLengthLabelPositionAndAngle(ref, newPosition.x, newPosition.y, angleRef.current);
        },
        [map],
    );

    useEffect(() => {
        map.on('zoomanim', zoomAnimation);
        map.on('zoom', () => setHidden(isLabelLongerThanItsEdge(ref, distanceRef, map)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            ref={ref}
            className={`border border-dark-blue bg-white rounded-md absolute px-[3px] pb-[2px] text-[13px] 
            pointer-events-none leading-none opacity-90 text-dark-blue leaflet-zoom-animated${
                hidden ? ' invisible' : ''
            }`}
            style={{ transformOrigin: '50% 50% 0' }}
            data-testid="length-label"
        >
            {`${distance.toLocaleString('de', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}`}&nbsp;m
        </div>
    );
};
