import axios, { AxiosError, AxiosResponse, Cancel, GenericAbortSignal } from 'axios';
import L from 'leaflet';

import appConfig from '../../../app.config';
import { MapElements } from '../../../types';
import { Branch } from '../../../types/branch';
import { LineCourseResponse } from './LineCourseResponse';

type Error = {
    error?: {
        erroneousCourseLineTypes: Branch[];
    };
};

type requestCanceled = {
    requestCanceled?: boolean;
};

type LineCourseResponseOrErrorOrCanceled = LineCourseResponse & Error & requestCanceled;

type LineCourseError = { data: { detail: { erroneousCourseLineTypes: Branch[] } }; status: number };

const lineCourseRequest = async (
    elements: MapElements,
    cadastralDistrictPolygon: L.LatLng[],
    electricity: boolean,
    gas: boolean,
    signal?: GenericAbortSignal,
): Promise<LineCourseResponseOrErrorOrCanceled> => {
    let electricityEntryCoordinates = elements.powerMarker;
    let gasEntryCoordinates = elements.gasMarker;
    if (electricity && elements.multiEntryMarker) {
        electricityEntryCoordinates = elements.multiEntryMarker;
    }
    if (gas && elements.multiEntryMarker) {
        gasEntryCoordinates = elements.multiEntryMarker;
    }

    const response: AxiosResponse<LineCourseResponse> | LineCourseError | Cancel = await axios
        .post<LineCourseResponse>(
            `${appConfig.services.connectionRequestFormApi}/connection-request/connection/line-course`,
            {
                isMultiEntry: !!elements.multiEntryMarker,
                electricityEntryCoordinates,
                electricityLineCourse: elements.electricityLineCourse,
                gasEntryCoordinates,
                gasLineCourse: elements.gasLineCourse,
                buildings: elements.houseShapes.map((houseShape) => houseShape.latLngs),
                cadastralDistrictPolygon,
            },
            {
                signal,
            },
        )
        .catch((e: AxiosError | Cancel) => {
            if (axios.isCancel(e)) {
                return e;
            }
            return (e as AxiosError).response;
        });

    if (axios.isCancel(response)) {
        return { requestCanceled: true };
    }
    const responseOrError = response as LineCourseError | AxiosResponse<LineCourseResponse>;
    if (responseOrError.status === 200) {
        return responseOrError.data as LineCourseResponse;
    }
    if (responseOrError.status === 422) {
        return {
            error: {
                erroneousCourseLineTypes: (responseOrError as LineCourseError).data.detail.erroneousCourseLineTypes,
            },
        };
    }

    throw new Error('Failed to retrieve a line course for defined entry points.');
};

export default lineCourseRequest;
