export default (
    value: number | string,
    validationErrorMessage: string,
    leadingZeroErrorMessage: string,
): undefined | string => {
    let valueAsNumber: number;
    // this can happen when spaces are entered inside the input field
    if (typeof value === 'string') {
        const valueIsString = value as string;
        const valueWithoutSpaces = valueIsString.replace(/\s/g, '');
        if (valueWithoutSpaces[0] === '0') {
            return leadingZeroErrorMessage;
        }
        valueAsNumber = +valueWithoutSpaces;
    } else {
        valueAsNumber = value;
    }
    return valueAsNumber >= 10000 ? undefined : validationErrorMessage;
};
