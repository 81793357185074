import { useMsal } from '@azure/msal-react';
import { AxiosInstance } from 'axios';
import * as React from 'react';
import { useEffect } from 'react';

import useAuthenticatedHttpClient from '../../hooks/useAuthenticatedHttpClient';
import { APIRequestState, UpdateContextFunction } from '../../types';
import { Context } from '../../wizard';
import contextTransformer from './ContextTransformer';
import { calculateOfferForAuthenticatedUser, calculateOfferForUnauthenticatedUser } from './OfferCalculationAPI';
import { ConnectionRequest, OfferResponse } from './types';

const calculateOffer = async (
    axios: AxiosInstance,
    connectionRequest: ConnectionRequest,
    connectionRequestId?: string,
    customerId?: string,
) => {
    if (customerId) {
        return calculateOfferForAuthenticatedUser(connectionRequest, customerId, axios, connectionRequestId);
    }
    return calculateOfferForUnauthenticatedUser(connectionRequest, connectionRequestId);
};

export default (
    context: Partial<Context>,
    updateContext: UpdateContextFunction,
): [OfferResponse, APIRequestState, boolean] => {
    const [offer, setOffer] = React.useState<OfferResponse>();
    const [requestState, setRequestState] = React.useState<APIRequestState>('initial');
    const [isLoading, setIsLoading] = React.useState(true);

    const { accounts } = useMsal();
    const axios = useAuthenticatedHttpClient();
    const {
        meta: { connectionRequestId },
        ...nonMetaContext
    } = context;

    useEffect(() => {
        let canceled = false;
        const connectionRequest: ConnectionRequest = contextTransformer(nonMetaContext);
        const calculateOfferAndSet = async (): Promise<void> => {
            const account = accounts?.[0];
            setRequestState('loading');
            const response = await calculateOffer(
                axios,
                connectionRequest,
                connectionRequestId,
                account?.localAccountId,
            );
            setIsLoading(false);
            if (canceled) {
                return;
            }

            setOffer(response.offer);
            updateContext({
                meta: {
                    isAutomaticElectricityOfferPossible: response.offer.isElectricityOfferAutomaticallyCalculated,
                    ...(response.offer.isElectricityOfferAutomaticallyCalculated !== undefined && {
                        electricityOffer: {
                            processCommunicationId: response.offer.electricityOffer.processCommunicationId,
                        },
                    }),
                    isAutomaticGasOfferPossible: response.offer.isGasOfferAutomaticallyCalculated,
                    ...(response.offer.isGasOfferAutomaticallyCalculated !== undefined && {
                        gasOffer: {
                            processCommunicationId: response.offer.gasOffer.processCommunicationId,
                        },
                    }),
                    connectionRequestId: response.connectionRequestId,
                },
            });
            setRequestState('finished_successfully');
        };
        calculateOfferAndSet().catch((e) => {
            console.log('Failed to calculate offer!', e);
            setOffer(undefined);
            setRequestState('error');
        });
        return () => {
            canceled = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectionRequestId, JSON.stringify(nonMetaContext), updateContext]);

    return [offer, requestState, isLoading];
};
