import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';

type UploadAdditionalDocumentsParams = {
    connectionRequestId: string;
    processCommunicationId: string;
    folderName: string;
    privacyPolicyAccepted: boolean;
};

const serviceUrl = appConfig.services.connectionRequestFormApi;

export const uploadAdditionalDocuments = async ({
    axios,
    params,
    customerId,
}: ApiParams<UploadAdditionalDocumentsParams>): Promise<void> => {
    await axios.post(
        `${serviceUrl}/account/${customerId}/connection-request/${params.connectionRequestId}/offer/${params.processCommunicationId}/document`,
        {
            folderName: params.folderName,
            privacyPolicyAccepted: params.privacyPolicyAccepted,
        },
    );
};
