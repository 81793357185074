import logo from '@ten-netzkundenportal/ui-components/dist/ten_logo_rgb_rz.jpg';
import * as React from 'react';

export type ManualOfferWaitingProps = {
    title: string;
    disabled?: boolean;
};

export default ({ title, disabled = false }: ManualOfferWaitingProps): React.ReactElement => (
    <div className={`w-full h-full ${disabled ? 'opacity-50' : ''}`}>
        <div className="shadow-ten">
            <div className="w-full h-full flex p-5 flex-col gap-4">
                <div className="grid absolute" style={{ gridTemplateColumns: '1fr 1fr' }}>
                    <img src={logo} alt="Logo Thüringer Energienetze" width="125px" />
                </div>
                <h3 className="text-medium font-bold pb-4 text-center">Netzanschlussvertrag - {title}</h3>
                <div className="text-center pt-4 px-12 lg:px-32 xl:px-64">
                    Für ein optimales Angebot benötigen wir noch etwas Zeit. Unser Angebot für Sie wird individuell von
                    unseren regionalen Ansprechpartnern erstellt und Ihnen zeitnah über unser Netzkundenportal zur
                    Verfügung gestellt.
                </div>
                <div className="text-center">
                    Sie erhalten eine Benachrichtigung, sobald unser Angebot für Sie verfügbar ist.
                </div>
                <div className="text-center pb-3">Vielen Dank für Ihre Geduld!</div>
            </div>
        </div>
    </div>
);
