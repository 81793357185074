import { CheckThick } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

export default (): React.ReactElement => (
    <div className="flex flex-col items-center mt-20 justify-center">
        <div className="w-3/4 mx-auto">
            <p className="text-center font-bold ">
                Ihre Registrierung wurde erfolgreich abgeschlossen. Sie erhalten zeitnah eine E-Mail zur Aktivierung
                Ihres Netzkundenportal-Accounts.
            </p>
            <p className="text-center font-bold pb-8">Bitte bestätigen Sie den in der E-Mail enthaltenen Link.</p>
            <p className="text-center pb-8">
                Hinweis: Dieser Vorgang kann je nach E-Mail-Dienstanbieter einige Minuten dauern.
            </p>
        </div>
        <div className="fill-current text-primary flex overflow-hidden justify-center gap-8 mt-6">
            <div className="w-10 place-items-center">
                <CheckThick />
            </div>
        </div>
    </div>
);
