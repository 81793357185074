import { LinkButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

import { LOCATION_DATA } from '../routes';
import { ElectricityAndGasAvailability } from '../types';
import readConnectionRequestFromLocalstorage from '../util/readConnectionRequestFromLocalstorage';
import NotResponsibleText from './NotResponsibleText';
import PortalNotResponsibleText from './PortalNotResponsibleText';

const NotResponsible = (): React.ReactElement => {
    const locationAvailability: ElectricityAndGasAvailability =
        readConnectionRequestFromLocalstorage('meta.locationAvailability');

    const operatingSupplyAtLeastOnce = () =>
        locationAvailability.electricity.responsible === 'OperatingSupply' ||
        locationAvailability.gas.responsible === 'OperatingSupply';

    return (
        <div className="pt-20 w-full">
            <div className="flex flex-col items-center">
                <div className="shadow-ten">
                    <div className="my-8 mx-16 flex flex-col items-center gap-12">
                        <h1 className="text-medium font-bold border-primary border-b-2 pb-3 px-12">Zuständigkeit</h1>
                        <div className="text-center flex flex-col gap-4 text-base">
                            {operatingSupplyAtLeastOnce() ? (
                                <PortalNotResponsibleText availability={locationAvailability} />
                            ) : (
                                <NotResponsibleText />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-24">
                <LinkButton label="Zurück" href={LOCATION_DATA} type="secondary" />
            </div>
        </div>
    );
};

export default NotResponsible;
