import { Dropdown, ErrorBox, Form } from '@ten-netzkundenportal/ui-components';
import L from 'leaflet';
import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { TwoButton, createPrevNextButton } from '../../components';
import MapComponent from '../../components/map/MapComponent';
import { PropertyCrossing, UpdateContextFunction } from '../../types';
import getCadastralDistrictLatLngArrayFromContextLocation from '../../util/getCadastralDistrictLatLngArrayFromContextLocation';
import { Context, useContextUpdate } from '../../wizard';
import Toolbox from './Toolbox';
import LengthsPane from './additionalInformationLayer/LengthsPane';
import LineCourses from './buildingAndConnectionPlan/LineCourses';
import ProcessGuide from './buildingAndConnectionPlan/ProcessGuide';
import useProcessGuide from './buildingAndConnectionPlan/useProcessGuide';

export type ConnectionPlanFormProperties = {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
};

export default ({ onSubmit, goBack, updateContext, context }: ConnectionPlanFormProperties): React.ReactElement => {
    const {
        register,
        watch,
        handleSubmit,
        formState: { isValid },
    } = useForm<PropertyCrossing>({
        mode: 'onChange',
        shouldUnregister: true,
        defaultValues: {
            propertyCrossing: context.propertyCrossing,
        },
    });

    const [planError, setPlanError] = React.useState<boolean>(false);
    const [showError, setShowError] = React.useState<boolean>(false);
    const [isMovingMarker, setIsMovingMarker] = React.useState<boolean>(false);

    const [map, setMap] = React.useState<L.Map>(null);

    useEffect(() => {
        if (planError) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [planError]);

    const cadastralDistrictLatLng = React.useMemo(
        () => getCadastralDistrictLatLngArrayFromContextLocation(context.location),
        [context.location],
    );

    const processGuide = useProcessGuide(context, updateContext);

    const getButtonHint = () => {
        if (!processGuide.isAtEnd) return 'Bitte schließen Sie zuerst Ihre Planungsskizze ab.';
        if (processGuide.isAtEnd && !isValid) return 'Bitte beantworten Sie die Überquerungsfrage.';
        return 'Weiter';
    };

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        nextTitle: getButtonHint(),
        onNext: handleSubmit(onSubmit),
        isNextValid:
            isValid &&
            processGuide.isAtEnd &&
            (!context.gas ||
                (context.gasConnectionLengthCadastralDistrictToBuilding &&
                    context.gasConnectionLengthMainLineToCadastralDistrict)) &&
            (!context.power || !!context.electricityConnectionLength),
    });

    useContextUpdate(watch, updateContext, 'CONNECTION_PLAN');

    React.useEffect(() => {
        if (cadastralDistrictLatLng && map) {
            map.setMinZoom(map.getZoom());
            map.setMaxBounds(map.getBounds().pad(0.01));
            const bounds = map.getBounds();

            const southEast = bounds.getSouthEast();
            const northWest = bounds.getNorthWest();

            updateContext({
                boundingBox: {
                    southEast: {
                        lat: southEast.lat,
                        lng: southEast.lng,
                    },
                    northWest: {
                        lat: northWest.lat,
                        lng: northWest.lng,
                    },
                },
            });
        }
    }, [cadastralDistrictLatLng, map, updateContext]);

    return (
        <Form title="Anschluss einzeichnen">
            <div className="flex flex-col gap-y-4">
                <span className="">
                    {context.userType === 'installer' || context.userType === 'projectpartner'
                        ? 'Um die Leitungslänge für das Angebot kalkulieren zu können, ' +
                          'benötigen wir von Ihnen eine ' +
                          'Planungsskizze des Anschlussobjekts und des geplanten Anschlusses (bzw. der Anschlüsse).'
                        : 'Um die Leitungslänge für Ihr Angebot kalkulieren zu können, ' +
                          'benötigen wir von Ihnen eine Planungsskizze ' +
                          'Ihres Anschlussobjekts und des geplanten Anschlusses (bzw. der Anschlüsse).'}
                    <br />
                    Dies geht in 3 einfachen Schritten: Markieren Sie bitte Ihr Anschlussobjekt, dann setzen Sie ein
                    oder mehrere Anschlusspunkte und prüfen abschließend den vorgeschlagenen Leitungsverlauf und passen
                    diesen bei Bedarf an. Hinweis: Bitte beachten Sie, dass die Leitung zukünftig nicht überbaut werden
                    darf.
                </span>

                <MapComponent
                    isPowerSelected={context.power}
                    isGasSelected={context.gas}
                    cadastralDistrictLatLng={cadastralDistrictLatLng}
                    map={map}
                    setMap={setMap}
                    currentProcessStep={processGuide.currentProcessStep}
                    takeFullWidth
                >
                    <Toolbox
                        context={context}
                        updateContext={updateContext}
                        toggleShowProcessGuide={processGuide.toggleShowProcessGuide}
                        currentProcessStep={processGuide.currentProcessStep}
                        setIsMovingMarker={setIsMovingMarker}
                    />
                    <LineCourses
                        context={context}
                        updateContext={updateContext}
                        setLoading={processGuide.setLoading}
                        setPlanError={setPlanError}
                        isMovingMarker={isMovingMarker}
                    />
                    <LengthsPane
                        houseShapes={context.houseShapes}
                        electricityLineCourse={context.electricityLineCourse}
                        gasLineCourse={context.gasLineCourse}
                    />
                    {processGuide.showProcessGuide && <ProcessGuide {...processGuide} />}
                </MapComponent>
            </div>
            <div className="flex w-1/2 items-center">
                <Dropdown
                    options={[
                        {
                            value: 'no',
                            label: 'Nein',
                        },
                        context.power && {
                            value: 'power',
                            label: 'Ja, für die Stromleitung',
                        },
                        context.gas && {
                            value: 'gas',
                            label: 'Ja, für die Erdgasleitung',
                        },
                        context.gas &&
                            context.power && {
                                value: 'powerAndGas',
                                label: 'Ja, für die Strom- und Erdgasleitung',
                            },
                    ].filter((option) => option && option.value)}
                    name="propertyCrossing"
                    dropdownClassname="w-1/3"
                    labelClassname="w-2/3"
                    label="Müssen fremde Grundstücke für den Anschluss an die Hauptleitung überquert werden
                        (ausgenommen öffentlicher Raum)?"
                    {...register('propertyCrossing', { required: true })}
                />
            </div>
            {planError && showError && <ErrorBox onClick={() => setShowError(false)} />}
            <TwoButton leftButton={prevButton} rightButton={nextButton} />
        </Form>
    );
};
