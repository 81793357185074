import { PowerConsumptionDeclarations } from '../types';

const getComparablePowerConsumptionDeclarations = (context): PowerConsumptionDeclarations => ({
    electricHeater: context.electricHeater,
    electricService: context.electricService,
    electricWarmWater: context.electricWarmWater,
    electricalWaterHeating: context.electricalWaterHeating,
    extractionPlant: context.extractionPlant,
    extractionPlantLessThan30: context.extractionPlantLessThan30,
    chargingStation: context.chargingStation,
    chargingInfrastructure: context.chargingInfrastructure,
    infrastructure: context.infrastructure,
    commercialUnits: context.commercialUnits,
    building: context.building,
});

export default getComparablePowerConsumptionDeclarations;
