import { Dropdown, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AddRemoveButtonGroup } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import useMultiField from '../../../components/useMultiField';
import { PowerConsumptionUnknownProperties, UserType } from '../../../types';
import RowElement from './RowWrapperElement';

export type ChargingFieldsProps = {
    reactHookForm: UseFormReturn<PowerConsumptionUnknownProperties>;
    userType: UserType;
};

export function getDefaultValue(): { capacity: '11' | '3.6' | '22'; amount: number } {
    return {
        capacity: '11',
        amount: 1,
    };
}

const chargingOptions = [
    {
        value: '3.6',
        label: '3,6 kW (durchschnittliche Ladezeit 12 h)',
    },
    {
        value: '11',
        label: '11 kW (durchschnittliche Ladezeit 8 h)',
    },
    {
        value: '22',
        label: '22 kW (durchschnittliche Ladezeit  5 h)',
    },
];

export default ({ reactHookForm, userType }: ChargingFieldsProps): React.ReactElement => {
    const {
        control,
        register,
        formState: { errors, touchedFields },
    } = reactHookForm;

    const [fields, append, remove] = useMultiField({
        control,
        name: 'chargingInfrastructure',
        defaultValue: getDefaultValue(),
    });

    return (
        <div className="mt-5 sm:mt-0">
            <div className="grid grid-flow-col items-center">
                <span className="font-bold">Ladetechnik</span>
            </div>
            <FormLayout>
                <FormItem
                    label={`Welche Ladetechnik ${
                        userType === 'installer' || userType === 'projectpartner' ? 'wird Ihr Kunde' : 'werden Sie'
                    } haben?`}
                    className="items-start"
                >
                    <div className="flex flex-col gap-y-3">
                        {fields.map((field, index) => (
                            <RowElement
                                key={field.id}
                                index={index}
                                firstColumnLabel="Leistung"
                                firstColumnContent={
                                    <Dropdown
                                        name={`chargingInfrastructure.${index}.capacity`}
                                        options={chargingOptions}
                                        dropdownClassname="w-full"
                                        {...register(`chargingInfrastructure.${index}.capacity` as const, {
                                            required: true,
                                            shouldUnregister: true,
                                        })}
                                    />
                                }
                                secondColumnLabel="Anzahl"
                                secondColumnContent={
                                    <div className="grid grid-flow-col gap-x-2">
                                        <NumberInput
                                            name={`chargingInfrastructure.${index}.amount`}
                                            {...NumberInputFunctions.registerNumberInput(
                                                reactHookForm,
                                                `chargingInfrastructure.${index}.amount`,
                                                {
                                                    required: true,
                                                    shouldUnregister: true,
                                                    min: 1,
                                                    max: 999,
                                                },
                                            )}
                                            displayErrorMessage={
                                                touchedFields?.chargingInfrastructure?.[index.toString()]?.amount &&
                                                errors?.chargingInfrastructure?.[index.toString()]?.amount &&
                                                'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            }
                                            fluidWidth
                                        />
                                        {
                                            // TODO: find a way to remove ghost span while keeping the same width
                                        }
                                        <span className="opacity-0">kW</span>
                                    </div>
                                }
                                buttonGroup={
                                    <AddRemoveButtonGroup
                                        group="chargingInfrastructure"
                                        handleAppend={append}
                                        handleDelete={remove(index)}
                                        fields={fields}
                                        index={index}
                                    />
                                }
                            />
                        ))}
                    </div>
                </FormItem>
            </FormLayout>
        </div>
    );
};
