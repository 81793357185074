/* eslint-disable react/jsx-no-useless-fragment */
import L from 'leaflet';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { UpdateContextFunction } from '../../../types';
import { electricityPolylineColor, gasPolylineColor, polyLineDesign } from '../../../util/drawing';
import getCadastralDistrictLatLngArrayFromContextLocation from '../../../util/getCadastralDistrictLatLngArrayFromContextLocation';
import { Context } from '../../../wizard';
import lineCourseRequest from '../api/LineCourseRequestAPI';
import { LineCourseResponse } from '../api/LineCourseResponse';

export type LineCoursesProperties = {
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    setLoading: (boolean) => void;
    setPlanError: (boolean) => void;
    isMovingMarker: boolean;
};

const trackChangesOfLineCourse = (
    lineCoursePolyLine: L.Polyline,
    key: 'electricityLineCourse' | 'gasLineCourse',
    updateContext: UpdateContextFunction,
    updateLineCourses: () => void,
) => {
    lineCoursePolyLine.on('pm:markerdragend pm:vertexremoved pm:markerdrag', (event) => {
        updateContext({ [key]: lineCoursePolyLine.getLatLngs() });
        if (event.type === 'pm:markerdragend' || event.type === 'pm:vertexremoved') {
            updateLineCourses();
        }
    });
};

const hideStartVertexOfLineCourses = (lineCoursePolylines: L.Polyline[]) => {
    const dots = document.querySelectorAll(
        '.leaflet-marker-icon.marker-icon.leaflet-zoom-animated.leaflet-interactive.leaflet-marker-draggable',
    );
    lineCoursePolylines.forEach((lineCoursePolyLine: L.Polyline) => {
        dots.forEach((dot: HTMLElement) => {
            if (!lineCoursePolyLine || lineCoursePolyLine.isEmpty()) {
                return;
            }
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
            const { x, y } = (dot as any)._leaflet_pos;
            const point = L.point(x, y);
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-explicit-any
            const rings = (lineCoursePolyLine as any)._rings[0];
            if (rings[0].equals(point)) {
                // eslint-disable-next-line no-param-reassign
                dot.style.display = 'none';
            }
        });
    });
};

const setLineCoursePolyLineOptions = (lineCoursePolyline: L.Polyline) => {
    lineCoursePolyline.pm.disableLayerDrag();
    lineCoursePolyline.pm.setOptions({
        allowRemoval: false,
        allowRotation: false,
        moveVertexValidation: ({ layer, marker }: { layer: L.Layer; marker: L.Marker }) => {
            const polyline = layer as L.Polyline;
            return !(polyline.getLatLngs()[0] === marker.getLatLng());
        },
        removeVertexValidation: ({ layer, marker }: { layer: L.Layer; marker: L.Marker }) => {
            const polyline = layer as L.Polyline;
            return !(
                polyline.getLatLngs()[0] === marker.getLatLng() ||
                polyline.getLatLngs()[polyline.getLatLngs().length - 1] === marker.getLatLng()
            );
        },
    });
};

export default ({
    context,
    updateContext,
    setLoading,
    setPlanError,
    isMovingMarker,
}: LineCoursesProperties): React.ReactElement => {
    const map = useMap();

    const [electricityLineCoursePolyLine, setElectricityLineCoursePolyLine] = React.useState<L.Polyline>(
        L.polyline(context.electricityLineCourse ?? [], {
            ...polyLineDesign,
            color: electricityPolylineColor,
        }),
    );
    const [gasLineCoursePolyLine, setGasLineCoursePolyLine] = React.useState<L.Polyline>(
        L.polyline(context.gasLineCourse ?? [], {
            ...polyLineDesign,
            color: gasPolylineColor,
        }),
    );

    const [abortController, setAbortController] = useState<AbortController>();

    const [updateCounter, setUpdateCounter] = useState(0);
    const update = () => setUpdateCounter((prev) => prev + 1);

    const fetchLineCourses = async () => {
        if (abortController) {
            abortController.abort();
        }

        const newAbortController = new AbortController();
        setAbortController(newAbortController);

        setLoading(true);
        setPlanError(false);
        let responseOrErrorOrCanceled;
        try {
            responseOrErrorOrCanceled = await lineCourseRequest(
                {
                    powerMarker: context.powerMarker,
                    gasMarker: context.gasMarker,
                    multiEntryMarker: context.multiEntryMarker,
                    houseShapes: context.houseShapes,
                    electricityLineCourse: context.electricityLineCourse,
                    gasLineCourse: context.gasLineCourse,
                },
                getCadastralDistrictLatLngArrayFromContextLocation(context.location),
                context.power,
                context.gas,
                newAbortController.signal,
            );

            if (responseOrErrorOrCanceled.requestCanceled) {
                return;
            }

            if (responseOrErrorOrCanceled.error) {
                if (responseOrErrorOrCanceled.error.erroneousCourseLineTypes.includes('electricity')) {
                    updateContext({ isElectricityLineCourseValid: false });
                } else {
                    updateContext({ isElectricityLineCourseValid: true });
                }
                if (responseOrErrorOrCanceled.error.erroneousCourseLineTypes.includes('gas')) {
                    updateContext({ isGasLineCourseValid: false });
                } else {
                    updateContext({ isGasLineCourseValid: true });
                }
                return;
            }

            const response = responseOrErrorOrCanceled as LineCourseResponse;
            if (response.electricityLineCourse && context.power) {
                setElectricityLineCoursePolyLine(
                    electricityLineCoursePolyLine.setLatLngs(response.electricityLineCourse),
                );
                updateContext({
                    electricityLineCourse: response.electricityLineCourse,
                    isElectricityLineCourseValid: true,
                    electricityConnectionLength: response.electricityLineLength,
                });
            }
            if (response.gasLineCourse && context.gas) {
                setGasLineCoursePolyLine(gasLineCoursePolyLine.setLatLngs(response.gasLineCourse));
                updateContext({
                    gasLineCourse: response.gasLineCourse,
                    isGasLineCourseValid: true,
                    gasConnectionLengthMainLineToCadastralDistrict: response.gasLineLengthMainLineToCadastralDistrict,
                    gasConnectionLengthCadastralDistrictToBuilding: response.gasLineLengthCadastralDistrictToBuilding,
                });
            }

            // Workaround in order to prevent wrong edit markers remaining in the map
            if (map.pm.globalEditModeEnabled()) {
                map.pm.toggleGlobalEditMode();
                map.pm.toggleGlobalEditMode();
            }
            setLoading(false);
        } catch (error) {
            setPlanError(true);
        }
    };

    useEffect(() => {
        if (context.electricityLineCourse === undefined) {
            setElectricityLineCoursePolyLine(electricityLineCoursePolyLine.setLatLngs([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.electricityLineCourse]);
    useEffect(() => {
        if (context.gasLineCourse === undefined) {
            setGasLineCoursePolyLine(gasLineCoursePolyLine.setLatLngs([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.gasLineCourse]);

    useEffect(() => {
        if (
            !isMovingMarker &&
            ((context.multiEntry === 'no' &&
                ((context.gas && (!context.gasMarker || !context.isGasLineCourseValid)) ||
                    (context.power && !(context.powerMarker && context.isElectricityLineCourseValid)))) ||
                (context.multiEntry === 'yes' &&
                    context.multiEntryMarker &&
                    ((context.gas && !context.isGasLineCourseValid) ||
                        (context.power && !context.isElectricityLineCourseValid)))) &&
            context.houseShapes.length > 0
        ) {
            fetchLineCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.powerMarker, context.gasMarker, context.multiEntryMarker, updateContext, isMovingMarker]);

    useEffect(() => {
        if (updateCounter !== 0) {
            fetchLineCourses();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateCounter, isMovingMarker]);

    useEffect(() => {
        [
            { lineCoursePolyLine: electricityLineCoursePolyLine, key: 'electricityLineCourse' },
            { lineCoursePolyLine: gasLineCoursePolyLine, key: 'gasLineCourse' },
        ].forEach(({ lineCoursePolyLine, key }) => {
            map.addLayer(lineCoursePolyLine);
            trackChangesOfLineCourse(
                lineCoursePolyLine as L.Polyline,
                key as 'electricityLineCourse' | 'gasLineCourse',
                updateContext,
                update,
            );
            setLineCoursePolyLineOptions(lineCoursePolyLine as L.Polyline);
        });

        map.on('pm:globaleditmodetoggled', () => {
            hideStartVertexOfLineCourses([electricityLineCoursePolyLine, gasLineCoursePolyLine]);
        });

        // this needs to be executed only once when the component is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
};
