import { FormItem } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import FormLayout from '../../components/SignupForm/FormLayout';
import AddressModule from '../../components/form-modules/AddressModule';
import PersonModule from '../../components/form-modules/PersonModule';
import { PrivateInvoiceData, UpdateContextFunction, isBusinessDataPrivate, isPrivateData } from '../../types';
import { Context, useContextUpdate } from '../../wizard';

export type FormProps = {
    invoiceAddressQuestion: string;
    updateContext: UpdateContextFunction;
    context: Partial<Context>;
    setIsFormValid: (isFormValid: boolean) => void;
};

const PrivateInvoiceAddressForm = ({
    invoiceAddressQuestion,
    updateContext,
    context,
    setIsFormValid,
}: FormProps): React.ReactElement => {
    const reactHookForm = useForm<PrivateInvoiceData>({
        mode: 'onTouched',
    });
    const {
        watch,
        reset,
        formState: { isValid },
    } = reactHookForm;

    const preassignedPrivateData = () => {
        if (isBusinessDataPrivate(context)) {
            return context.businessData;
        }

        if (isPrivateData(context)) {
            return context.privateData;
        }

        throw Error('Illegal State');
    };

    React.useEffect(() => {
        if (context.isDeviatingInvoiceAddress) {
            reset({
                invoicePrivate: context.invoicePrivate ?? {
                    person: { firstName: '', academicTitle: undefined, salutation: null, lastName: '' },
                    address: { street: '', houseNumber: '', postalCode: '', city: '' },
                },
            });
        } else {
            reset({ invoicePrivate: preassignedPrivateData() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.isDeviatingInvoiceAddress]);

    useContextUpdate(watch, updateContext, 'ADDRESSES_INVOICE_ADDRESS', {
        disabled: !context.isDeviatingInvoiceAddress,
    });

    React.useEffect(() => {
        setIsFormValid(isValid);
    }, [isValid, setIsFormValid]);

    return (
        <FormProvider {...reactHookForm}>
            <FormLayout>
                <FormItem label={invoiceAddressQuestion}>
                    <div className="max-w-[456px]">
                        <PersonModule
                            parentObjectReference="invoicePrivate.person"
                            disabled={!context.isDeviatingInvoiceAddress}
                        />
                        <AddressModule
                            parentObjectReference="invoicePrivate.address"
                            disabled={!context.isDeviatingInvoiceAddress}
                        />
                    </div>
                </FormItem>
            </FormLayout>
        </FormProvider>
    );
};

export default PrivateInvoiceAddressForm;
