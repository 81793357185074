import { trimObject } from '../../util/trimObject';
import { Context } from '../../wizard';

export const transformContextToBuilding = (context: Partial<Context>) =>
    trimObject({
        ...context.building,
        newBuilding: context.building && context.building.newBuilding === 'yes',
        basement: context.building?.basement ? context.building.basement === 'yes' : undefined,
        specialType:
            context.building.specialType && context.building.specialType.length > 1
                ? context.building.specialType
                : undefined,
    });
