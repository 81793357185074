import { Dropdown, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AddRemoveButtonGroup } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import useMultiField from '../../../components/useMultiField';
import { PowerConsumptionUnknownProperties, UserType } from '../../../types';
import RowElement from './RowWrapperElement';

export type ElectricalHeaterProps = {
    reactHookForm: UseFormReturn<PowerConsumptionUnknownProperties>;
    userType: UserType;
};

// as is used to show that there is a type difference between PowerConsumptionUnknownProperties
// and Form type. This was obscured by RowHook, but was already implemented.
// consider refactor to formType and contextType.
export function getDefaultValue() {
    return {} as { type: string; capacity: 0 };
}

const dropdownOptions = [
    {
        value: 'infrared',
        label: 'Infrarotheizung',
    },
    {
        value: 'controlledVentilation',
        label: 'Kontrollierte Wohnungsbelüftung',
    },
    {
        value: 'nightStorageHeater',
        label: 'Nachtspeicherheizung',
    },
    {
        value: 'heatPump',
        label: 'Wärmepumpe',
    },
];

export default ({ reactHookForm, userType }: ElectricalHeaterProps): React.ReactElement => {
    const {
        control,
        register,
        formState: { errors, touchedFields },
    } = reactHookForm;

    const [fields, append, remove] = useMultiField({
        control,
        name: 'electricService',
        defaultValue: getDefaultValue(),
    });

    return (
        <div className="mt-5 sm:mt-0">
            <div className="grid grid-flow-col items-center">
                <span className="font-bold">Elektrische Heizung</span>
            </div>
            <FormLayout>
                <FormItem
                    label={
                        userType === 'installer' || userType === 'projectpartner'
                            ? 'Welche Heizungsart(en) wird Ihr Kunde haben?'
                            : 'Welche Heizungsart(en) werden Sie haben?'
                    }
                    className="items-start w-full"
                >
                    <div className="flex flex-col gap-y-3 w-full">
                        {fields.map((field, index) => (
                            <RowElement
                                key={field.id}
                                index={index}
                                firstColumnLabel="Heizungsart"
                                firstColumnContent={
                                    <Dropdown
                                        name={`electricService.${index}.type`}
                                        options={dropdownOptions}
                                        dropdownClassname="w-full"
                                        {...register(`electricService.${index}.type`, {
                                            required: true,
                                            shouldUnregister: true,
                                        })}
                                    />
                                }
                                secondColumnLabel="Gesamtleistung"
                                secondColumnContent={
                                    <div className="grid grid-flow-col gap-x-2 content-start">
                                        <NumberInput
                                            name={`electricService.${index}.capacity`}
                                            {...NumberInputFunctions.registerNumberInput(
                                                reactHookForm,
                                                `electricService.${index}.capacity`,
                                                {
                                                    required: true,
                                                    shouldUnregister: true,
                                                    min: 1,
                                                    max: 999,
                                                },
                                            )}
                                            displayErrorMessage={
                                                touchedFields?.electricService?.[index.toString()]?.capacity &&
                                                errors?.electricService?.[index.toString()]?.capacity &&
                                                'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            }
                                            fluidWidth
                                        />

                                        <span className="mt-2">kW</span>
                                    </div>
                                }
                                buttonGroup={
                                    <AddRemoveButtonGroup
                                        group="electricService"
                                        handleAppend={append}
                                        handleDelete={remove(index)}
                                        fields={fields}
                                        index={index}
                                    />
                                }
                            />
                        ))}
                    </div>
                </FormItem>
            </FormLayout>
        </div>
    );
};
