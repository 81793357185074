export const STEPS = {
    SERVICE_PROCESS: 'SERVICE_PROCESS',
    CONSUMPTION_PROCESS: 'CONSUMPTION_PROCESS',
    CONNECTION_PROCESS: 'CONNECTION_PROCESS',
    TRANSPOSITION_PROCESS: 'TRANSPOSITION_PROCESS',
    ADDRESSES_PROCESS: 'ADDRESSES_PROCESS',
    OFFER_PROCESS: 'OFFER_PROCESS',
    NEXT_STEPS_PROCESS: 'NEXT_STEPS_PROCESS',
} as const;

// Union type of the values of all fields of the constant STEPS object
export type STEPS_VALUE = (typeof STEPS)[keyof typeof STEPS];

export const ALL_STEPS: STEPS_VALUE[] = [
    STEPS.SERVICE_PROCESS,
    STEPS.CONSUMPTION_PROCESS,
    STEPS.CONNECTION_PROCESS,
    STEPS.TRANSPOSITION_PROCESS,
    STEPS.ADDRESSES_PROCESS,
    STEPS.OFFER_PROCESS,
    STEPS.NEXT_STEPS_PROCESS,
];

// Behaves like an enum
export const SUBPROCESS = {
    SERVICE_LOCATION_DATA: 'SERVICE_LOCATION_DATA',
    SERVICE_SELECTION: 'SERVICE_SELECTION',

    CONSUMPTION_BUILDING_DATA: 'CONSUMPTION_BUILDING_DATA',
    CONSUMPTION_POWER_CONSUMPTION_DECLARATION: 'CONSUMPTION_POWER_CONSUMPTION_DECLARATION',
    CONSUMPTION_POWER_CONSUMPTION_TOTAL: 'CONSUMPTION_POWER_CONSUMPTION_TOTAL',
    CONSUMPTION_GAS_CONSUMPTION: 'CONSUMPTION_GAS_CONSUMPTION',

    CONNECTION_DATA: 'CONNECTION_DATA',
    CONNECTION_PLAN: 'CONNECTION_PLAN',
    CONNECTION_LAND_OWNER: 'CONNECTION_LAND_OWNER',

    TRANSPOSITION_PERSONAL_CONTRIBUTION: 'TRANSPOSITION_PERSONAL_CONTRIBUTION',
    TRANSPOSITION_INSTALLER_SELECTION: 'TRANSPOSITION_INSTALLER_SELECTION',

    ADDRESSES_CONTACT_ADDRESS: 'ADDRESSES_CONTACT_ADDRESS',
    ADDRESSES_INVOICE_ADDRESS: 'ADDRESSES_INVOICE_ADDRESS',

    OFFER_REQUEST_OVERVIEW: 'OFFER_REQUEST_OVERVIEW',
    OFFER_POWER: 'OFFER_POWER',
    OFFER_GAS: 'OFFER_GAS',
    OFFER_CONCLUSION: 'OFFER_CONCLUSION',

    NEXT_STEPS: 'NEXT_STEPS',
} as const;

// Union type of the values of all fields of the constant SUBPROCESS object
export type SUBPROCESS_VALUE = (typeof SUBPROCESS)[keyof typeof SUBPROCESS];

export const SUBPROCESS_INDEX_MAPPING: { [key in SUBPROCESS_VALUE]: [number, number] } = {
    SERVICE_LOCATION_DATA: [0, 0],
    SERVICE_SELECTION: [0, 1],

    CONSUMPTION_BUILDING_DATA: [1, 0],
    CONSUMPTION_POWER_CONSUMPTION_DECLARATION: [1, 1],
    CONSUMPTION_POWER_CONSUMPTION_TOTAL: [1, 2],
    CONSUMPTION_GAS_CONSUMPTION: [1, 3],

    CONNECTION_DATA: [2, 0],
    CONNECTION_PLAN: [2, 1],
    CONNECTION_LAND_OWNER: [2, 2],

    TRANSPOSITION_PERSONAL_CONTRIBUTION: [3, 0],
    TRANSPOSITION_INSTALLER_SELECTION: [3, 1],

    ADDRESSES_CONTACT_ADDRESS: [4, 0],
    ADDRESSES_INVOICE_ADDRESS: [4, 1],

    OFFER_REQUEST_OVERVIEW: [5, 0],
    OFFER_POWER: [5, 1],
    OFFER_GAS: [5, 2],
    OFFER_CONCLUSION: [5, 3],

    NEXT_STEPS: [6, 0],
};

export const CONSUMPTION_PROCESS_INDEX = 1;
