import L, { CRS } from 'leaflet';
import * as React from 'react';
import { MapContainer, Polyline, TileLayer, WMSTileLayer, ZoomControl } from 'react-leaflet';

import appConfig from '../../app.config';
import controlLayerEditing from '../../connection/connection-plan-form/buildingAndConnectionPlan/controlLayerEditing';

export type ConnectionPlanFormProperties = {
    isPowerSelected: boolean;
    isGasSelected: boolean;
    cadastralDistrictLatLng: L.LatLng[];
    map: L.Map | undefined;
    setMap: React.Dispatch<React.SetStateAction<L.Map | undefined>>;
    children?: React.ReactNode;
    currentProcessStep?: number;
    takeFullWidth?: boolean;
};

export default ({
    map,
    setMap,
    isGasSelected,
    isPowerSelected,
    cadastralDistrictLatLng,
    currentProcessStep,
    children,
    takeFullWidth,
}: ConnectionPlanFormProperties): React.ReactElement => {
    const [isControlLayerSet, setIsControlLayerSet] = React.useState<boolean>(false);

    const padBoundsByXMeters = (bounds: L.LatLngBounds, meters: number) =>
        bounds.extend(bounds.getNorthWest().toBounds(meters * 2)).extend(bounds.getSouthEast().toBounds(meters * 2));

    const layersToSelect = ['CadastralDistrict', isPowerSelected && 'Power', isGasSelected && 'Gas']
        .filter((layer) => layer && layer.length > 0)
        .join(',');

    const polyBoundingBox = L.polygon(cadastralDistrictLatLng).getBounds();

    const center = L.polygon(cadastralDistrictLatLng).getBounds().getCenter();

    const wmsTileLayer = React.useMemo(
        () => (
            <WMSTileLayer
                url={`${appConfig.services.gisMiddlewareApi}/wms?code=${encodeURIComponent(
                    appConfig.services.gisMiddlewareApiKey,
                )}`}
                crs={CRS.EPSG4326}
                format="image/png"
                maxZoom={24}
                maxNativeZoom={22}
                opacity={0.9}
                transparent
                params={{
                    layers: layersToSelect,
                    version: '1.3.0',
                }}
            />
        ),
        [layersToSelect],
    );

    React.useEffect(() => {
        if (!map || isControlLayerSet) return;

        if (currentProcessStep) {
            controlLayerEditing(map, L.PM.Utils.findLayers(map), currentProcessStep, () => null);
        }
        setIsControlLayerSet(true);
    }, [currentProcessStep, isControlLayerSet, map]);

    return (
        <div className={`${takeFullWidth ? 'full-main-container-width' : ''} h-[600px] relative`}>
            <MapContainer
                center={center}
                zoomControl={false}
                maxZoom={24}
                className="w-full h-full"
                bounds={padBoundsByXMeters(polyBoundingBox, 25)}
                zoomSnap={0.01}
                zoomDelta={0.5}
                wheelPxPerZoomLevel={70}
                inertia={false}
                maxBoundsViscosity={1}
                ref={setMap}
            >
                <TileLayer
                    maxNativeZoom={19}
                    maxZoom={26}
                    opacity={0.6}
                    attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://tile.openstreetmap.de/{z}/{x}/{y}.png"
                />

                {wmsTileLayer}
                {cadastralDistrictLatLng && (
                    <Polyline pathOptions={{ color: '#24abe6' }} positions={cadastralDistrictLatLng} pmIgnore />
                )}
                <ZoomControl position="bottomright" />
                <div
                    className="w-min h-8 bottom-4 left-4 gap-x-3 px-3 border-none box-border flex border
                        bg-white absolute items-center shadow rounded-lg cursor-auto z-[800]"
                >
                    {isPowerSelected && (
                        <div className="flex w-min place-items-center">
                            <span className="h-0 w-4 border-t-4 electricityConnection" />
                            <span className="ml-2 electricityConnection">Stromleitung</span>
                        </div>
                    )}
                    {isGasSelected && (
                        <div className="flex w-min place-items-center">
                            <span className="h-0 w-4 border-t-4 gasConnection" />
                            <span className="ml-2 gasConnection">Erdgasleitung</span>
                        </div>
                    )}
                </div>
                {children}
            </MapContainer>
        </div>
    );
};
