import { Dropdown, TextField, Tooltip } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { PrivateData } from '../../types';

type FormProps = {
    parentObjectReference: string;
    disabled?: boolean;
};

const PersonFields = ({ parentObjectReference, disabled = false }: FormProps): React.ReactElement => {
    const dropdownOptions = {
        salutation: [
            {
                value: 'Mr',
                label: 'Herr',
            },
            {
                value: 'Ms',
                label: 'Frau',
            },
            {
                value: 'Divers',
                label: 'Divers',
            },
        ],
        academicTitle: [
            {
                value: '',
                label: '',
            },
            {
                value: 'Dr',
                label: 'Dr.',
            },
            {
                value: 'Prof',
                label: 'Prof.',
            },
            {
                value: 'ProfDr',
                label: 'Prof. Dr.',
            },
        ],
    };
    const requiredErrorMessage = 'Dies ist ein Pflichtfeld.';
    const validationErrorMessage = 'Bitte korrigieren Sie Ihre Eingabe.';
    const {
        register,
        formState: { errors },
    } = useFormContext();

    const flattenedErrors = {
        ...parentObjectReference.split('.').reduce((o, x) => o?.[x], errors as FieldErrors<PrivateData>),
    };
    return (
        <>
            <div className="flex space-x-2 h-20 items-start">
                <label className="text-small grid w-full" htmlFor={`dropdown-${parentObjectReference}.salutation`}>
                    <span className="text-small">Anrede</span>
                    <Dropdown
                        name="salutation"
                        test-id="dropdown-salutation"
                        options={dropdownOptions.salutation}
                        dropdownClassname="w-full"
                        disabled={disabled}
                        {...register(`${parentObjectReference}.salutation`, { required: true })}
                    />
                    {flattenedErrors?.salutation && (
                        <div>
                            <Tooltip message={requiredErrorMessage} />
                        </div>
                    )}
                </label>
                <label className="text-small grid w-full" htmlFor={`dropdown-${parentObjectReference}.academicTitle`}>
                    <span className="text-small">
                        Titel <span className="text-darkest-grey">(optional)</span>
                    </span>
                    <Dropdown
                        name="academicTitle"
                        test-id="dropdown-academicTitle"
                        options={dropdownOptions.academicTitle}
                        dropdownClassname="w-full"
                        disabled={disabled}
                        {...register(`${parentObjectReference}.academicTitle`, { required: false })}
                    />
                </label>
            </div>
            <div className="flex space-x-2 h-20 ">
                <div className="w-full">
                    <TextField
                        autoComplete="given-name"
                        name="firstName"
                        label="Vorname"
                        displayErrorMessage={flattenedErrors?.firstName?.message}
                        disabled={disabled}
                        {...register(`${parentObjectReference}.firstName`, {
                            required: requiredErrorMessage,
                            minLength: { value: 2, message: validationErrorMessage },
                            maxLength: { value: 40, message: validationErrorMessage },
                        })}
                    />
                </div>
                <div className="w-full h-20">
                    <TextField
                        autoComplete="family-name"
                        name="lastName"
                        label="Nachname"
                        displayErrorMessage={flattenedErrors?.lastName?.message}
                        disabled={disabled}
                        {...register(`${parentObjectReference}.lastName`, {
                            required: requiredErrorMessage,
                            minLength: { value: 2, message: validationErrorMessage },
                            maxLength: { value: 40, message: validationErrorMessage },
                        })}
                    />
                </div>
            </div>
        </>
    );
};

export default PersonFields;
