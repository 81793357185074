import { ErrorBox, Form } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

export type NoAutomaticOffer = {
    prevButton: React.ReactElement;
    nextButton: React.ReactElement;
    concludeOfferError: boolean;
};

export default ({ prevButton, nextButton, concludeOfferError }: NoAutomaticOffer): React.ReactElement => {
    const [showError, setShowError] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (concludeOfferError) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [concludeOfferError]);

    return (
        <Form leftButton={prevButton} rightButton={nextButton} title="Unser Angebot an Sie">
            <div className="flex flex-col space-y-4">
                <span>Fast geschafft – vielen Dank für Ihre Zeit!</span>
                <div className="flex flex-col space-y-1">
                    <span>
                        Nun sind wir an der Reihe: Ihre Angaben werden durch unsere regionalen Ansprechpartner geprüft
                        und es wird ein individuelles Angebot für Sie erstellt.
                    </span>
                    <span>
                        Das Angebot wird Ihnen nach der erfolgreichen Prüfung zeitnah über unser Netzkundenportal zur
                        Verfügung gestellt. Sie erhalten eine Benachrichtigung, sobald unser Angebot für Sie verfügbar
                        ist.
                    </span>
                </div>
                <span>
                    Bitte schließen Sie Ihren Antrag nun ab, so dass die Prüfung und die individuelle Angebotserstellung
                    erfolgen kann.
                </span>
            </div>

            {concludeOfferError && showError && <ErrorBox onClick={() => setShowError(false)} />}
        </Form>
    );
};
