import { EventObject, MachineConfig, StateMachine, StateNode, createMachine } from 'xstate';

import { ALL_STEPS, STEPS_VALUE, SUBPROCESS_INDEX_MAPPING, SUBPROCESS_VALUE } from './constants';
import { Context } from './context';

export const getStateNodes = (stateNode: StateNode | StateMachine<unknown, unknown, EventObject>): StateNode[] => {
    const { states } = stateNode;
    const nodes = Object.keys(states).reduce((accNodes: StateNode[], stateKey) => {
        const childStateNode = states[stateKey];
        const childStateNodes = getStateNodes(childStateNode);

        accNodes.push(childStateNode, ...childStateNodes);
        return accNodes;
    }, []);
    return nodes;
};
type RouteMetadata = { target: string; path: string; process: STEPS_VALUE; subProcess: number };

export const getRoutes = (config: MachineConfig<Partial<Context>, never, EventObject>): Array<RouteMetadata> => {
    const nodes = getStateNodes(createMachine(config));
    const routes: Array<RouteMetadata> = [];
    Object.values(nodes).forEach((node) => {
        if (node.meta && node.meta.path && node.meta.process && node.meta.subprocess) {
            routes.push({
                target: node.path.join('.'),
                path: node.meta.path,
                process: node.meta.process,
                subProcess: node.meta.subprocess,
            });
        }
    });
    return routes;
};

export const debounce = (func: (...args: unknown[]) => void, timeout = 300): ((...args: unknown[]) => void) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
};

export const changeStepVisibility = (
    context: Partial<Context>,
    subprocess: SUBPROCESS_VALUE,
    visibility: boolean,
): void => {
    const [stepIndex, substepIndex] = SUBPROCESS_INDEX_MAPPING[subprocess];
    context.meta.steps[stepIndex].visible.splice(substepIndex, 1, visibility);
};

export const getProcessIndex = (process: STEPS_VALUE) => ALL_STEPS.findIndex((step) => step === process);

export const isNewFurthestStep = (
    context: Partial<Context>,
    currentProcess: STEPS_VALUE,
    currentSubprocess: number,
): boolean => {
    const positionOfFurthestProcess = getProcessIndex(context.meta.furthestStep?.furthestProcess);
    const positionOfCurrentProcess = getProcessIndex(currentProcess);

    return (
        positionOfFurthestProcess < positionOfCurrentProcess ||
        (positionOfFurthestProcess === positionOfCurrentProcess &&
            context.meta.furthestStep?.furthestSubprocess < currentSubprocess)
    );
};
