import { AuthError } from '@azure/msal-browser';
import { UnauthenticatedTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Button, FormItem, LoadingSpinnerButton, RadioButton } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { navigateToUrl } from 'single-spa';

import useApi from '../../hooks/useApi';
import { OFFER_CONCLUSION } from '../../routes';
import { errorCodePasswordForgotButton } from '../../util/errorCodePasswordForgotButton';
import { Context } from '../../wizard';
import { OfferReply, sendOfferReply } from '../api/OfferReplyAPI';
import { MaterialList } from '../api/material-costs/MaterialPriceAPI';
import { OfferResponse } from '../api/types';
import AutomaticOfferForm from './AcceptOfferForm';
import NoAutomaticOfferForm from './NoAutomaticOfferForm';
import NonCustomerConclusionForm from './NonCustomerConclusionForm';
import RegistrationForm from './RegistrationForm';
import EmailRegistrationSuccessContainer from './success/EmailRegistrationSuccessContainer';

export type OfferConclusionProperties = {
    onSubmit: () => void;
    goBack: () => void;
    context: Partial<Context>;
    offer: OfferResponse;
    materialList: MaterialList;
};

export default ({ onSubmit, goBack, context, offer, materialList }: OfferConclusionProperties): React.ReactElement => {
    const { userType } = context;
    const { instance } = useMsal();
    const sendOfferReplyApi = useApi(sendOfferReply);
    const { electricityOffer, gasOffer } = offer || {};

    const [registrationSuccessful, setRegistrationSuccessful] = React.useState<boolean>(false);
    const [authenticationProcessType, setAuthenticationProcessType] = React.useState<'login' | 'registration'>(
        'registration',
    );
    const [concludeOfferError, setConcludeOfferError] = React.useState<boolean>(false);
    const isAutomaticOfferPossible =
        context.meta.isAutomaticGasOfferPossible || context.meta.isAutomaticElectricityOfferPossible;

    const isAuthenticated = useIsAuthenticated();
    const [isSending, setIsSending] = React.useState(false);

    const concludeOffer = async (offerReplies: OfferReply[]): Promise<boolean> => {
        if (isAuthenticated && context.meta.connectionRequestId) {
            const offerReplyProps = {
                connectionRequestId: context.meta.connectionRequestId,
                offerReply: offerReplies,
            };

            try {
                await sendOfferReplyApi(offerReplyProps);
                return true;
            } catch (error) {
                console.error(`Failed to send offer reply`, error);
                setConcludeOfferError(true);
                return false;
            }
        }
        return false;
    };

    const handleOnNextWithNoAcceptableOffer = async () => {
        const offerReplies = [
            gasOffer && {
                offerCommunicationId: gasOffer.processCommunicationId,
                acceptOffer: false,
            },
            electricityOffer && {
                offerCommunicationId: electricityOffer.processCommunicationId,
                acceptOffer: false,
            },
        ].filter(Boolean);
        setIsSending(true);
        const wasResponseSuccessful = await concludeOffer(offerReplies);
        setIsSending(false);

        if (wasResponseSuccessful) {
            onSubmit();
        } else {
            // eslint-disable-next-line no-console
            setConcludeOfferError(true);
            console.error(`Failed to send offer reply`);
        }
    };

    const navigateToLoginPage = () => {
        const redirectKey = 'portal-app-local-redirect-url';
        sessionStorage.setItem(redirectKey, `${OFFER_CONCLUSION}/${context.meta.connectionRequestId}`);
        instance.loginRedirect().catch((error) => {
            if ((error as AuthError).errorMessage?.includes(errorCodePasswordForgotButton)) {
                navigateToUrl('/account/passwort-vergessen');
            }
        });
    };

    const handleAuthenticationProcessChange = (e) => {
        setAuthenticationProcessType(e.target.value);
    };

    return (
        <>
            <UnauthenticatedTemplate>
                <div className="w-full grid grid-cols-[3fr_7fr] gap-x-5 my-10">
                    <FormItem
                        label="Haben Sie bereits einen Zugang zu unserem Netzkundenportal?"
                        className="flex gap-x-60"
                    >
                        <RadioButton
                            id="login"
                            name="login"
                            checked={authenticationProcessType === 'login'}
                            value="login"
                            onChange={handleAuthenticationProcessChange}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            id="registration"
                            name="registration"
                            value="registration"
                            checked={authenticationProcessType === 'registration'}
                            onChange={handleAuthenticationProcessChange}
                        >
                            Nein
                        </RadioButton>
                    </FormItem>
                </div>

                {authenticationProcessType === 'login' && (
                    <div className="flex flex-col space-y-4 w-full">
                        <h3 className="font-bold text-medium">Anmeldung im Netzkundenportal</h3>
                        <span>
                            Bitte melden Sie sich vor dem Abschluss Ihres Antrags an. Nach der Anmeldung werden Sie
                            zurück zu Ihrem Antrag geleitet und können diesen abschließen.
                        </span>
                        <div className="flex flex-row-reverse w-full">
                            <Button label="Zur Anmeldung" onClick={navigateToLoginPage} />
                        </div>
                    </div>
                )}
                {!registrationSuccessful && authenticationProcessType === 'registration' && (
                    <RegistrationForm context={context} setRegistrationSuccessful={setRegistrationSuccessful} />
                )}
                {registrationSuccessful && authenticationProcessType === 'registration' && (
                    <EmailRegistrationSuccessContainer />
                )}
            </UnauthenticatedTemplate>

            {(userType === 'installer' || userType === 'projectpartner') && (
                <NonCustomerConclusionForm
                    prevButton={<Button label="Zurück" onClick={goBack} type="secondary" />}
                    nextButton={
                        <LoadingSpinnerButton
                            label="Antrag abschließen"
                            type="primary"
                            disabled={!isAuthenticated || concludeOfferError}
                            loading={isSending}
                            loadingTitle="Die Angebotsannahme wird übermittelt."
                            onClick={handleOnNextWithNoAcceptableOffer}
                        />
                    }
                    context={context}
                    concludeOfferError={concludeOfferError}
                />
            )}

            {userType !== 'installer' && userType !== 'projectpartner' && isAutomaticOfferPossible && (
                <AutomaticOfferForm
                    onNext={onSubmit}
                    onBack={goBack}
                    offer={offer}
                    context={context}
                    isAuthenticated={isAuthenticated}
                    materialList={materialList}
                    concludeOffer={concludeOffer}
                />
            )}

            {userType !== 'installer' && userType !== 'projectpartner' && !isAutomaticOfferPossible && (
                <NoAutomaticOfferForm
                    prevButton={<Button label="Zurück" onClick={goBack} type="secondary" />}
                    nextButton={
                        <LoadingSpinnerButton
                            label="Antrag abschließen"
                            type="primary"
                            disabled={!isAuthenticated}
                            loading={isSending}
                            loadingTitle="Die Angebotsannahme wird übermittelt."
                            onClick={handleOnNextWithNoAcceptableOffer}
                        />
                    }
                    concludeOfferError={concludeOfferError}
                />
            )}
        </>
    );
};
