import { InstallerFilterResult, InstallerFilterResultWithDecodedCompanyName } from '../../types';

const getBranchInstaller = (
    installers: InstallerFilterResult[] | undefined,
    branch: 'electricity' | 'gas',
): InstallerFilterResult | undefined => {
    if (!installers || installers.length === 0) {
        return undefined;
    }

    if (installers.length === 1) {
        const installer: InstallerFilterResult = installers[0];
        if ((branch === 'electricity' && installer.electricityBranch) || (branch === 'gas' && installer.gasBranch)) {
            return installer;
        }
        return undefined;
    }

    const exclusiveElectricityInstaller = installers.filter(
        (installer) => installer.electricityBranch && !installer.gasBranch,
    )[0];

    const exclusiveGasInstaller = installers.filter(
        (installer) => !installer.electricityBranch && installer.gasBranch,
    )[0];

    const gasAndElectricityInstallers = installers.filter(
        (installer) => installer.electricityBranch && installer.gasBranch,
    );

    if (gasAndElectricityInstallers.length === 2) {
        return gasAndElectricityInstallers[branch === 'electricity' ? 0 : 1];
    }

    if (branch === 'electricity') {
        return exclusiveElectricityInstaller ?? gasAndElectricityInstallers[0];
    }
    return exclusiveGasInstaller ?? gasAndElectricityInstallers[0];
};

// TODO: Add tests for the file
export const transformInstallerArrayToSpecifiedBranchInstaller = (
    chosenInstallers: InstallerFilterResult[] | undefined,
): {
    electricityInstaller: InstallerFilterResult | undefined;
    gasInstaller: InstallerFilterResult | undefined;
} => {
    const filteredChosenInstallers = chosenInstallers?.map(
        (chosenInstaller: InstallerFilterResultWithDecodedCompanyName) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { companyNameDecoded, ...installer } = chosenInstaller;
            return installer;
        },
    );
    const electricityInstaller = getBranchInstaller(filteredChosenInstallers, 'electricity');
    const gasInstaller = getBranchInstaller(filteredChosenInstallers, 'gas');

    return { electricityInstaller, gasInstaller };
};
