import L, { LeafletMouseEvent } from 'leaflet';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Pane, useMap } from 'react-leaflet';

import { HouseShape, LineCourse } from '../../../types';
import { electricityPolylineColor, gasPolylineColor } from '../../../util/drawing';
import LengthLabel from './LengthLabel';
import LineCourseLengthLabel from './LineCourseLengthLabel';

type LengthsPaneProps = {
    houseShapes?: HouseShape[];
    gasLineCourse?: LineCourse;
    electricityLineCourse?: LineCourse;
};
const LengthsPane = ({ houseShapes, gasLineCourse, electricityLineCourse }: LengthsPaneProps): React.ReactElement => {
    const map = useMap();

    const [drawShapePoints, setDrawShapePoints] = React.useState<L.LatLng[]>([]);
    const [mostRecentMouseLatLngInMap, setMostRecentMouseLatLngInMap] = React.useState<L.LatLng>();
    const isDrawModeEnabledRef = React.useRef<boolean>(map.pm.globalDrawModeEnabled());
    const [drawMode, setDrawMode] = React.useState<'none' | 'Rectangle' | 'Polygon'>('none');

    useEffect(() => {
        isDrawModeEnabledRef.current = map.pm.globalDrawModeEnabled();
    });

    useEffect(() => {
        map.on('mousemove', (event) => {
            if (isDrawModeEnabledRef.current) {
                const leafletMouseEvent = event as LeafletMouseEvent;
                setMostRecentMouseLatLngInMap(leafletMouseEvent.latlng);
            }
        });
        map.on('pm:drawstart', (e) => {
            setDrawShapePoints([]);
            if (e.shape === 'Rectangle' || e.shape === 'Polygon') {
                setDrawMode(e.shape as 'Rectangle' | 'Polygon');
            }
        });
        map.on('pm:drawend', () => {
            setDrawShapePoints([]);
            setDrawMode('none');
        });
        map.on('click', (event) => {
            if (isDrawModeEnabledRef.current) {
                setDrawShapePoints((prevDrawShapePoints) => [
                    ...prevDrawShapePoints,
                    (event as LeafletMouseEvent).latlng,
                ]);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [, setUpdateCounter] = useState(0);
    const update = () => setUpdateCounter((prev) => prev + 1);
    useEffect(() => {
        update();
    }, [houseShapes, gasLineCourse, electricityLineCourse]);

    const layers = L.PM.Utils.findLayers(map);
    const polylines = layers
        .filter((layer): layer is L.Polyline => layer instanceof L.Polyline && !(layer instanceof L.Polygon))
        .filter((polyline) => polyline.getLatLngs().length > 0);
    const electricityPolyline = React.useMemo(
        () =>
            electricityLineCourse
                ? polylines.find((polyline) => polyline.options.color === electricityPolylineColor)
                : undefined,
        [electricityLineCourse, polylines],
    );
    const gasPolyline = React.useMemo(
        () => (gasLineCourse ? polylines.find((polyline) => polyline.options.color === gasPolylineColor) : undefined),
        [gasLineCourse, polylines],
    );

    const polygonEdges = React.useMemo(() => {
        const edges = layers
            .filter((layer): layer is L.Polygon => layer instanceof L.Polygon)
            .flatMap((polygon) => {
                const latLngs = polygon.getLatLngs()[0] as L.LatLng[];
                return latLngs.map((latlng, index) => ({
                    a: latlng,
                    b: latLngs[(index + 1) % latLngs.length],
                    // eslint-disable-next-line @typescript-eslint/dot-notation
                    id: `${polygon['_id']}-${index}`,
                }));
            });
        let extraEdges = [];
        if (drawShapePoints.length > 0) {
            if (drawMode === 'Polygon') {
                extraEdges = drawShapePoints.map((drawShapePoint, index) => ({
                    a: drawShapePoint,
                    b: index < drawShapePoints.length - 1 ? drawShapePoints[index + 1] : mostRecentMouseLatLngInMap,
                    id: `drawing-shape-${index}`,
                }));
            }
            if (drawMode === 'Rectangle') {
                const bounds = L.latLngBounds([drawShapePoints[0], mostRecentMouseLatLngInMap]);
                extraEdges.push({
                    a: bounds.getNorthWest(),
                    b: bounds.getNorthEast(),
                    id: 'drawing-shape-rectangle-north-line',
                });
                extraEdges.push({
                    a: bounds.getNorthEast(),
                    b: bounds.getSouthEast(),
                    id: 'drawing-shape-rectangle-east-line',
                });
                extraEdges.push({
                    a: bounds.getSouthEast(),
                    b: bounds.getSouthWest(),
                    id: 'drawing-shape-rectangle-south-line',
                });
                extraEdges.push({
                    a: bounds.getSouthWest(),
                    b: bounds.getNorthWest(),
                    id: 'drawing-shape-rectangle-west-line',
                });
            }
        }
        return edges.concat(extraEdges);
    }, [layers, drawShapePoints, mostRecentMouseLatLngInMap, drawMode]);

    return (
        <Pane name="lengths" style={{ zIndex: 700 }}>
            {polygonEdges.map((polygonEdge) => (
                <LengthLabel a={polygonEdge.a} b={polygonEdge.b} key={`length-label-${polygonEdge.id}`} />
            ))}
            {gasPolyline && <LineCourseLengthLabel polyline={gasPolyline} type="gas" />}
            {electricityPolyline && <LineCourseLengthLabel polyline={electricityPolyline} type="electricity" />}
        </Pane>
    );
};

export default LengthsPane;
