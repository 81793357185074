import { Context } from './context';

export const isPowerServiceSelected = (context: Partial<Context>): boolean => context.power;
export const isGasServiceSelected = (context: Partial<Context>): boolean => context.gas;

export const isPowerConsumptionTotalPageShown = (context: Partial<Context>): boolean =>
    isPowerServiceSelected(context) && !context.powerConsumptionKnown;
export const isAutomaticElectricityOfferPageShown = (context: Partial<Context>): boolean =>
    isPowerServiceSelected(context) && (context.meta.isAutomaticElectricityOfferPossible ?? true);
export const isAutomaticGasOfferPageShown = (context: Partial<Context>): boolean =>
    isGasServiceSelected(context) && (context.meta.isAutomaticGasOfferPossible ?? true);

export default {
    isPowerServiceSelected,
    isPowerConsumptionTotalPageShown,
    isGasServiceSelected,
    isAutomaticElectricityOfferPageShown,
    isAutomaticGasOfferPageShown,
};
