import { Button } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';

interface ButtonProps {
    onNext: () => void;
    onBack: () => void;
    isNextValid: boolean;
    nextLabel?: string;
    prevLabel?: string;
    nextTitle?: string;
    size?: 'sm' | 'md' | 'mobile';
}

export default ({
    onBack,
    onNext,
    isNextValid,
    nextLabel,
    prevLabel,
    nextTitle,
    size = 'md',
}: ButtonProps): [React.ReactElement, React.ReactElement] => {
    const nextButton = (
        <Button
            size={size}
            label={nextLabel || 'Weiter'}
            title={nextTitle}
            onClick={onNext}
            type="primary"
            disabled={!isNextValid}
        />
    );

    const prevButton = <Button size={size} type="secondary" label={prevLabel || 'Zurück'} onClick={onBack} />;

    return [prevButton, nextButton];
};
