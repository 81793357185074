import { FormItem, NumberInput, NumberInputFunctions, TextField } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AddRemoveButtonGroup as ButtonGroup } from '../../../components';
import useMultiField from '../../../components/useMultiField';
import { GasConsumptionUnknownData, UserType } from '../../../types';

export type GasAdditionalDevicesProps = {
    reactHookForm: UseFormReturn<GasConsumptionUnknownData>;
    userType: UserType;
    syncContext: () => void;
};

export default ({ reactHookForm, userType, syncContext }: GasAdditionalDevicesProps): React.ReactElement => {
    const {
        register,
        control,
        formState: { errors, touchedFields },
        reset,
        getValues,
    } = reactHookForm;

    const [fields, append, remove] = useMultiField({
        control,
        name: 'gasAdditionalDevices',
        defaultValue: {},
        resetDefaultValues: reset,
        syncContext,
    });

    return (
        <FormItem
            label={
                <>
                    {userType === 'installer' || userType === 'projectpartner'
                        ? 'Falls Ihr Kunde zusätzliche Geräte haben wird, für die er Erdgas benötigt, ' +
                          'geben Sie diese bitte hier an:'
                        : 'Falls Sie zusätzliche Geräte haben werden, für die Sie Erdgas benötigen, ' +
                          'geben Sie diese bitte hier an.'}

                    <span className="text-darkest-grey ml-1">(optional)</span>
                </>
            }
            className="items-start"
        >
            <div className="flex flex-col gap-y-3">
                {fields.map((field, index) => {
                    const inputIsRequired =
                        !!getValues(`gasAdditionalDevices.${index}.capacityInKW`) ||
                        !!getValues(`gasAdditionalDevices.${index}.amount`);
                    return (
                        <div className="flex flex-row items-center justify-start w-full gap-x-6" key={field.id}>
                            <div className="flex items-center justify-start w-10/12 md:w-2/3 gap-x-2 ml-px mb-1">
                                <div className="flex flex-col md:grid md:w-3/5 min-w-[120px]">
                                    {index === 0 && <span className="text-small">Art Erdgasgerät</span>}
                                    <TextField
                                        name={`gasAdditionalDevices.${index}.name`}
                                        {...register(`gasAdditionalDevices.${index}.name` as const, {
                                            minLength: 2,
                                            maxLength: 60,
                                            onChange: syncContext,
                                        })}
                                        displayErrorMessage={
                                            touchedFields?.gasAdditionalDevices?.[index.toString()]?.name &&
                                            errors?.gasAdditionalDevices?.[index.toString()]?.name &&
                                            'Bitte geben Sie einen Namen mit 2-60 Zeichen an.'
                                        }
                                    />
                                </div>
                                <div className="flex flex-col md:grid">
                                    {index === 0 && <span className="text-small">Leistung</span>}
                                    <NumberInput
                                        name={`gasAdditionalDevices.${index}.capacityInKW`}
                                        {...NumberInputFunctions.registerNumberField(
                                            reactHookForm,
                                            `gasAdditionalDevices.${index}.capacityInKW`,
                                            {
                                                required: inputIsRequired,
                                                shouldUnregister: true,
                                                min: 1,
                                                max: 999,
                                                onChange: syncContext,
                                            },
                                        )}
                                        displayErrorMessage={
                                            touchedFields?.gasAdditionalDevices?.[index.toString()]?.capacityInKW &&
                                            errors?.gasAdditionalDevices?.[index.toString()]?.capacityInKW &&
                                            'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                        }
                                    />
                                </div>
                                <span className={`${index === 0 ? 'mt-4 ' : ''} mr-0 md:mr-4`}>kW</span>
                                <div className="flex flex-col md:grid">
                                    {index === 0 && <span className="text-small">Anzahl</span>}
                                    <NumberInput
                                        name={`gasAdditionalDevices.${index}.amount`}
                                        {...NumberInputFunctions.registerNumberField(
                                            reactHookForm,
                                            `gasAdditionalDevices.${index}.amount`,
                                            {
                                                required: inputIsRequired,
                                                shouldUnregister: true,
                                                min: 1,
                                                max: 999,
                                                onChange: syncContext,
                                            },
                                        )}
                                        displayErrorMessage={
                                            touchedFields?.gasAdditionalDevices?.[index.toString()]?.amount &&
                                            errors?.gasAdditionalDevices?.[index.toString()]?.amount &&
                                            'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                        }
                                    />
                                </div>
                            </div>
                            <ButtonGroup
                                group="gasAdditionalDevices"
                                handleAppend={append}
                                handleDelete={remove(index)}
                                fields={fields}
                                index={index}
                                width="w-2/12 md:w-1/3"
                            />
                        </div>
                    );
                })}
            </div>
        </FormItem>
    );
};
