import {
    AddressLocation,
    CadastralDistrictLocation,
    SaxonyAnhaltOrThuringiaCadastralDistrictLocation,
    SaxonyCadastralDistrictLocation,
} from '@ten-netzkundenportal/ui-location-search';

import { Context } from '../wizard';
import { TelephoneData } from './common';
import {
    BusinessData,
    BusinessDataCompany,
    BusinessDataPrivate,
    CompanyData,
    CompanyDataProperties,
    PrivateData,
    PrivateDataProperties,
} from './contactData';

export const isPrivateData = (context: Partial<Context>): context is PrivateData & TelephoneData =>
    context.userType === 'private';

export const isCompanyData = (context: Partial<Context>): context is CompanyData & TelephoneData =>
    context.userType === 'company';

export const isBusinessData = (
    context: Partial<Context>,
): context is BusinessData & (BusinessDataPrivate | BusinessDataCompany) =>
    (context.userType === 'installer' || context.userType === 'projectpartner') &&
    (context as BusinessData).businessData !== undefined;

export const isBusinessDataPrivate = (
    context: Partial<Context>,
): context is BusinessDataPrivate & BusinessData & TelephoneData =>
    (context.userType === 'installer' || context.userType === 'projectpartner') &&
    (context as BusinessDataPrivate)?.businessData?.customerType === 'private';

export const isBusinessDataCompany = (
    context: Partial<Context>,
): context is BusinessDataCompany & BusinessData & TelephoneData =>
    (context.userType === 'installer' || context.userType === 'projectpartner') &&
    (context as BusinessDataCompany)?.businessData?.customerType === 'company';

export const isAddressSearchResult = (
    location: Partial<AddressLocation> | Partial<CadastralDistrictLocation>,
): location is AddressLocation => (location as AddressLocation)?.cityName !== undefined;

export const isContactDataPrivate = (
    contactData: PrivateDataProperties | CompanyDataProperties,
): contactData is PrivateDataProperties => (contactData as PrivateDataProperties).person !== undefined;

export const isSaxonyAnhaltOrThuringiaCadastralDistrictLocation = (
    location:
        | SaxonyCadastralDistrictLocation
        | SaxonyAnhaltOrThuringiaCadastralDistrictLocation
        | Partial<CadastralDistrictLocation>,
): location is SaxonyAnhaltOrThuringiaCadastralDistrictLocation =>
    (location as SaxonyAnhaltOrThuringiaCadastralDistrictLocation).cadastralDistrict !== undefined;
