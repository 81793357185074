import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';

type OfferReplyProps = {
    connectionRequestId: string;
    offerReply: OfferReply[];
};

export type OfferReply = {
    offerCommunicationId: string;
    acceptOffer: boolean;
};

export const sendOfferReply = async ({ params, customerId, axios }: ApiParams<OfferReplyProps>): Promise<void> => {
    await axios.patch(
        `${appConfig.services.connectionRequestFormApi}/customer/` +
            `${customerId}/connection-request/` +
            `${params.connectionRequestId}/response`,
        params.offerReply,
    );
};
