import { Column, ColumnItem, ColumnSection } from '@ten-netzkundenportal/ui-components';
import React from 'react';

import {
    Address,
    CompanyDataProperties,
    Person,
    isBusinessDataCompany,
    isBusinessDataPrivate,
    isCompanyData,
    isPrivateData,
} from '../../types';
import { formatPersonName } from '../../util/formatting';
import { Context } from '../../wizard';

const AddressItem = ({ address }: { address: Address }): React.ReactElement => {
    const { street, houseNumber, postalCode, city } = address;
    return <ColumnItem label="Adresse" text={[`${street} ${houseNumber}`, `${postalCode} ${city}`]} />;
};

const PersonDataItem = ({ person, address }: { person: Person; address: Address }): React.ReactElement => (
    <>
        <ColumnItem label="Name" text={formatPersonName(person)} />
        <AddressItem address={address} />
    </>
);

const CompanyDataItem = ({ companyName, address }: CompanyDataProperties): React.ReactElement => (
    <>
        <ColumnItem label="Firmenname" text={companyName} />
        <AddressItem address={address} />
    </>
);

const ContactDataItem = ({ context }: { context: Partial<Context> }): React.ReactElement => (
    <>
        {isPrivateData(context) && (
            <PersonDataItem person={context.privateData.person} address={context.privateData.address} />
        )}
        {isCompanyData(context) && (
            <CompanyDataItem companyName={context.companyData.companyName} address={context.companyData.address} />
        )}
        {(context.userType === 'installer' || context.userType === 'projectpartner') &&
            isBusinessDataPrivate(context) && (
                <PersonDataItem person={context.businessData.person} address={context.businessData.address} />
            )}
        {(context.userType === 'installer' || context.userType === 'projectpartner') &&
            isBusinessDataCompany(context) && (
                <CompanyDataItem
                    companyName={context.businessData.companyName}
                    address={context.businessData.address}
                />
            )}
    </>
);

export type AddressColumnProps = {
    context: Partial<Context>;
};

const AddressColumn = ({ context }: AddressColumnProps): React.ReactElement => (
    <Column title="Adressen">
        <ColumnSection title="Kundendaten">
            <ContactDataItem context={context} />
            <ColumnItem
                label="Telefonnummer"
                text={`+${context.telephone?.countryPrefix} ${context.telephone?.number?.trim()}`}
            />
        </ColumnSection>

        <ColumnSection title="Rechnungsadresse">
            {!context.isDeviatingInvoiceAddress && <ContactDataItem context={context} />}
            {context.isDeviatingInvoiceAddress && context.deviatingInvoiceAddressType === 'private' && (
                <PersonDataItem person={context.invoicePrivate.person} address={context.invoicePrivate.address} />
            )}

            {context.isDeviatingInvoiceAddress && context.deviatingInvoiceAddressType === 'company' && (
                <CompanyDataItem
                    companyName={context.invoiceCompany.companyName}
                    address={context.invoiceCompany.address}
                />
            )}
        </ColumnSection>
    </Column>
);

export default AddressColumn;
