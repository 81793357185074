import { AxiosResponse } from 'axios';

import appConfig from '../../app.config';
import { ApiParams } from '../../hooks/useApi';
import { GetOfferResponse } from '../types';

export type GetOfferParameter = {
    connectionRequestId: string;
    processCommunicationId: string;
};

const getOfferForCustomer = async ({
    params,
    customerId,
    axios,
}: ApiParams<GetOfferParameter>): Promise<GetOfferResponse> => {
    const { connectionRequestId, processCommunicationId } = params;

    const response: AxiosResponse = await axios.get(
        `${appConfig.services.connectionRequestFormApi}/customer/${customerId}/connection-request` +
            `/${connectionRequestId}/offer/${processCommunicationId}`,
    );

    if (response.status === 200) {
        return response.data;
    }
    throw new Error('Failed get offer for connection requests');
};

export default getOfferForCustomer;
