import {
    Energy,
    Form,
    FormItem,
    NumberInput,
    NumberInputFunctions,
    TextField,
} from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useFieldArray, useForm, useWatch } from 'react-hook-form';

import { TwoButton, createPrevNextButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import {
    BuildingDataProperties,
    Meta,
    PowerConsumptionTotalProperties,
    PowerConsumptionUnknownProperties,
    UpdateContextFunction,
} from '../../types';
import { deepEqual } from '../../util';
import getComparablePowerConsumptionDeclarations from '../../util/getComparablePowerConsumptionDeclarations';
import { useContextUpdate } from '../../wizard';
import InfrastructureFields from './InfrastructureFields';
import NumerationElement from './NumerationElement';
import calculation from './PowerConsumptionCalculation';

export type ConsumptionTotalFormProps = {
    onSubmit: (data: unknown) => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<BuildingDataProperties> &
        Partial<PowerConsumptionUnknownProperties> &
        Partial<PowerConsumptionTotalProperties> &
        Partial<Meta>;
};

const setCommercialUnits = (context): { type: string; capacity: number }[] => {
    const { building, commercialUnits } = context;
    if (!commercialUnits) return Array(building.commercialUnit).fill({ type: undefined, capacity: undefined });
    if (commercialUnits.length < building.commercialUnit) {
        return commercialUnits.concat(
            Array(building.commercialUnit - commercialUnits.length).fill({ type: undefined, capacity: undefined }),
        );
    }
    return commercialUnits.slice(0, building.commercialUnit);
};

export default ({ onSubmit, goBack, updateContext, context }: ConsumptionTotalFormProps): React.ReactElement => {
    const [calculatedConsumption, setCalculatedConsumption] = React.useState(context.powerConsumptionInKw);
    const [isReadyForCalculation, setIsReadyForCalculation] = React.useState(false);
    const { building } = context;
    const multiFamily = building.buildingType === 'multiFamily';
    const reactHookForm = useForm<PowerConsumptionTotalProperties>({
        mode: 'onTouched',
        shouldUnregister: false,
        defaultValues: {
            powerConsumptionInKw: context.powerConsumptionInKw,
            commercialUnits: setCommercialUnits(context),
            infrastructure: context.infrastructure,
        },
    });

    const {
        control,
        handleSubmit,
        formState: { isValid, errors, touchedFields },
        register,
        setValue,
        trigger,
        watch,
    } = reactHookForm;

    const { fields } = useFieldArray({
        control,
        name: 'commercialUnits',
        shouldUnregister: true,
    });

    const infrastructure = useWatch({ name: 'infrastructure', control });
    const commercialUnits = useWatch({ name: 'commercialUnits', control });

    const calculationFunction = React.useCallback(
        (newPowerConsumptionDeclarations, powerConsumptionDeclarationsInCurrentUse) => {
            if (isReadyForCalculation) {
                if (
                    !powerConsumptionDeclarationsInCurrentUse ||
                    !deepEqual(
                        getComparablePowerConsumptionDeclarations(powerConsumptionDeclarationsInCurrentUse),
                        newPowerConsumptionDeclarations,
                    )
                ) {
                    const sum = calculation(newPowerConsumptionDeclarations);
                    if (!Number.isNaN(sum)) {
                        setCalculatedConsumption(sum);
                        updateContext({
                            meta: {
                                powerConsumptionCalculationDeclarations: JSON.parse(
                                    JSON.stringify(newPowerConsumptionDeclarations),
                                ),
                            },
                        });
                    }
                }
            }
        },
        /* eslint-disable react-hooks/exhaustive-deps */
        [isReadyForCalculation, setCalculatedConsumption],
    );

    React.useEffect(() => {
        const powerConsumptionDeclarations = getComparablePowerConsumptionDeclarations({
            ...context,
            infrastructure,
            commercialUnits,
            building,
        });
        calculationFunction(powerConsumptionDeclarations, context.meta.powerConsumptionCalculationDeclarations);
    }, [
        calculationFunction,
        infrastructure,
        commercialUnits,
        building,
        context.electricHeater,
        context.electricService,
        context.electricWarmWater,
        context.electricalWaterHeating,
        context.extractionPlant,
        context.extractionPlantLessThan30,
        context.chargingStation,
        context.chargingInfrastructure,
    ]);

    React.useEffect(() => {
        setValue('powerConsumptionInKw', calculatedConsumption, { shouldValidate: true });
    }, [setValue, calculatedConsumption]);

    React.useEffect(() => {
        if (
            !multiFamily ||
            commercialUnits?.filter((element) => typeof element.capacity === 'number').length ===
                building.commercialUnit
        ) {
            const commercialUnitsFields = Array.from({ length: fields.length }, (_, index) => [
                `commercialUnits.${index}.type`,
                `commercialUnits.${index}.capacity`,
            ]).flat();

            const infrastructureFields = Array.from({ length: infrastructure.length }, (_, index) => [
                `infrastructure.${index}.name`,
                `infrastructure.${index}.amount`,
                `infrastructure.${index}.capacity`,
            ]).flat();

            trigger(commercialUnitsFields.concat(infrastructureFields, ['commercialUnits', 'infrastructure']) as never)
                .then((errorFree) => setIsReadyForCalculation(errorFree))
                .catch(() => setIsReadyForCalculation(false));
        } else {
            setIsReadyForCalculation(false);
        }
    }, [commercialUnits, building.commercialUnit, infrastructure, multiFamily, trigger, fields.length]);
    useContextUpdate(watch, updateContext, 'CONSUMPTION_POWER_CONSUMPTION_TOTAL');

    const [prevButton, nextButton] = createPrevNextButton({
        onBack: goBack,
        onNext: handleSubmit(onSubmit),
        isNextValid: isValid,
    });

    return (
        <Form title="Leistungsbedarf Strom">
            {multiFamily && <InfrastructureFields reactHookForm={reactHookForm} />}
            {multiFamily && building.commercialUnit > 0 && (
                <>
                    <div className="grid grid-flow-col items-center">
                        <span className="font-bold">Gewerbeeinheiten</span>
                    </div>
                    <FormLayout>
                        <FormItem
                            label="Welche Leistung werden Sie für Ihre Gewerbeeinheiten jeweils benötigen?"
                            className="items-start grid-cols-[1fr_4fr] flex flex-col gap-y-3 w-full"
                        >
                            {fields.map((field, index) => (
                                <div className="flex flex-row items-center justify-start w-full gap-x-6" key={field.id}>
                                    <div className="flex items-center justify-start sm:w-2/3 gap-x-2 ml-px mb-1 w-full">
                                        <div className="grid" style={{ minWidth: '2.5rem' }}>
                                            {index === 0 && <span className="text-small">Einheit</span>}
                                            <NumerationElement value={(index + 1).toString(10)} />
                                        </div>
                                        <div className="grid w-full">
                                            {index === 0 && (
                                                <span className="text-small">
                                                    Gewerbeart
                                                    <span className="ml-1 text-darkest-grey">(optional)</span>
                                                </span>
                                            )}
                                            <TextField
                                                name={`commercialUnits.${index}.type`}
                                                {...register(`commercialUnits.${index}.type` as const, {
                                                    minLength: 2,
                                                    maxLength: 60,
                                                })}
                                                displayErrorMessage={
                                                    errors?.commercialUnits?.[index.toString()]?.type &&
                                                    'Bitte geben Sie eine Gewerbeart mit 2-60 Zeichen an.'
                                                }
                                            />
                                        </div>
                                        <div className="grid">
                                            {index === 0 && <span className="text-small">Leistung</span>}
                                            <NumberInput
                                                name={`commercialUnits.${index}.capacity`}
                                                {...NumberInputFunctions.registerNumberInput(
                                                    reactHookForm,
                                                    `commercialUnits.${index}.capacity`,
                                                    {
                                                        required: true,
                                                        shouldUnregister: true,
                                                        min: 1,
                                                        max: 999,
                                                    },
                                                )}
                                                displayErrorMessage={
                                                    errors?.commercialUnits?.[index.toString()]?.capacity &&
                                                    'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                                }
                                            />
                                        </div>
                                        <span className={`${index === 0 ? 'mt-4' : ''}`}>kW</span>
                                    </div>
                                </div>
                            ))}
                        </FormItem>
                    </FormLayout>
                </>
            )}

            <div className="flex flex-row justify-center shadow-ten w-full xl:w-[1195px] xl:h-[128px]">
                <div className="w-full h-full self-center grid auto-rows-auto p-6 gap-y-2">
                    <div className="flex">
                        <div className="w-6 self-center text-primary fill-current" style={{ marginLeft: '-5px' }}>
                            <Energy />
                        </div>
                        <div className="self-center font-bold">Leistungsbedarf Strom gesamt</div>
                    </div>
                    <div className="grid grid-flow-row sm:grid-flow-col auto-cols-auto gap-y-4 sm:gap-x-8">
                        <div className="self-center">
                            Basierend auf Ihren Gebäudeangaben und dem angegebenen Leistungsbedarf kalkulieren wir eine
                            benötigte Gesamtleistung für Strom von circa:
                        </div>
                        <div className="flex h-full w-full mx-0 sm:mx-6 justify-self-start sm:justify-self-end">
                            <div>
                                <NumberInput
                                    name="powerConsumptionInKw"
                                    disabled={!isReadyForCalculation}
                                    {...NumberInputFunctions.registerNumberInput(
                                        reactHookForm,
                                        'powerConsumptionInKw',
                                        {
                                            required: true,
                                            shouldUnregister: true,
                                            min: 1,
                                        },
                                    )}
                                    displayErrorMessage={
                                        isReadyForCalculation &&
                                        touchedFields?.powerConsumptionInKw &&
                                        errors?.powerConsumptionInKw &&
                                        'Bitte geben Sie eine Zahl größer als 0 ein.'
                                    }
                                />
                            </div>
                            <span className="mt-2 ml-2">kW</span>
                        </div>
                    </div>
                </div>
            </div>
            <TwoButton leftButton={prevButton} rightButton={nextButton} />
        </Form>
    );
};
