import { AxiosResponse } from 'axios';

import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';

export type GetDocumentParameter = {
    processCommunicationId: string;
    documentId: string;
    connectionRequestId: string;
};

const getExternDocument = async ({ params, axios, customerId }: ApiParams<GetDocumentParameter>): Promise<Blob> => {
    const { processCommunicationId, documentId, connectionRequestId } = params;

    const response: AxiosResponse = await axios.get(
        `${appConfig.services.connectionRequestFormApi}/account/${customerId}/connection-request/${connectionRequestId}/offer/${processCommunicationId}/document/${documentId}`,
        {
            responseType: 'blob',
        },
    );

    return response.data;
};

export default getExternDocument;
