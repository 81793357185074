import { ElectricityOffer, GasOffer, ManualElectricityOffer, ManualGasOffer } from '../types';
import { GetOfferResponseElectricity, GetOfferResponseGas } from './types';

export const isGetOfferResponseElectricity = (
    response: GetOfferResponseElectricity | GetOfferResponseGas,
): response is GetOfferResponseElectricity => response.branch === 'electricity';

export const isElectricityOffer = (offer: GasOffer | ElectricityOffer): offer is ElectricityOffer =>
    'connectionLength' in offer;

export const isGasOffer = (offer: GasOffer | ElectricityOffer): offer is GasOffer =>
    'connectionLengthCadastralDistrictToBuilding' in offer;

export const isManualElectricityOffer = (
    offer: ManualGasOffer | ManualElectricityOffer,
): offer is ManualElectricityOffer => 'connection' in offer && offer.branch === 'electricity';
