import { UpdateContextFunction } from '../types';
import { SUBPROCESS_VALUE } from '../wizard';

export const resetDrawingData = (updateContext: UpdateContextFunction, process: SUBPROCESS_VALUE) => {
    updateContext(
        {
            meta: { connectionPlanFormState: undefined },

            houseShapes: [],

            powerMarker: undefined,
            gasMarker: undefined,
            multiEntryMarker: undefined,

            isElectricityLineCourseValid: undefined,
            isGasLineCourseValid: undefined,

            electricityConnectionLength: undefined,
            gasConnectionLengthCadastralDistrictToBuilding: undefined,
            gasConnectionLengthMainLineToCadastralDistrict: undefined,

            electricityLineCourse: undefined,
            gasLineCourse: undefined,
        },
        process,
    );
};
