/*  eslint-disable import/no-unresolved */
import { Plus, Trash } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { FieldArrayPath, FieldValues } from 'react-hook-form';
import { FieldArrayWithId } from 'react-hook-form/dist/types/fieldArray';

export interface AddRemoveButtonGroupProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
> {
    group: string;
    fields: FieldArrayWithId<TFieldValues, TFieldArrayName, TKeyName>[];
    index: number;
    handleAppend: () => void;
    handleDelete: () => void;
    width?: string;
}

export default ({
    group,
    handleAppend,
    fields,
    index,
    handleDelete,
    width = 'w-14',
}: AddRemoveButtonGroupProps): React.ReactElement => (
    <div className={`flex items-center ${width} h-10 gap-x-2 md:gap-x-5 `}>
        <div
            className={`icon transition duration-200 ease-in-out fill-current
            ${
                fields.length !== 1
                    ? 'text-primary hover:text-dark-blue cursor-pointer'
                    : 'text-dark-grey cursor-not-allowed'
            }
            ${index === 0 ? 'mt-4' : ''}`}
            onClick={handleDelete}
            onKeyDown={(event) => {
                if (event.key === ' ') {
                    handleDelete();
                    event.preventDefault();
                }
            }}
            id={`trash-button-${group}`}
            role="button"
            aria-label="delete row"
            tabIndex={0}
        >
            <Trash />
        </div>

        {fields.length === index + 1 && (
            <div
                className={`cursor-pointer icon fill-current text-primary hover:text-dark-blue ${
                    index === 0 ? 'mt-4' : ''
                }`}
                onClick={handleAppend}
                onKeyDown={(event) => {
                    if (event.key === ' ') {
                        handleAppend();
                        event.preventDefault();
                    }
                }}
                id={`plus-button-${group}`}
                role="button"
                aria-label="add new row"
                tabIndex={0}
            >
                <Plus />
            </div>
        )}
    </div>
);
