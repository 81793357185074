import { InstallerFilterResult } from '@ten-netzkundenportal/ui-installer-selection/';

import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';
import { Branch } from '../../../../types/branch';

const crfServiceUrl = `${appConfig.services.connectionRequestFormApi}`;

type SetOfferInstallerResult = {
    status: number;
};

type AddInstallerToConnectionRequestParams = {
    connectionRequestId: string;
    installer: InstallerFilterResult;
    branch: Branch;
};

const updateInstaller = async ({
    params,
    customerId,
    axios,
}: ApiParams<AddInstallerToConnectionRequestParams>): Promise<void> => {
    const result = await axios.post<SetOfferInstallerResult>(
        `${crfServiceUrl}/account/${customerId}/connection-request/${params.connectionRequestId}/installer`,
        {
            installer: params.installer,
            branch: params.branch,
        },
    );

    if (result.status === 200) {
        return;
    }

    if (result.status === 404) {
        throw new Error('Connection request not found.');
    }

    if (result.status === 409) {
        throw new Error('Invalid installer for connection request.');
    }

    throw new Error('Failed to assign installer to connection request.');
};

export default updateInstaller;
