import { AddressLocation, CadastralDistrictLocation } from '@ten-netzkundenportal/ui-location-search';

import { ServerAddressLocation, isSaxonyAnhaltOrThuringiaCadastralDistrictLocation } from '../../types';
import { Context } from '../../wizard';

export default (context: Partial<Context>): ServerAddressLocation | CadastralDistrictLocation => {
    if (context.location.geojson.type === 'Point') {
        const addressLocation = context.location as AddressLocation;
        return {
            streetName: addressLocation.streetName,
            cityName: addressLocation.cityName,
            districtName: addressLocation.districtName,
            postcode: addressLocation.postcode,
            housenumber: addressLocation.housenumber ?? undefined,
            housenumberAddition: addressLocation.housenumberAddition ?? undefined,
            geojson: addressLocation.geojson,
            epsgCode: addressLocation.epsgCode,
            cadastralDistrictCoordinates: addressLocation.cadastralDistrictCoordinates,
        };
    }
    const cadastralDistrictLocation = context.location as CadastralDistrictLocation;
    return {
        stateCode: cadastralDistrictLocation.stateCode,
        districtId: cadastralDistrictLocation.districtId,
        districtName: cadastralDistrictLocation.districtName,
        cadastralDistrict: isSaxonyAnhaltOrThuringiaCadastralDistrictLocation(cadastralDistrictLocation)
            ? (cadastralDistrictLocation.cadastralDistrict ?? undefined)
            : undefined,
        cadastralUnitCounter: cadastralDistrictLocation.cadastralUnitCounter ?? undefined,
        cadastralUnitDenominator: cadastralDistrictLocation.cadastralUnitDenominator ?? undefined,
        geojson: cadastralDistrictLocation.geojson,
        epsgCode: cadastralDistrictLocation.epsgCode,
    };
};
