import { useMsal } from '@azure/msal-react';
import { AuthenticatedContent, TokenClaims } from '@ten-netzkundenportal/ui-auth-utils';
import { LoadingSpinner } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { Route, Switch, useParams } from 'react-router-dom';

import useApi from '../../hooks/useApi';
import {
    OFFER_REVISITED_CONCLUSION,
    OFFER_REVISITED_DOCUMENT,
    OFFER_REVISITED_DOCUMENT_UPLOAD,
    OFFER_REVISITED_INSTALLER_SELECTION,
    OFFER_REVISITED_OVERVIEW,
} from '../../routes';
import { Option, UserType } from '../../types';
import getOfferForCustomer from '../api/getOfferForCustomer';
import getOfferForProjectPartner from '../api/getOfferForProjectPartner';
import { isGetOfferResponseElectricity } from '../guards';
import { GetOfferResponse, MappedQuerySelectedOfferResponse } from '../types';
import Conclusion from './conclusion';
import DocumentUploadForm from './document/DocumentUploadForm';
import OfferRevisitedDocument from './document/OfferRevisitedDocument';
import OfferRevisitedInstallerSelection from './installerSelection';
import Offer from './offer';
import { mapQuerySelectedOfferResponseToProps } from './util';

export type OfferRevisitedChildrenProps = {
    mappedData: MappedQuerySelectedOfferResponse;
    personalContribution: Option[];
    userType: UserType;
};

function computePersonalContribution(offer: GetOfferResponse) {
    return [
        offer?.contributionPrices?.multiEntryNetPrice && {
            type: 'Durchbruch / Mehrspartenhauseinführung',
            value: offer.contributionPrices.multiEntryNetPrice,
        },
        offer?.contributionPrices?.civilEngineeringNetPrice && {
            type: 'Tiefbau',
            value: offer.contributionPrices.civilEngineeringNetPrice,
        },
        isGetOfferResponseElectricity(offer) &&
            offer?.contributionPrices?.counterNetPrice && {
                type: 'Bereitstellung Hausanschlusskasten über Zähleranschlusssäule',
                value: offer.contributionPrices.counterNetPrice,
            },
    ].filter((v) => v);
}

const OfferRevisited = (): React.ReactElement => {
    const { connectionRequestId, processCommunicationId } = useParams();

    const { accounts } = useMsal();
    const userType =
        accounts.length > 0
            ? ((accounts[0].idTokenClaims as TokenClaims).extension_customerType as UserType)
            : undefined;

    const getOfferApi = useApi(userType === 'projectpartner' ? getOfferForProjectPartner : getOfferForCustomer);

    const [mappedData, setMappedData] = React.useState<MappedQuerySelectedOfferResponse>();
    const [personalContribution, setPersonalContribution] = React.useState<Option[]>([]);
    const [showError, setShowError] = React.useState<boolean>(false);

    React.useEffect(() => {
        setShowError(false);
        getOfferApi({
            connectionRequestId,
            processCommunicationId,
        })
            .then((response) => {
                setMappedData(mapQuerySelectedOfferResponseToProps(response));
                setPersonalContribution(computePersonalContribution(response));
            })
            .catch((e) => {
                console.error('Failed to selected offer!', e);
                setMappedData(undefined);
                setPersonalContribution(undefined);
                setShowError(true);
            });
    }, [connectionRequestId, processCommunicationId, getOfferApi]);

    if (showError) {
        return (
            <div className="w-full flex flex-col gap-y-12 py-10 relative">
                Leider ist ein technischer Fehler aufgetreten. Bitte versuchen Sie es zu einem späteren Zeitpunkt
                erneut.
            </div>
        );
    }

    return (
        <AuthenticatedContent>
            <React.Suspense
                fallback={
                    <div className="w-full h-[50rem] flex flex-col items-center">
                        <div className=" flex flex-row min-h-full flex-grow items-center">
                            <div className="w-20 h-20">
                                <LoadingSpinner />
                            </div>
                        </div>
                    </div>
                }
            >
                <Switch>
                    <Route path={OFFER_REVISITED_OVERVIEW}>
                        <Offer
                            mappedData={mappedData}
                            personalContribution={personalContribution}
                            userType={userType}
                        />
                    </Route>
                    <Route path={OFFER_REVISITED_CONCLUSION}>
                        <Conclusion mappedData={mappedData} personalContribution={personalContribution} />
                    </Route>
                    <Route path={OFFER_REVISITED_INSTALLER_SELECTION}>
                        <OfferRevisitedInstallerSelection mappedData={mappedData} />
                    </Route>

                    <Route path={OFFER_REVISITED_DOCUMENT}>
                        <OfferRevisitedDocument />
                    </Route>

                    <Route path={OFFER_REVISITED_DOCUMENT_UPLOAD}>
                        <DocumentUploadForm />
                    </Route>
                </Switch>
            </React.Suspense>
        </AuthenticatedContent>
    );
};

export default OfferRevisited;
