import { YesNo } from './common';

export type GasConsumptionIsKnownProperty = {
    gasConsumptionKnown: boolean;
};

export type GasConsumptionInKw = {
    gasConsumptionInKw: number;
};

// TODO: Move somewhere else
export const GasHeatings = [
    {
        type: 'Brennwertgerät mit Warmwasser',
        capacityInKW: 20,
    },
    {
        type: 'Brennwertgerät ohne Warmwasser',
        capacityInKW: 15,
    },
    {
        type: 'Heizkessel mit Warmwasser',
        capacityInKW: 20,
    },
    {
        type: 'Heizkessel ohne Warmwasser',
        capacityInKW: 15,
    },
];
const gasHeatingsTypes = GasHeatings.map((gasHeating) => gasHeating.type);
export const GasHeatingOptions = gasHeatingsTypes.map((type) => ({ value: type, label: type }));
export type GasHeatingTypes = (typeof gasHeatingsTypes)[number];

// TODO: Move somewhere else
export const GasDevices = [
    {
        type: '',
        capacityInKW: undefined,
    },
    {
        type: 'BHKW',
        capacityInKW: 25,
    },
    {
        type: 'Brennstoffzelle',
        capacityInKW: 25,
    },
    {
        type: 'Durchlaufwasserheizer',
        capacityInKW: 18,
    },
    {
        type: 'Umlaufwasserheizer',
        capacityInKW: 15,
    },
];
const gasDevicesTypes = GasDevices.map((gasDevice) => gasDevice.type);
export const GasDevicesOptions = gasDevicesTypes.map((type) => ({ value: type, label: type }));
export type GasDevicesTypes = (typeof gasDevicesTypes)[number];

export type GasConsumptionUnknownData = {
    gasHeating: YesNo;
    gasOutlet: YesNo;
    gasHeatings: { type?: GasHeatingTypes; amount?: number; capacityInKW?: number }[];
    gasDevices: { type?: GasDevicesTypes; amount?: number; capacityInKW?: number }[];
    gasOutlets: { amount?: number };
    gasAdditionalDevices: { name?: string; amount?: number; capacityInKW?: number }[];
    gasConsumptionInKw: number;
};

export type GasConsumptionProperties = GasConsumptionIsKnownProperty & GasConsumptionInKw & GasConsumptionUnknownData;
