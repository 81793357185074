import { MaintenanceCard } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { LOCATION_DATA, START } from '../routes';
import { isMaintenanceActive } from '../util/maintenance';

const Maintenance = (): React.ReactElement => {
    const history = useHistory();

    const handleTryAgainButtonClick = async () => {
        localStorage.removeItem('antragsstrecke');
        if (await isMaintenanceActive()) {
            window.location.reload();
        } else {
            window.location.replace(LOCATION_DATA);
        }
    };

    return <MaintenanceCard onBack={() => history.push(START)} onTryAgain={handleTryAgainButtonClick} />;
};

export default Maintenance;
