import L from 'leaflet';

import electricityConnectionIcon from '../assets/electricityConnectionIcon.svg';
import gasConnectionIcon from '../assets/gasConnectionIcon.svg';
import multiConnectionIcon from '../assets/multiConnectionIcon.svg';
import { pathOptions } from '../connection/connection-plan-form/buildingAndConnectionPlan/ShapeButtonBox';
import { HouseShape } from '../types';

export type HouseShapeWithKey = {
    key: number;
    value: HouseShape;
};

export const iconMap = {
    Electricity: electricityConnectionIcon,
    Gas: gasConnectionIcon,
    MultiEntry: multiConnectionIcon,
};

export const gasPolylineColor = '#ffc107';

export const electricityPolylineColor = '#33ab3e';

export const getMarkerOptions = (mT: MarkerToolType): L.MarkerOptions => ({
    icon: L.icon({
        iconUrl: iconMap[mT],
        iconSize: [20, 20],
        iconAnchor: [10, 10],
        shadowSize: [0, 0],
    }),
    opacity: 0.85,
});

export const polyLineDesign = {
    weight: 3,
    opacity: 0.85,
    smoothFactor: 1,
    dashArray: '4 8',
};

export type MarkerToolType = 'Electricity' | 'Gas' | 'MultiEntry';

export const drawHouses = (houses: HouseShapeWithKey[], map: L.Map) => {
    const allLayer: { layer: L.Polygon; shape: HouseShapeWithKey }[] = [];
    houses.forEach((shape) => {
        const { latLngs, shapeType, angle } = shape.value;

        const layer =
            shapeType && shapeType === 'Rectangle'
                ? L.rectangle(L.polygon(latLngs).getBounds(), pathOptions).setLatLngs(latLngs)
                : L.polygon(latLngs, pathOptions);
        layer.pm.setOptions({ removeLayerBelowMinVertexCount: false });
        map.addLayer(layer);
        if (shapeType === 'Rectangle' && angle) {
            // set the angle property so editing loaded rectangles does not reset their rotation
            // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/dot-notation
            layer.pm['_angle'] = angle;
        }
        allLayer.push({ layer, shape });
    });
    return allLayer;
};

export const drawMarker = (coordinate: L.LatLng, markerToolType: MarkerToolType, map: L.Map) => {
    const marker = L.marker(coordinate, getMarkerOptions(markerToolType));
    map.addLayer(marker);
    return marker;
};

export const drawLineCourse = (coordinates: L.LatLng[], type: 'Electricity' | 'Gas', map: L.Map) => {
    const electricityPolyline =
        type === 'Electricity'
            ? L.polyline(coordinates, {
                  ...polyLineDesign,
                  color: electricityPolylineColor,
              })
            : undefined;
    const gasPolyline =
        type === 'Gas'
            ? L.polyline(coordinates, {
                  ...polyLineDesign,
                  color: gasPolylineColor,
              })
            : undefined;
    if (electricityPolyline) {
        map.addLayer(electricityPolyline);
    }
    if (gasPolyline) {
        map.addLayer(gasPolyline);
    }
};
