import { Address, isBusinessData, isCompanyData, isPrivateData } from '../types';
import { Branch } from '../types/branch';
import { Context } from '../wizard';
import { MaterialList } from './api/material-costs/MaterialPriceAPI';

export const getPersonalContribution = (
    isElectricity: boolean,
    materialList: MaterialList,
    multiEntry: boolean,
    civiEngineering: boolean,
    counter?: boolean,
) => {
    const personalContribution = isElectricity
        ? [
              multiEntry && {
                  type: 'Durchbruch / Mehrspartenhauseinführung',
                  value: materialList.VTEPHAEGMK.price.net,
              },
              civiEngineering && {
                  type: 'Tiefbau',
                  value: materialList.VTEPEKNGEL.price.net,
              },
              counter && {
                  type: 'Bereitstellung Hausanschlusskasten über Zähleranschlusssäule',
                  value: materialList.VTEPZASG.price.net,
              },
          ]
        : [
              multiEntry && {
                  type: 'Durchbruch / Mehrspartenhauseinführung',
                  value: materialList.VTEPGHEKB.price.net,
              },
              civiEngineering && {
                  type: 'Tiefbau',
                  value: materialList.VTEPGN25GEL.price.net,
              },
          ];

    return personalContribution.filter((contribution) => contribution && contribution.type);
};
export const findCorrectAddress = (context: Partial<Context>): Address => {
    if (isPrivateData(context)) {
        return context.privateData.address;
    }
    if (isCompanyData(context)) {
        return context.companyData.address;
    }
    if (isBusinessData(context)) {
        return context.businessData.address;
    }

    throw new Error('No suitable address could be found!');
};

export const getHint = (offerType: Branch) => {
    const hintElectricity =
        'Die Hausanschlusskosten enthalten die Aufwendungen für ' +
        'Kabelverlegung, -anschluss, Mauerdurchbruch, Hausanschlusskasten und die ' +
        'Inbetriebnahme des Netzanschlusses.';
    const hintGas =
        'Die Hausanschlusskosten enthalten die Aufwendungen für Leitungsverlegung, -anschluss, ' +
        'Mauerdurchbruch, Hausanschlusskasten und die Inbetriebnahme des Netzanschlusses.';
    return offerType === 'electricity' ? hintElectricity : hintGas;
};
