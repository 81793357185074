import { Button, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useForm } from 'react-hook-form';

import { TwoButton } from '../../components';
import FormLayout from '../../components/SignupForm/FormLayout';
import { PowerConsumptionKnownProperties, UpdateContextFunction, UserTypeProperties } from '../../types';
import { useContextUpdate } from '../../wizard';

interface Props {
    onSubmit: () => void;
    goBack: () => void;
    updateContext: UpdateContextFunction;
    context: Partial<PowerConsumptionKnownProperties & UserTypeProperties>;
}

export default ({ onSubmit, goBack, updateContext, context }: Props): React.ReactElement => {
    const reactHookForm = useForm<PowerConsumptionKnownProperties>({
        mode: 'onTouched',
        shouldUnregister: true,
        defaultValues: {
            powerConsumptionInKw: context.powerConsumptionInKw,
        },
    });
    const {
        handleSubmit,
        watch,
        formState: { isValid, errors },
    } = reactHookForm;
    useContextUpdate(watch, updateContext, 'CONSUMPTION_POWER_CONSUMPTION_DECLARATION');

    const nextButton = <Button label="Weiter" onClick={handleSubmit(onSubmit)} type="primary" disabled={!isValid} />;
    const prevButton = <Button type="secondary" label="Zurück" onClick={goBack} />;

    return (
        <>
            <FormLayout>
                <FormItem
                    label={
                        context.userType === 'installer' || context.userType === 'projectpartner'
                            ? 'Welche Leistung wird Ihr Kunde insgesamt benötigen?'
                            : 'Welche Leistung werden Sie insgesamt benötigen?'
                    }
                    className="items-center gap-x-10"
                >
                    <div className="flex">
                        <NumberInput
                            name="consumption"
                            {...NumberInputFunctions.registerNumberInput(reactHookForm, 'powerConsumptionInKw', {
                                required: true,
                                shouldUnregister: true,
                                min: 1,
                            })}
                            displayErrorMessage={
                                errors?.powerConsumptionInKw && 'Bitte geben Sie eine Zahl größer als 0 ein.'
                            }
                        />
                        <span className="ml-2 mt-2">kW</span>
                    </div>
                </FormItem>
            </FormLayout>
            <TwoButton leftButton={prevButton} rightButton={nextButton} />
        </>
    );
};
