import { SpecialBuildingType } from '../../types';

// TODO we need to discuss where to place these files with dropdown options
const options: { value: SpecialBuildingType; label: string }[] = [
    {
        value: 'vacationHouse',
        label: 'Ferienhaus',
    },
    {
        value: 'garage',
        label: 'Garage',
    },
    {
        value: 'garden',
        label: 'Garten',
    },
    {
        value: 'warehouse',
        label: 'Lagerhalle',
    },
    {
        value: 'other',
        label: 'Sonstiges (z.B. Funkstation)',
    },
];

export default options;
