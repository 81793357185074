import L from 'leaflet';
import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';

import { HouseShape, HouseShapeType, UpdateContextFunction } from '../../../types';
import { HouseShapeWithKey, drawHouses } from '../../../util/drawing';
import { Context } from '../../../wizard';
import { ToolType } from '../Toolbox';
import ToolButton from './ToolButton';
import { shapeListener } from './createdLayerListener';

export type ShapeButtonBoxProperties = {
    currentTool: ToolType;
    setCurrentTool: (tool: ToolType) => void;
    updateContext: UpdateContextFunction;
    currentProcessStep: number;
    context: Partial<Context>;
};

export const pathOptions = {
    color: 'rgb(13, 103, 157)',
    fillColor: 'rgb(13, 103, 157)',
    weight: 2,
};

const shapeListenerNames = 'pm:edit pm:rotate pm:remove pm:drag pm:markerdrag';
export type ShapeToolType = 'Freehand' | 'Rectangle' | 'Rotate' | 'Remove' | 'Drag' | 'Edit';
export default ({
    currentTool,
    setCurrentTool,
    updateContext,
    context,
    currentProcessStep,
}: ShapeButtonBoxProperties): React.ReactElement => {
    const map = useMap();

    const [houseShapeList] = React.useState<HouseShapeWithKey[]>(
        context.houseShapes.map((shape, index) => ({ key: index, value: shape })),
    );

    map.pm.setPathOptions({ ...pathOptions }, { merge: true });

    const updateShape = useCallback(
        (id, shape) => {
            const shapeToUpdate = houseShapeList.find((shapeEntry) => shapeEntry.key === id);
            if (shapeToUpdate) {
                houseShapeList.splice(houseShapeList.indexOf(shapeToUpdate), 1);
            }
            houseShapeList.push({ key: id, value: shape });
            updateContext({
                houseShapes: houseShapeList.map((shapeEntry) => shapeEntry.value),
            });
        },
        [houseShapeList, updateContext],
    );

    const deleteShape = useCallback(
        (id) => {
            const shapeToUpdate = houseShapeList.find((shapeEntry) => shapeEntry.key === id);
            houseShapeList.splice(houseShapeList.indexOf(shapeToUpdate), 1);
            updateContext({
                houseShapes: houseShapeList.map((shapeEntry) => shapeEntry.value),
            });
            map.pm.disableGlobalRemovalMode();
            setCurrentTool('none');
        },
        [houseShapeList, map.pm, updateContext, setCurrentTool],
    );

    const createShape = useCallback(
        (event) => {
            const { shape, layer } = event;
            if (shape !== 'Marker') {
                const houseShape: HouseShape = {
                    shapeType: shape as HouseShapeType,
                    // getLatLngs returns a multi polygon (array) with one polygon (array)
                    latLngs: (layer as L.Polygon).getLatLngs()[0] as L.LatLng[],
                };
                const newMaxLayerId =
                    houseShapeList.reduce(
                        (previousValue, currentValue) =>
                            previousValue.key < currentValue.key ? currentValue : previousValue,
                        { key: 0 },
                    ).key + 1;
                updateShape(newMaxLayerId, houseShape);
                layer.removeEventListener(shapeListenerNames);
                layer.pm.setOptions({ removeLayerBelowMinVertexCount: false });
                layer.on(
                    shapeListenerNames,
                    shapeListener(newMaxLayerId, shape as HouseShapeType, updateShape, deleteShape),
                );
            }
        },
        [deleteShape, houseShapeList, updateShape],
    );

    // add drawn shapes and markers to map, only executed the first time the Toolbox gets drawn
    useEffect(() => {
        const layers = drawHouses(houseShapeList, map);
        layers.forEach(({ layer, shape }) => {
            layer.on(shapeListenerNames, shapeListener(shape.key, shape.value.shapeType, updateShape, deleteShape));
        });

        // prevent Rectangle from appearing in the center of the map after clicking "Frei zeichnen"
        map.on('pm:drawstart', () => {
            map.fireEvent('mousemove', {
                latlng: L.latLng(0, 0),
            });
        });

        // this needs to be executed only once when the component is loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            currentTool === 'Freehand' ||
            currentTool === 'Rectangle' ||
            currentTool === 'Rotate' ||
            currentTool === 'Remove' ||
            currentTool === 'Drag' ||
            currentTool === 'Edit'
        ) {
            map.removeEventListener('pm:create');
            map.once('pm:create', createShape);
        }
    }, [createShape, currentTool, map]);

    return (
        <div className="grid grid-cols-2 mb-1 gap-2 mx-2">
            <ToolButton
                buttonType="Freehand"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
            <ToolButton
                buttonType="Rectangle"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
            <ToolButton
                buttonType="Edit"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
            <ToolButton
                buttonType="Rotate"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
            <ToolButton
                buttonType="Drag"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
            <ToolButton
                buttonType="Remove"
                currentProcessStep={currentProcessStep}
                currentTool={currentTool}
                setCurrentTool={setCurrentTool}
            />
        </div>
    );
};
