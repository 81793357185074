import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';

const serviceUrl = appConfig.services.connectionRequestFormApi;

export const createSas = async ({
    customerId,
    axios,
    params,
}: ApiParams<{
    connectionRequestId: string;
    processCommunicationId: string;
}>) => {
    const response = await axios.get<{ sas: string; storageAccount: string }>(
        `${serviceUrl}/account/${customerId}/connection-request/${params.connectionRequestId}/offer/${params.processCommunicationId}/sas`,
    );

    return response.data;
};
