import { ElectricHeaterType, ElectricWaterHeaterType, Electricity } from '../types';
import { Context } from '../wizard';

const transformInfrastructure = (
    infrastructures: { name: string; amount: number | string; capacity: number | string }[],
) => {
    if (infrastructures === undefined) return [];

    const transformedInfrastructure = infrastructures.map((infrastructure) => {
        if (infrastructure.amount === '' || infrastructure.capacity === '') {
            return undefined;
        }
        return {
            name: infrastructure.name,
            amount: +infrastructure.amount,
            capacity: +infrastructure.capacity,
        };
    });
    return transformedInfrastructure.filter((inf) => inf !== undefined);
};

export default (context: Partial<Context>): Electricity =>
    context.power
        ? {
              predefinedConsumption: context.powerConsumptionInKw,
              heater:
                  !context.powerConsumptionKnown && context.electricHeater && context.electricService?.length > 0
                      ? context.electricService?.map((electricHeater) => ({
                            type: electricHeater.type as ElectricHeaterType,
                            capacity: electricHeater.capacity,
                        }))
                      : undefined,
              waterHeater:
                  !context.powerConsumptionKnown &&
                  context.electricWarmWater &&
                  context.electricalWaterHeating?.length > 0
                      ? context.electricalWaterHeating?.map((electricWaterHeating) => ({
                            type: electricWaterHeating.type as ElectricWaterHeaterType,
                            capacity: electricWaterHeating.capacity,
                        }))
                      : undefined,
              extractionPlant: !context.powerConsumptionKnown ? context.extractionPlant : undefined,
              extractionPlantLessThan30:
                  !context.powerConsumptionKnown && context.extractionPlant
                      ? context.extractionPlantLessThan30 === 'yes'
                      : undefined,
              chargingStation: !context.powerConsumptionKnown ? context.chargingStation : undefined,
              chargingInfrastructure:
                  !context.powerConsumptionKnown &&
                  context.chargingStation &&
                  context.chargingInfrastructure?.length > 0
                      ? context.chargingInfrastructure?.map((chargingInfrastructure) => ({
                            capacity: chargingInfrastructure.capacity,
                            amount: chargingInfrastructure.amount,
                        }))
                      : undefined,
              infrastructure:
                  transformInfrastructure(context.infrastructure).length > 0
                      ? transformInfrastructure(context.infrastructure)
                      : undefined,
              commercialUnits: context.commercialUnits?.length > 0 ? context.commercialUnits : undefined,
              personalContributionMultiEntry: context.personalContributionMultiEntryPower ?? false,
              personalContributionCivilEngineering: context.personalContributionCivilEngineeringPower ?? false,
              personalContributionCounter: context.personalContributionCounter ?? false,
          }
        : undefined;
