import { Button, IconsCloseMenu, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import L from 'leaflet';
import * as React from 'react';

import { DRAW_MARKERS_STATE, EDIT_LINE_COURSE_STATE, ProcessGuideContent } from './useProcessGuide';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

export type ProcessGuideProperties = {
    next: () => void;
    prev: () => void;
    guideContent: ProcessGuideContent;
    toggleShowProcessGuide: () => void;
    isAtBeginning: boolean;
    isAtEnd: boolean;
    canContinue: () => boolean;
    currentProcessStep: number;
    loading: boolean;
};

export default ({
    next,
    prev,
    guideContent,
    toggleShowProcessGuide,
    isAtBeginning,
    isAtEnd,
    canContinue,
    currentProcessStep,
    loading,
}: ProcessGuideProperties): React.ReactElement => {
    const ProcessGuideRef = React.useRef(null);
    React.useEffect(() => {
        if (ProcessGuideRef.current) {
            L.DomEvent.disableClickPropagation(ProcessGuideRef.current);
        }
    });

    const getLoadingTitle = (processStep: number) => {
        switch (processStep) {
            case DRAW_MARKERS_STATE:
                return 'Der kürzeste Leitungsverlauf wird berechnet.';
            case EDIT_LINE_COURSE_STATE:
                return 'Der Leitungsverlauf wird geprüft.';
            default:
                break;
        }
        return 'Lädt...';
    };

    return (
        <div ref={ProcessGuideRef}>
            <div
                className="top-4 left-[10rem] bg-white absolute rounded
                        shadow rounded-3xl cursor-auto w-[33rem] min-h-[9rem] speech-bubble"
                style={{ zIndex: 800 }}
            >
                <button
                    type="button"
                    className="absolute fill-current text-dark-grey hover:text-darkest-grey top-1 right-1
                                w-4 m-2"
                    onClick={toggleShowProcessGuide}
                    title="Schließen"
                    aria-label="close dialog"
                >
                    <IconsCloseMenu />
                </button>
                <div className="p-3 h-full flex flex-col">
                    <div style={{ fontSize: 'calc(13px + 0.08vw)' }}>
                        <h5 className="font-bold"> {guideContent.heading} </h5>
                        <p> {guideContent.text} </p>
                        {guideContent.footer && <p> {guideContent.footer} </p>}
                    </div>
                    <div className="flex w-full justify-between pt-2">
                        {!isAtBeginning && (
                            <div className="left-3 bottom-3">
                                <Button
                                    label="Zurück"
                                    size="sm"
                                    type="secondary"
                                    onClick={prev}
                                    id="button-processGuide-zurück"
                                />
                            </div>
                        )}
                        {!isAtEnd && (
                            <div className="right-3 bottom-3 w-full justify-end flex">
                                <LoadingSpinnerButton
                                    label={currentProcessStep === EDIT_LINE_COURSE_STATE ? 'Fertig' : 'Weiter'}
                                    size="sm"
                                    loading={loading && canContinue()}
                                    onClick={next}
                                    type="primary"
                                    disabled={!canContinue()}
                                    id="button-processGuide-weiter"
                                    loadingTitle={getLoadingTitle(currentProcessStep)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
