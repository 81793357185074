import { Form, LoadingSpinnerButton } from '@ten-netzkundenportal/ui-components';
import { FormValues, SignUpFormCompany, SignUpFormPrivateCustomer } from '@ten-netzkundenportal/ui-registration';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { isCompanyData, isPrivateData } from '../../types';
import { Context } from '../../wizard';
import registerCustomer from './api/registerCustomer';

export type RegistrationFormProperties = {
    context: Partial<Context>;
    setRegistrationSuccessful: (boolean) => void;
};

export default ({ context, setRegistrationSuccessful }: RegistrationFormProperties): React.ReactElement => {
    const { userType } = context;

    const submit = async (customer: FormValues) => {
        const registrationResponseStatus = await registerCustomer(userType, customer, context.meta.connectionRequestId);
        setRegistrationSuccessful(registrationResponseStatus === 201);
    };

    const reactHookForm = useForm<FormValues>({
        mode: 'onTouched',
        defaultValues: {
            customerType: userType,
            companyName:
                (userType === 'projectpartner' || userType === 'company') && isCompanyData(context)
                    ? context?.companyData.companyName
                    : undefined,
            firstName:
                userType === 'private' && isPrivateData(context) ? context?.privateData.person.firstName : undefined,
            lastName:
                userType === 'private' && isPrivateData(context) ? context.privateData.person.lastName : undefined,
        },
    });

    const {
        formState: { isValid, isValidating, isSubmitting },
        handleSubmit,
    } = reactHookForm;

    return (
        <Form title="Registrierung für das Netzkundenportal">
            <FormProvider {...reactHookForm}>
                <span>
                    Bitte registrieren Sie sich vor dem Abschluss Ihres Antrags. Über Ihre angegebene E-Mail-Adresse
                    erhalten Sie Zugang zu unserem Netzkundenportal, in dem Sie Ihren Antrag jederzeit einsehen können.
                    Zudem können Sie dort Ihre Daten selbstständig anpassen, die Bearbeitung verfolgen und Kontakt zu
                    uns aufnehmen.
                </span>
                <div className="max-w-3xl gap-y-10">
                    {userType === 'projectpartner' && <SignUpFormCompany requireEmailConfirmation />}
                    {userType === 'private' && <SignUpFormPrivateCustomer requireEmailConfirmation />}
                    {userType === 'company' && <SignUpFormCompany requireEmailConfirmation />}
                    <div className="flex justify-end mt-10">
                        <LoadingSpinnerButton
                            loading={isSubmitting || isValidating}
                            type="primary"
                            disabled={!isValid || isValidating}
                            label="Registrieren"
                            onClick={handleSubmit(submit)}
                        />
                    </div>
                </div>
            </FormProvider>
        </Form>
    );
};
