import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import { BUILDING_DATA, GAS_CONSUMPTION, POWER_CONSUMPTION_DECLARATION, POWER_CONSUMPTION_TOTAL } from '../routes';
import { RouteProperty } from '../types';
import BuildingDataForm from './building-data-form';
import GasConsumption from './gas-consumption-form';
import PowerConsumptionDeclarationForm from './power-consumption-declaration-form';
import PowerConsumptionTotalForm from './power-consumption-total-form';

export default ({ onSubmit, goBack, updateContext, context }: RouteProperty): React.ReactElement => (
    <Switch>
        <Route path={BUILDING_DATA}>
            <BuildingDataForm onSubmit={onSubmit} goBack={goBack} updateContext={updateContext} context={context} />
        </Route>
        <Route path={POWER_CONSUMPTION_DECLARATION}>
            <PowerConsumptionDeclarationForm
                onSubmit={onSubmit}
                goBack={goBack}
                updateContext={updateContext}
                context={context}
            />
        </Route>
        <Route path={POWER_CONSUMPTION_TOTAL}>
            <PowerConsumptionTotalForm
                onSubmit={onSubmit}
                goBack={goBack}
                context={context}
                updateContext={updateContext}
            />
        </Route>
        <Route path={GAS_CONSUMPTION}>
            <GasConsumption onSubmit={onSubmit} goBack={goBack} context={context} updateContext={updateContext} />
        </Route>
    </Switch>
);
