import { AxiosResponse } from 'axios';

import appConfig from '../../../../app.config';
import { ApiParams } from '../../../../hooks/useApi';
import { DocumentMetadata } from '../../../types';

export type GetDocumentListParameter = {
    processCommunicationId: string;
    connectionRequestId: string;
};

export const getDocumentList = async ({
    params,
    axios,
    customerId,
}: ApiParams<GetDocumentListParameter>): Promise<DocumentMetadata[]> => {
    const { processCommunicationId, connectionRequestId } = params;

    const response: AxiosResponse = await axios.get(
        `${appConfig.services.connectionRequestFormApi}/account/${customerId}/connection-request/${connectionRequestId}/offer/${processCommunicationId}/document`,
    );

    return response.data;
};
