export const trimObject = (value) => {
    if (value instanceof Date) {
        return value;
    }
    if (Array.isArray(value)) {
        return value.map(trimObject);
    }
    if (typeof value === 'object' && value !== null) {
        return Object.keys(value).reduce((acc, key) => {
            acc[key] = trimObject(value[key]);
            return acc;
        }, {});
    }

    if (typeof value === 'string') {
        return value.trim();
    }
    return value;
};
