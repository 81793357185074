function createDocumentUrl(blob: Blob) {
    return window.URL.createObjectURL(
        new Blob([blob], {
            type: 'application/pdf',
        }),
    );
}

const getDownloadOfferLink = (blob: Blob, filename: string) => {
    const objectUrl = createDocumentUrl(blob);
    const anchor = document.createElement('a');
    document.body.appendChild(anchor);
    anchor.href = objectUrl;
    anchor.download = filename;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
};

export default getDownloadOfferLink;
