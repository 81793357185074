import { STEPS_VALUE } from './constants';
import { Context } from './context';

export const NEXT_EVENT = 'NEXT';
export const PREV_EVENT = 'PREV';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const SYNC_STATE_WITH_LOCATION = 'SYNC_STATE_WITH_LOCATION';
export const SYNC_LOCATION_WITH_STATE = 'SYNC_LOCATION_WITH_STATE';
export const UPDATE_PATH = 'UPDATE_PATH';

export const UPDATE_FURTHEST_STEPS = 'UPDATE_FURTHEST_STEPS';

export type UpdateContextEvent = { type: 'UPDATE_CONTEXT'; value: Partial<Context> };
export type SyncStateWithLocationEvent = { type: 'SYNC_STATE_WITH_LOCATION'; path: string };
export type UpdatePathEvent = { type: 'UPDATE_PATH'; path: string };
export type UpdateWizardStepsEvent = { type: 'UPDATE_FURTHEST_STEPS'; process: STEPS_VALUE; subprocess: number };
export type UserEvent =
    | UpdateContextEvent
    | SyncStateWithLocationEvent
    | UpdatePathEvent
    | UpdateWizardStepsEvent
    | { type: 'NEXT' }
    | { type: 'PREV' }
    | { type: 'SYNC_LOCATION_WITH_STATE' }
    | { type: 'xstate.init' };
