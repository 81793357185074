import { Dropdown, FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import '@ten-netzkundenportal/ui-components/dist/base.css';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';

import { AddRemoveButtonGroup } from '../../../components';
import FormLayout from '../../../components/SignupForm/FormLayout';
import useMultiField from '../../../components/useMultiField';
import { PowerConsumptionUnknownProperties, UserType } from '../../../types';
import RowElement from './RowWrapperElement';

export type ElectricalWaterHeatingProps = {
    reactHookForm: UseFormReturn<PowerConsumptionUnknownProperties>;
    userType: UserType;
};

const options = [
    {
        value: 'directBufferStorage',
        label: 'Direkter Pufferspeicher',
    },
    {
        value: 'waterHeater',
        label: 'Durchlauferhitzer',
    },
    {
        value: 'solarThermalSupportedBufferStorage',
        label: 'Solarthermie gestützter Pufferspeicher',
    },
    {
        value: 'tbd',
        label: 'Noch nicht definiert',
    },
];

// as is used to show that there is a type difference between PowerConsumptionUnknownProperties
// and Form type. This was obscured by RowHook, but was already implemented.
// consider refactor to formType and contextType.
export function getDefaultValue() {
    return {} as { type: string; capacity: 0 };
}

export default ({ reactHookForm, userType }: ElectricalWaterHeatingProps): React.ReactElement => {
    const {
        control,
        register,
        formState: { errors, touchedFields },
    } = reactHookForm;

    const [fields, handleAppend, handleDelete] = useMultiField({
        control,
        name: 'electricalWaterHeating',
        defaultValue: getDefaultValue(),
    });

    return (
        <div className="mt-5 sm:mt-0">
            <div className="grid grid-flow-col items-center">
                <span className="font-bold">Elektrische Warmwasserbereitung</span>
            </div>
            <FormLayout>
                <FormItem
                    label={`Welche elektrische Warmwasser\u00ADbereitungsart ${
                        userType === 'installer' || userType === 'projectpartner'
                            ? 'wird Ihr Kunde nutzen?'
                            : 'werden Sie nutzen?'
                    }`}
                    className="items-start"
                >
                    <div className="flex flex-col gap-y-3">
                        {fields.map((field, index) => (
                            <RowElement
                                key={field.id}
                                index={index}
                                firstColumnLabel="Warmwasserbereitungsart"
                                firstColumnContent={
                                    <Dropdown
                                        name={`electricalWaterHeating.${index}.type`}
                                        options={options}
                                        dropdownClassname="w-full"
                                        {...register(`electricalWaterHeating.${index}.type` as const, {
                                            required: true,
                                            shouldUnregister: true,
                                        })}
                                    />
                                }
                                secondColumnLabel="Leistung"
                                secondColumnContent={
                                    <div className="grid grid-flow-col gap-x-2">
                                        <NumberInput
                                            name={`electricalWaterHeating.${index}.capacity`}
                                            {...NumberInputFunctions.registerNumberInput(
                                                reactHookForm,
                                                `electricalWaterHeating.${index}.capacity`,
                                                {
                                                    required: true,
                                                    shouldUnregister: true,
                                                    min: 1,
                                                    max: 999,
                                                },
                                            )}
                                            displayErrorMessage={
                                                touchedFields?.electricalWaterHeating?.[index.toString()]?.capacity &&
                                                errors?.electricalWaterHeating?.[index.toString()]?.capacity &&
                                                'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                            }
                                            fluidWidth
                                        />
                                        <span className="mt-2">kW</span>
                                    </div>
                                }
                                buttonGroup={
                                    <AddRemoveButtonGroup
                                        group="electricalWaterHeating"
                                        handleAppend={handleAppend}
                                        handleDelete={handleDelete(index)}
                                        fields={fields}
                                        index={index}
                                    />
                                }
                            />
                        ))}
                    </div>
                </FormItem>
            </FormLayout>
        </div>
    );
};
