import { FormItem, NumberInput, NumberInputFunctions } from '@ten-netzkundenportal/ui-components';
import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import { GasConsumptionUnknownData, UserType } from '../../../types';

export type GasOutletCounterProps = {
    userType: UserType;
    syncContext: () => void;
};

export default ({ userType, syncContext }: GasOutletCounterProps): React.ReactElement => {
    const reactHookForm = useFormContext<GasConsumptionUnknownData>();
    const {
        formState: { errors, touchedFields },
    } = reactHookForm;

    return (
        <div className="flex gap-x-10">
            <FormItem
                label={
                    <>
                        Wie viele Erdgassteckdosen{' '}
                        {userType === 'installer' || userType === 'projectpartner' ? 'wird Ihr Kunde ' : 'werden Sie '}
                        haben?
                        <br />
                        <span className="text-small">
                            <b>Hinweis:</b> Eine Steckdose hat einen Leistungsumfang von max. 13 kW.
                        </span>
                    </>
                }
                className="items-start"
            >
                <div className="flex flex-col gap-y-3">
                    <div className="flex flex-row items-start justify-start w-full gap-x-6">
                        <div className="flex items-center justify-start w-2/3 gap-x-2 ml-px mb-1">
                            <div className="flex flex-col md:grid md:w-3/5 w-[120px]">
                                <label className="text-small" htmlFor="numberinput-gasOutlets.amount">
                                    Anzahl
                                </label>
                                <NumberInput
                                    name="gasOutlets.amount"
                                    {...NumberInputFunctions.registerNumberField(reactHookForm, `gasOutlets.amount`, {
                                        required: true,
                                        shouldUnregister: true,
                                        min: 1,
                                        max: 999,
                                        onChange: syncContext,
                                    })}
                                    displayErrorMessage={
                                        touchedFields?.gasOutlets?.amount &&
                                        errors?.gasOutlets?.amount &&
                                        'Bitte geben Sie eine Zahl zwischen 1 und 999 ein.'
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FormItem>
        </div>
    );
};
