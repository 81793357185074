import { TextField } from '@ten-netzkundenportal/ui-components';
import React from 'react';
import { FieldErrors, useFormContext } from 'react-hook-form';

import { CompanyName } from '../../types';

const requiredErrorMessage = 'Dies ist ein Pflichtfeld.';
const validationErrorMessage = 'Bitte korrigieren Sie Ihre Eingabe.';

export type CompanyNameFieldProps = {
    registerPrefix: string;
    disabled?: boolean;
};

const CompanyNameField = ({ registerPrefix, disabled = false }: CompanyNameFieldProps): React.ReactElement => {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div className="h-20">
            <TextField
                autoComplete="organization"
                name="companyName"
                label="Firmenname inkl. Rechtsform"
                displayErrorMessage={
                    { ...registerPrefix.split('.').reduce((o, x) => o?.[x], errors as FieldErrors<CompanyName>) }
                        ?.companyName?.message
                }
                disabled={disabled}
                {...register(`${registerPrefix}.companyName`, {
                    required: requiredErrorMessage,
                    minLength: { value: 2, message: validationErrorMessage },
                    maxLength: { value: 160, message: validationErrorMessage },
                })}
            />
        </div>
    );
};

export default CompanyNameField;
