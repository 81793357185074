import axios from 'axios';

interface MaintenanceStatus {
    maintenanceDistributedGeneration: boolean;
    maintenanceConnectionRequest: boolean;
}

export async function isMaintenanceActive() {
    try {
        const maintenanceStatus = await axios.get<MaintenanceStatus>('/manual-configuration/maintenance.json', {
            headers: {
                'Cache-Control': 'no-store',
            },
        });
        return maintenanceStatus.data.maintenanceConnectionRequest ?? false;
    } catch (err) {
        console.error('Failed to load maintenance status', err);
        return false;
    }
}
