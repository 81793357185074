import axios, { AxiosResponse } from 'axios';

import appConfig from '../../../app.config';

type Price = {
    net: string;
    gross: string;
};

type MaterialPrice = {
    name: string;
    price: Price;
};

// @ToDo: Find better way to map the material names
export type MaterialList = {
    // Energy
    VTEPEKN: MaterialPrice; // Neuanschluss: <= 30 m
    VTEPEKN1: MaterialPrice; // Neuanschluss: > 30 m und <= 50 m
    VTEPEKNGEL: MaterialPrice; // Tiefbau
    VTEPHAEGMK: MaterialPrice; // Durchbruch/ Mehrspartenhauseinführung
    VTEPZASG: MaterialPrice; // Bereitstellung Hausanschlusskasten über Zähleranschlusssäule
    // Gas
    VTEPGDN251: MaterialPrice; // Neuanschluss: Leitungslänge <=1m / <= 20 m *
    VTEPGDN252: MaterialPrice; // Neuanschluss: Leitungslänge <=1m / > 20 m und <=100 m *
    VTEPGDN253: MaterialPrice; // Neuanschluss: Leitungslänge >1m / <= 30 m*
    VTEPGDN254: MaterialPrice; // Neuanschluss: Leitungslänge >1m / >30m und <= 40 m*
    VTEPGDN255: MaterialPrice; // Neuanschluss: Leitungslänge >1m / >40m und <= 50 m*
    VTEPGDN256: MaterialPrice; // Neuanschluss:Leitungslänge >1m / >50m und <= 60 m*
    VTEPGN25GEL: MaterialPrice; // Tiefbau
    VTEPGHEKB: MaterialPrice; // Durchbruch/ Mehrspartenhauseinführung
};

const getMaterialList = async (): Promise<MaterialList> => {
    const response: AxiosResponse<MaterialList> = await axios.get<MaterialList>(
        `${appConfig.services.connectionRequestFormApi}/price-list`,
    );

    if (response.status === 200) {
        return response.data;
    }
    throw new Error('Failed getting Material Costs');
};

export default getMaterialList;
