import { MachineConfig } from 'xstate';

import {
    BUILDING_DATA,
    CONNECTION_DATA,
    CONNECTION_PLAN,
    CONTACT_ADDRESS,
    GAS_CONSUMPTION,
    INSTALLER_SELECTION,
    INVOICE_ADDRESS,
    LAND_OWNER,
    LOCATION_DATA,
    NEXT_STEPS,
    OFFER_CONCLUSION,
    OFFER_CONCLUSION_REDIRECT,
    OFFER_GAS,
    OFFER_POWER,
    PERSONAL_CONTRIBUTION,
    POWER_CONSUMPTION_DECLARATION,
    POWER_CONSUMPTION_TOTAL,
    REQUEST_OVERVIEW,
    SERVICE_SELECTION,
} from '../routes';
import { STEPS, SUBPROCESS_INDEX_MAPPING } from './constants';
import { Context } from './context';
import { UPDATE_FURTHEST_STEPS, UserEvent } from './events';
import guards from './guards';

const initial = 'initial';
const states = {
    initial: {
        always: [{ target: 'service' }],
    },
    service: {
        initial,
        states: {
            initial: {
                always: { target: 'locationData' },
            },
            locationData: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.SERVICE_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.SERVICE_LOCATION_DATA[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: LOCATION_DATA },
                ],
                meta: {
                    path: LOCATION_DATA,
                    process: STEPS.SERVICE_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.SERVICE_LOCATION_DATA[1] + 1,
                },
                on: {
                    NEXT: { target: 'serviceSelection' },
                },
            },
            serviceSelection: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.SERVICE_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.SERVICE_SELECTION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: SERVICE_SELECTION },
                ],
                meta: {
                    path: SERVICE_SELECTION,
                    process: STEPS.SERVICE_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.SERVICE_SELECTION[1] + 1,
                },
                on: {
                    PREV: { target: 'locationData' },
                },
            },
            tail: {
                always: { target: 'serviceSelection' },
            },
        },
        on: {
            NEXT: { target: 'consumption' },
        },
    },
    consumption: {
        initial,
        states: {
            initial: {
                always: { target: 'buildingData' },
            },
            buildingData: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONSUMPTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_BUILDING_DATA[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: BUILDING_DATA },
                ],
                meta: {
                    path: BUILDING_DATA,
                    process: STEPS.CONSUMPTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_BUILDING_DATA[1] + 1,
                },
                on: {
                    NEXT: [
                        { target: 'powerConsumptionDeclaration', cond: guards.isPowerServiceSelected },
                        { target: 'gasConsumption', cond: guards.isGasServiceSelected },
                    ],
                },
            },
            powerConsumptionDeclaration: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONSUMPTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_POWER_CONSUMPTION_DECLARATION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: POWER_CONSUMPTION_DECLARATION },
                ],
                meta: {
                    path: POWER_CONSUMPTION_DECLARATION,
                    process: STEPS.CONSUMPTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_POWER_CONSUMPTION_DECLARATION[1] + 1,
                },
                on: {
                    NEXT: [
                        { target: 'powerConsumptionTotal', cond: guards.isPowerConsumptionTotalPageShown },
                        { target: 'gasConsumption', cond: guards.isGasServiceSelected },
                    ],
                    PREV: { target: 'buildingData' },
                },
            },
            powerConsumptionTotal: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONSUMPTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_POWER_CONSUMPTION_TOTAL[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: POWER_CONSUMPTION_TOTAL },
                ],
                meta: {
                    path: POWER_CONSUMPTION_TOTAL,
                    process: STEPS.CONSUMPTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_POWER_CONSUMPTION_TOTAL[1] + 1,
                },
                on: {
                    NEXT: { target: 'gasConsumption', cond: guards.isGasServiceSelected },
                    PREV: { target: 'powerConsumptionDeclaration' },
                },
            },
            gasConsumption: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONSUMPTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_GAS_CONSUMPTION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: GAS_CONSUMPTION },
                ],
                on: {
                    PREV: [
                        { target: 'powerConsumptionTotal', cond: guards.isPowerConsumptionTotalPageShown },
                        { target: 'powerConsumptionDeclaration', cond: guards.isPowerServiceSelected },
                        { target: 'buildingData' },
                    ],
                },
                meta: {
                    path: GAS_CONSUMPTION,
                    process: STEPS.CONSUMPTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONSUMPTION_GAS_CONSUMPTION[1] + 1,
                },
            },
            tail: {
                always: [
                    { target: 'gasConsumption', cond: guards.isGasServiceSelected },
                    { target: 'powerConsumptionTotal', cond: guards.isPowerConsumptionTotalPageShown },
                    { target: 'powerConsumptionDeclaration' },
                ],
            },
        },
        on: {
            NEXT: { target: 'connection' },
            PREV: { target: 'service.tail' },
        },
    },
    connection: {
        initial,
        states: {
            initial: {
                always: { target: 'data' },
            },
            data: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONNECTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_DATA[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: CONNECTION_DATA },
                ],
                meta: {
                    path: CONNECTION_DATA,
                    process: STEPS.CONNECTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_DATA[1] + 1,
                },
                on: {
                    NEXT: { target: 'plan' },
                },
            },
            plan: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONNECTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_PLAN[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: CONNECTION_PLAN },
                ],
                meta: {
                    path: CONNECTION_PLAN,
                    process: STEPS.CONNECTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_PLAN[1] + 1,
                },
                on: {
                    NEXT: { target: 'landOwner' },
                    PREV: { target: 'data' },
                },
            },
            landOwner: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.CONNECTION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_LAND_OWNER[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: LAND_OWNER },
                ],
                meta: {
                    path: LAND_OWNER,
                    process: STEPS.CONNECTION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.CONNECTION_LAND_OWNER[1] + 1,
                },
                on: {
                    PREV: { target: 'plan' },
                },
            },
            tail: {
                always: { target: 'landOwner' },
            },
        },
        on: {
            NEXT: { target: 'transposition' },
            PREV: { target: 'consumption.tail' },
        },
    },
    transposition: {
        initial,
        states: {
            initial: {
                always: { target: 'personalContribution' },
            },
            personalContribution: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.TRANSPOSITION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.TRANSPOSITION_PERSONAL_CONTRIBUTION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: PERSONAL_CONTRIBUTION },
                ],
                meta: {
                    path: PERSONAL_CONTRIBUTION,
                    process: STEPS.TRANSPOSITION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.TRANSPOSITION_PERSONAL_CONTRIBUTION[1] + 1,
                },
                on: {
                    NEXT: [{ target: 'installerSelection' }],
                },
            },
            installerSelection: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.TRANSPOSITION_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.TRANSPOSITION_INSTALLER_SELECTION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: INSTALLER_SELECTION },
                ],
                meta: {
                    path: INSTALLER_SELECTION,
                    process: STEPS.TRANSPOSITION_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.TRANSPOSITION_INSTALLER_SELECTION[1] + 1,
                },
                on: {
                    PREV: { target: 'personalContribution' },
                },
            },
            tail: {
                always: { target: 'installerSelection' },
            },
        },
        on: {
            NEXT: { target: 'addresses' },
            PREV: { target: 'connection.tail' },
        },
    },
    addresses: {
        initial,
        states: {
            initial: {
                always: { target: 'contactAddress' },
            },
            contactAddress: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.ADDRESSES_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.ADDRESSES_CONTACT_ADDRESS[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: CONTACT_ADDRESS },
                ],
                meta: {
                    path: CONTACT_ADDRESS,
                    process: STEPS.ADDRESSES_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.ADDRESSES_CONTACT_ADDRESS[1] + 1,
                },
                on: {
                    NEXT: { target: 'invoiceAddress' },
                },
            },
            invoiceAddress: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.ADDRESSES_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.ADDRESSES_INVOICE_ADDRESS[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: INVOICE_ADDRESS },
                ],
                meta: {
                    path: INVOICE_ADDRESS,
                    process: STEPS.ADDRESSES_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.ADDRESSES_INVOICE_ADDRESS[1] + 1,
                },
                on: {
                    PREV: { target: 'contactAddress' },
                },
            },
            tail: {
                always: { target: 'invoiceAddress' },
            },
        },
        on: {
            NEXT: { target: 'offer' },
            PREV: { target: 'transposition.tail' },
        },
    },
    offer: {
        initial,
        states: {
            initial: {
                always: { target: 'requestOverview' },
            },
            requestOverview: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.OFFER_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_REQUEST_OVERVIEW[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: REQUEST_OVERVIEW },
                ],
                meta: {
                    path: REQUEST_OVERVIEW,
                    process: STEPS.OFFER_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_REQUEST_OVERVIEW[1] + 1,
                },
                on: {
                    NEXT: [
                        { target: 'offerPower', cond: guards.isAutomaticElectricityOfferPageShown },
                        { target: 'offerGas', cond: guards.isAutomaticGasOfferPageShown },
                        { target: 'offerConclusion' },
                    ],
                },
            },
            offerPower: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.OFFER_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_POWER[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: OFFER_POWER },
                ],
                meta: {
                    path: OFFER_POWER,
                    process: STEPS.OFFER_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_POWER[1] + 1,
                },
                on: {
                    NEXT: [
                        {
                            target: 'offerGas',
                            cond: guards.isAutomaticGasOfferPageShown,
                        },
                        { target: 'offerConclusion' },
                    ],
                    PREV: { target: 'requestOverview' },
                },
            },
            offerGas: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.OFFER_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_GAS[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: OFFER_GAS },
                ],
                meta: {
                    path: OFFER_GAS,
                    process: STEPS.OFFER_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_GAS[1] + 1,
                },
                on: {
                    NEXT: { target: 'offerConclusion' },
                    PREV: [
                        {
                            target: 'offerPower',
                            cond: guards.isAutomaticElectricityOfferPageShown,
                        },
                        { target: 'requestOverview' },
                    ],
                },
            },
            offerConclusion: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.OFFER_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_CONCLUSION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: OFFER_CONCLUSION },
                ],
                meta: {
                    path: OFFER_CONCLUSION,
                    process: STEPS.OFFER_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_CONCLUSION[1] + 1,
                },
                on: {
                    PREV: [
                        { target: 'offerGas', cond: guards.isAutomaticGasOfferPageShown },
                        {
                            target: 'offerPower',
                            cond: guards.isAutomaticElectricityOfferPageShown,
                        },
                        { target: 'requestOverview' },
                    ],
                },
            },
            offerConclusionRedirect: {
                entry: [
                    {
                        type: UPDATE_FURTHEST_STEPS,
                        process: STEPS.OFFER_PROCESS,
                        subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_CONCLUSION[1] + 1,
                    },
                    { type: 'UPDATE_PATH', path: OFFER_CONCLUSION },
                ],
                meta: {
                    path: OFFER_CONCLUSION_REDIRECT,
                    process: STEPS.OFFER_PROCESS,
                    subprocess: SUBPROCESS_INDEX_MAPPING.OFFER_CONCLUSION[1] + 1,
                },
                on: {
                    PREV: [
                        { target: 'offerGas', cond: guards.isAutomaticGasOfferPageShown },
                        {
                            target: 'offerPower',
                            cond: guards.isAutomaticElectricityOfferPageShown,
                        },
                        { target: 'requestOverview' },
                    ],
                },
            },
            tail: {
                always: { target: 'offerConclusion' },
            },
        },
        on: {
            NEXT: { target: 'nextSteps' },
            PREV: { target: 'addresses.tail' },
        },
    },
    nextSteps: {
        entry: [
            {
                type: UPDATE_FURTHEST_STEPS,
                process: STEPS.NEXT_STEPS_PROCESS,
                subprocess: SUBPROCESS_INDEX_MAPPING.NEXT_STEPS[1],
            },
            { type: 'UPDATE_PATH', path: NEXT_STEPS },
        ],
        meta: {
            path: NEXT_STEPS,
            process: STEPS.NEXT_STEPS_PROCESS,
            subprocess: SUBPROCESS_INDEX_MAPPING.NEXT_STEPS[1],
        },
    },
};

export default {
    id: 'antragsstrecke',
    initial: 'initial',
    context: {},
    on: {
        UPDATE_CONTEXT: {
            actions: ['UPDATE_CONTEXT'],
        },
        SYNC_STATE_WITH_LOCATION: {
            actions: ['SYNC_STATE_WITH_LOCATION'],
        },
        SYNC_LOCATION_WITH_STATE: {
            actions: ['SYNC_LOCATION_WITH_STATE'],
        },
        UPDATE_PATH: {
            actions: ['UPDATE_PATH'],
        },
    },
    states,
    strict: true,
    predictableActionArguments: true,
} as MachineConfig<Partial<Context>, never, UserEvent>;
