import { Drawing } from '../../types';
import { Context } from '../../wizard';

const transformContextToDrawing = (context: Partial<Context>): Drawing => ({
    multiEntryCoordinates: context.multiEntryMarker,
    electricityEntryCoordinates: context.powerMarker,
    gasEntryCoordinates: context.gasMarker,
    buildings: context.houseShapes.map((houseShape) => houseShape.latLngs),
    electricityLineCourse: context.electricityLineCourse,
    gasLineCourse: context.gasLineCourse,
});

export default transformContextToDrawing;
