import * as React from 'react';
import { ReactElement } from 'react';

export interface RowElementProps {
    index: number;
    firstColumnLabel: string;
    firstColumnContent: ReactElement;
    secondColumnLabel: string;
    secondColumnContent: ReactElement;
    buttonGroup: ReactElement;
}

export default ({
    index,
    firstColumnLabel,
    firstColumnContent,
    secondColumnLabel,
    secondColumnContent,
    buttonGroup,
}: RowElementProps): React.ReactElement => (
    <div className="flex flex-row items-center justify-start w-full gap-x-4">
        <div className="flex items-center justify-start w-full xl:w-1/2 md:w-3/4 gap-x-2 mb-px">
            <div className="grid w-full md:w-9/12">
                {index === 0 && <span className="text-small">{firstColumnLabel}</span>}
                {firstColumnContent}
            </div>
            <div className="grid w-1/2 md:w-3/12">
                {index === 0 && <span className="text-small">{secondColumnLabel}</span>}
                {secondColumnContent}
            </div>
        </div>
        {buttonGroup}
    </div>
);
